import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { BLOG_TYPE, IPageMdl, IPageShortMdl, PAGE_TYPE } from "pages/_models/PageMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";
import {
    getInitialStateValue,
    putPromiseListResultInInitialState,
    putPromiseResourceResultInInitialState,
    putPromiseResultInInitialState,
} from "_common/_utils/initialStateUtils";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import dayjs from "dayjs";
import { TFilter } from "admin/_common/filters/TFilter";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import i18next from "i18next";
import { observable } from "mobx";
import { getI18nExpByLang } from "_common/_utils/pageUtils";

class PagesStore extends BaseResourceStore<IPageMdl> {
    searchArticlesLoadingState: LoadingStateMdl<IPageMdl[] | undefined> = new LoadingStateMdl<IPageMdl[] | undefined>();
    @observable searchResults: IPageMdl[] = [];
    @observable homeArticles: IPageShortMdl[] = [];
    private latestArticlesLoadingState: LoadingStateMdl<IPageShortMdl[]> = new LoadingStateMdl<IPageShortMdl[]>();
    private homeArticlesLoadingState: LoadingStateMdl<IPageShortMdl[]> = new LoadingStateMdl<IPageShortMdl[]>();

    constructor() {
        super("pages");
        if (__BROWSER__) this.onInit();
    }

    getByUrl(path: string, lang: TLang) {
        const page = this.getByUrlSync(path, lang);
        if (page) return page;
        let pagePath = path.substr(lang === "en" ? 3 : sharedConfig.languages[lang].basePath.length);
        const isBlogPage = pagePath.indexOf("/" + getI18nExpByLang(lang, "routes.blogPage"));
        if (isBlogPage > -1) {
            pagePath = pagePath.substr(("/" + getI18nExpByLang(lang, "routes.blogPage")).length);
        }
        const pagePromise = fetchUtils
            .get<IPageMdl>(`${this.apiPath}/published?path=${pagePath}&lang=${lang}`)
            .then(({ data }) => {
                this.addItem(data);
            });
        putPromiseResourceResultInInitialState(this.name, pagePromise);
        return pagePromise;
    }

    getByUrlSync(path: string, lang: TLang) {
        let pagePath = path.substr(lang === "en" ? 3 : sharedConfig.languages[lang].basePath.length);
        const isBlogPage = pagePath.indexOf("/" + getI18nExpByLang(lang, "routes.blogPage"));
        if (isBlogPage > -1) {
            pagePath = pagePath.substr(("/" + getI18nExpByLang(lang, "routes.blogPage")).length);
        }
        return this.findOneSync(`localized.${lang}.url`, pagePath || "/");
    }

    getHomeArticles() {
        return this.loadShortPages(
            `homeArticles`,
            `
            ${this.apiPath}/homeArticles?lang=${i18next.language}`,
            this.homeArticlesLoadingState,
        );
    }
    getLatestArticles(limit?: number) {
        return this.loadShortPages(
            `pagesStore.latestArticles`,
            `
            ${this.apiPath}/latestArticles?limit=${limit ?? 10}&lang=${i18next.language}`,
            this.latestArticlesLoadingState,
        );
    }

    list(
        offset?: number,
        limit?: number,
        listId?: string,
        sort?: { [p: string]: number },
        filters?: TFilter[],
    ): Promise<{ count: number; items: IPageMdl[] }> {
        if (!filters) filters = [];
        if (listId === "pagesList") {
            filters = [...filters, { type: TFilterType.STRING, id: "type", value: PAGE_TYPE.NORMAL }];
        } else if (listId === "articlesList") {
            filters = [
                ...filters,
                {
                    type: TFilterType.STRING,
                    id: "type",
                    value: PAGE_TYPE.ARTICLE,
                },
                { type: TFilterType.BOOLEAN, id: `localized.${i18next.language}.published`, value: true },
            ];
        } else if (listId === "featuredArticlesList") {
            filters = [
                ...filters,
                {
                    type: TFilterType.STRING,
                    id: "type",
                    value: PAGE_TYPE.ARTICLE,
                },
                {
                    type: TFilterType.NUMBER,
                    id: `localized.${i18next.language}.featured`,
                    op: "eq",
                    value: 1,
                },
                { type: TFilterType.BOOLEAN, id: `localized.${i18next.language}.published`, value: true },
            ];
        } else if (listId && listId.startsWith("similarArticles")) {
            filters = [
                ...filters,
                {
                    type: TFilterType.STRING,
                    id: "type",
                    value: PAGE_TYPE.ARTICLE,
                },
                { type: TFilterType.BOOLEAN, id: `localized.${i18next.language}.published`, value: true },
            ];
        }

        const promise = super.list(offset, limit, listId, sort, filters);
        putPromiseListResultInInitialState(this.name, listId ?? "default", offset ?? 0, promise);
        return promise;
    }

    countArticlesByType(blogType: string) {
        return fetchUtils.get(this.apiPath + "/countArticlesByType/" + blogType + "?lang=" + i18next.language);
    }

    getSimilarBlogs(blogType: BLOG_TYPE, id: string) {
        return this.list(undefined, 3, undefined, { [`localized.${i18next.language}.publishedAt`]: -1 }, [
            { id: "_id", value: id, type: TFilterType.NUMBER, op: "ne" },
            { id: `localized.${i18next.language}.published`, type: TFilterType.BOOLEAN, value: true },
            { id: "blogType", value: blogType, type: TFilterType.ENUM },
        ]);
    }

    fetchBySearch(search: string) {
        if (!this.searchArticlesLoadingState.isLoading) {
            if (search === "") {
                this.searchArticlesLoadingState.setStatus("IDLE");
                return;
            }
            const promise = this.list(undefined, Number.MAX_SAFE_INTEGER, "search", { createdAt: -1 }, [
                {
                    id: `localized.${i18next.language}.title`,
                    type: TFilterType.STRING,
                    value: search,
                },
            ]);
            promise.then(
                ({ items }) => {
                    this.searchResults = items;
                    this.searchArticlesLoadingState.setSuccess(items);
                },
                () => {
                    this.searchResults = [];
                    this.searchArticlesLoadingState.setError({ key: "ERROR_FETCHING_RESULTS" });
                },
            );
        }
        return this.searchArticlesLoadingState;
    }

    protected reformatItem(item: IPageMdl): IPageMdl;
    protected reformatItem(item: IPageShortMdl): IPageShortMdl;
    protected reformatItem(item: IPageMdl | IPageShortMdl): IPageMdl | IPageShortMdl {
        const formattedItem = super.reformatItem(item);
        if ((item as any).publishedAt) (formattedItem as any).publishedAt = dayjs((formattedItem as any).publishedAt);
        return formattedItem;
    }

    protected onReset() {
        super.onReset();
        this.latestArticlesLoadingState = new LoadingStateMdl<IPageShortMdl[]>();
        this.homeArticlesLoadingState = new LoadingStateMdl<IPageShortMdl[]>();
    }

    protected onInit(fromRootCtor?: boolean) {
        if (fromRootCtor) return;
        super.onInit();

        const homeArticles = getInitialStateValue<IPageShortMdl[]>(`homeArticles`);
        if (homeArticles) {
            if (this.homeArticlesLoadingState) {
                this.homeArticlesLoadingState.setSuccess(homeArticles.map((page) => this.reformatItem(page)));
            }
        }

        const latestArticles = getInitialStateValue<IPageShortMdl[]>(`pagesStore.latestArticles`);
        if (latestArticles) {
            if (this.latestArticlesLoadingState) {
                this.latestArticlesLoadingState.setSuccess(latestArticles.map((page) => this.reformatItem(page)));
            }
        }
    }

    protected duplicateItem(item: Omit<IPageMdl, "_id">) {
        const localized = { ...item.localized };
        Object.values(localized).map((localizedPage) => {
            if (localizedPage) {
                localizedPage.url = localizedPage.url + Math.round(Math.random() * 1000);
            }
        });
        item = { ...item, localized };
        return super.duplicateItem(item);
    }

    private loadShortPages(storageKey: string, apiPath: string, loadingState: LoadingStateMdl<IPageShortMdl[]>) {
        // if (!loadingState.isLoading && !loadingState.isSucceeded) {
        if (loadingState.status === "IDLE") {
            loadingState.startLoading();
            const promise = fetchUtils.get<IPageShortMdl[]>(apiPath).then(
                ({ data }) => {
                    const result = data.map((page) => this.reformatItem(page));
                    loadingState.setSuccess(result);
                    return result;
                },
                (error) => {
                    loadingState.setError(error);
                    return [];
                },
            );
            putPromiseResultInInitialState(storageKey, promise);
        }
        return loadingState;
    }
}

const pagesStore = new PagesStore();
export { pagesStore };
