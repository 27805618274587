import React from "react";
import { Price } from "components/propertyOrUnit/Price";
import { useTranslation } from "react-i18next";
import { useUnitStore } from "units/UnitContext";
import { formatPesosPrice } from "_common/_utils/currencyUtils";
import styles from "properties/propertyPage/_css/PropertyPrice.module.css";
import clsx from "clsx";
import discount from "shared/assets/images/icons/discount.svg";

export function UnitPrice() {
    const unitStore = useUnitStore();
    const { t } = useTranslation();

    if (!unitStore.unitType.price.min) return null;
    const priceBySquare = Math.round(unitStore.unitType.price.min / unitStore.unitType.squareSurface.min ?? 1) / 100;
    const isUnitDiscount = unitStore.unitType.discount && !!unitStore.unitType.localized.discountText;

    return (
        <div className={clsx("flex-1 mt_10 p_10", styles.container)}>
            {unitStore.unitType.priceIsNotDisplayed ? (
                <div>{t("propertiesPage.card.priceOnRequest")}</div>
            ) : (
                <>
                    <Price price={unitStore.unitType.price.min} priceIsInPesos={unitStore.unitType?.priceIsInPesos} />
                    {unitStore.unitType?.priceIsInPesos && (
                        <div className={styles.text}>({formatPesosPrice(unitStore.unitType?.priceInPesos?.min)})</div>
                    )}
                    {priceBySquare && (
                        <div className={clsx(styles.text, "mb_10")}>
                            {t("propertyPage.priceBySquare", { count: priceBySquare })}
                        </div>
                    )}
                    {unitStore.unitType.fees && (
                        <div className={styles.textSmaller}>
                            {t("propertyPage.fees") +
                                t(`propertyPage.feesTypes.${unitStore.unitType.fees.type}`, {
                                    count: unitStore.unitType.fees.value,
                                })}
                        </div>
                    )}
                    <div className={styles.textSmaller}>{t("unitPage.taxes")}</div>
                </>
            )}
            {isUnitDiscount && (
                <p style={{ fontSize: 22 }} className={"mt_10 flex_center_center"}>
                    <img src={discount} alt={"discount icon"} />
                    {isUnitDiscount
                        ? unitStore.unitType.localized.discountText
                        : unitStore.unitType.property.localized.discountText}
                </p>
            )}
        </div>
    );
}
