import React from "react";
import { Separator } from "_common/ui/components/Separator";
import { settingsStore } from "settings/_stores/settingsStore";
import { ISettingsMdl } from "settings/_models/SettingsMdl";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import canada from "../assets/images/flags/canPin.png";
import mexico from "../assets/images/flags/mexPin.png";

export function ContactUsAddresses() {
    const settings = settingsStore.getOne() as ISettingsMdl;
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    return (
        <div className={"mt-8"}>
            <Separator className={"mb-6"} />
            <div className={"mb-6 md:flex md:mb-8"}>
                <div className={"md:mr-8"}>
                    <iframe
                        src={`https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d22394.632978481673!2d-73.4482458!3d45.4430235!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc90f953f09ba55%3A0x540630a9676df6b2!2sZisla!5e0!3m2!1s${lang}!2s!4v1721035899256!5m2!1s${lang}!2s`}
                        width="254"
                        height="138"
                        allowFullScreen={false}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        className={"rounded-lg"}
                    />
                </div>
                <div className={"mt-4 md:flex md:items-center md:justify-center"}>
                    {settings?.contacts?.address && (
                        <div>
                            <div className={"flex flex-row gap-3 items-center md:gap-5"}>
                                <LazyLoadImage src={canada} className={"w-[30px] h-[34px] object-cover"} />
                                <a
                                    href={"https://maps.app.goo.gl/CiatK6oYB1SMqECx7"}
                                    target={"_blank"}
                                    rel={"noreferrer noopener"}
                                    className={"text-black"}
                                >
                                    {t("contactPage.addressCa")}
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className={"md:flex"}>
                <div className={"md:mr-8"}>
                    <iframe
                        src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3722.5364814806526!2d-86.77147769999999!3d21.091167399999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c2901609eddc7%3A0x95b6fa7c7c1b0fa2!2sZisla!5e0!3m2!1s${lang}!2s!4v1721036140472!5m2!1s${lang}!2s`}
                        width="254"
                        height="138"
                        allowFullScreen={false}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        className={"rounded-lg"}
                    />
                </div>
                <div className={"mt-4"}>
                    {settings?.contacts?.mexicanAddress && (
                        <div>
                            <div className={"flex flex-row gap-3 md:gap-5 items-center"}>
                                <LazyLoadImage src={mexico} className={"w-[30px] h-[34px] object-cover"} />
                                <a
                                    href={"https://maps.app.goo.gl/V4mk2aXRoh9FDAUs6"}
                                    target={"_blank"}
                                    rel={"noreferrer noopener"}
                                    className={"text-black"}
                                >
                                    {t("contactPage.addressMex")}
                                </a>
                            </div>
                            <div hidden dangerouslySetInnerHTML={{ __html: settings.contacts.mexicanAddress }} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
