import React from "react";
import { BLOG_TYPE, IPageMdl } from "pages/_models/PageMdl";
import { useTranslation } from "react-i18next";
import { pagesStore } from "pages/_stores/pagesStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { ArticleSimilar } from "blog/ArticleSimilar";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";

type Props = {
    blogType?: BLOG_TYPE;
    blogId: string;
};

export function ArticlesSimilar(props: Props) {
    const { t } = useTranslation();
    const listStore = pagesStore.getListStore(
        "similarArticles_" + props.blogId,
        3,
        [{ id: "_id", value: props.blogId, type: TFilterType.NUMBER, op: "ne" }],
        undefined,
    );

    const loadingState: LoadingStateMdl<{ count: number; items: IPageMdl[] }> = listStore.currentLoadingState;
    if (!loadingState) return null;
    return (
        <div className={"min-h-[350px] mt-12"}>
            <div className={"text-xl font-normal"}>{t("blogPage.popular.title")}</div>
            <LoadableFromLoading<{ count: number; items: IPageMdl[] }>
                loading={loadingState}
                renderer={() => {
                    return listStore.paginatedItems
                        .filter((blog): blog is IPageMdl => blog !== undefined)
                        .map((blog: IPageMdl) => {
                            return (
                                <div key={blog._id}>
                                    <ArticleSimilar blog={blog} />
                                </div>
                            );
                        });
                }}
            />
        </div>
    );
}
