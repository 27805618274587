import { ReactNode } from "react";
import { action, observable } from "mobx";

export type TPanelOption = {
    children: ReactNode;
    closeable?: boolean;
    onClose?: () => void;
    withSpinner?: boolean;
    opened: boolean;
    stickyCross?: boolean;
    className?: string;
    withoutCross?: boolean;
};

class PanelStore {
    @observable option: TPanelOption | undefined;

    @action open(option: TPanelOption) {
        this.option = option;
    }

    @action close() {
        this.option = undefined;
    }
}

const panelStore = new PanelStore();
export { panelStore };
