import React from "react";
import { isInPesosProperty } from "_common/_utils/propertyUtils";
import { formatPesosPrice, formatPrice } from "_common/_utils/currencyUtils";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";

type Props = {
    property: TPropertyListingMdl;
};

export function PropertyCardSubTitle({ property }: Props) {
    const { t } = useTranslation();

    return (
        <>
            {property.priceIsNotDisplayed ? (
                <>{t("propertiesPage.card.priceOnRequest")}</>
            ) : (
                <>
                    {t("words.from")}
                    {isInPesosProperty(property) ? (
                        <>{formatPesosPrice(property.priceInPesos?.min)}</>
                    ) : (
                        <>{formatPrice(property.price?.min, false, isInPesosProperty(property))}</>
                    )}
                </>
            )}
        </>
    );
}
