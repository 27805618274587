export enum UNIT_CONVERSION {
    KG_TO_LB = 2.20462,
    LB_TO_KG = 0.4535,
    CM_TO_IN = 0.393701,
    KM_TO_MI = 0.621371,
    FT2_TO_M2 = 0.1,
    M2_TO_FT2 = 10,
}

export enum UNITY {
    KG = "KG",
    CM = "CM",
    KM = "KM",
    W = "W",
    KMH = "KMH",
    LB = "LB",
    INCH = "INCH",
    MI = "MI",
    WH = "WH",
    MIN = "MIN",
    V = "V",
    A = "A",
    FT2 = "FT2",
    M2 = "M2",
}

export function convert(value: number, fromToRatio: UNIT_CONVERSION, notRounded = false): number {
    if (notRounded) return value * fromToRatio;
    return Math.round(value * fromToRatio * 100) / 100;
}
