import React from "react";
import facebook from "../../assets/images/networks/primaryFacebook.svg";
import email from "../../assets/images/aboutUs/email.svg";
import linkedin from "../../assets/images/networks/primaryLinkedin.svg";
import whatsapp from "../../assets/images/networks/primaryWhatsapp.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

type ShareArticleProps = {
    url: string;
    title: string;
};

export function ShareArticle({ url, title }: ShareArticleProps) {
    const encodedUrl = encodeURIComponent(url);
    const encodedTitle = encodeURIComponent(title);
    const { t } = useTranslation();
    const whatsappMessage = encodeURIComponent(`${title} ${url}`);

    const emailShareLink = `mailto:?subject=${encodedTitle}&body=${encodedUrl}`;
    const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
    const linkedinShareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
    const whatsappShareLink = `https://api.whatsapp.com/send?text=${whatsappMessage}`;

    return (
        <div className={"flex flex-row items-center gap-3.5 pl-5 md:justify-end md:pr-6"}>
            <div className={"text-sm font-normal text-[var(--color-secondary)]"}>{t("words.share")}</div>

            <a href={emailShareLink} target="_blank" rel="noopener noreferrer">
                <LazyLoadImage src={email} alt="Email" className={"w-[30px] h-[30px]"} />
            </a>

            <a href={facebookShareLink} target="_blank" rel="noopener noreferrer">
                <LazyLoadImage src={facebook} alt="Facebook" />
            </a>

            <a href={whatsappShareLink} target="_blank" rel="noopener noreferrer">
                <LazyLoadImage src={whatsapp} alt="Instagram" />
            </a>

            <a href={linkedinShareLink} target="_blank" rel="noopener noreferrer">
                <LazyLoadImage src={linkedin} alt="LinkedIn" />
            </a>
        </div>
    );
}
