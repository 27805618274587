import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { PropertyCardFeatured } from "properties/propertyCard/PropertyCardFeatured";
import { observer } from "mobx-react-lite";
import { propertiesStore } from "properties/_stores/propertiesStore";

SwiperCore.use([Navigation]);

const NAME = "homeFeaturesProperties";
export const HomeFeaturesProperties = observer(() => {
    return (
        <>
            <div id={NAME} className={"position_relative desktopDisplay"}>
                <Swiper
                    loop
                    navigation={{
                        prevEl: `.swiper-button-prev.${NAME}`,
                        nextEl: `.swiper-button-next.${NAME}`,
                    }}
                    slidesPerView={3.1}
                    spaceBetween={20}
                    breakpoints={{
                        690: {
                            slidesPerView: 2,
                        },
                        1180: {
                            slidesPerView: 2.5,
                        },
                        1400: {
                            slidesOffsetBefore: 20,
                            slidesPerView: 3.1,
                        },
                    }}
                >
                    {propertiesStore.featuredProperties.map((property, index) => {
                        if (!property) return null;
                        return (
                            <SwiperSlide key={index}>
                                <PropertyCardFeatured property={property} />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                <div className={`swiper-button-prev ${NAME}`} />
                <div className={`swiper-button-next ${NAME}`} />
            </div>
            <div className={"mobileDisplay"}>
                {propertiesStore.featuredProperties.slice(0, 3).map((property) => {
                    if (!property) return null;
                    return (
                        <div className={" mb_15"} key={property._id}>
                            <PropertyCardFeatured property={property} />
                        </div>
                    );
                })}
            </div>
        </>
    );
});
