// CurrencyContext.tsx
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { CURRENCY_OPTIONS } from "_common/currency/CurrencyTypes";

type CurrencyContextType = {
    currentCurrency: CURRENCY_OPTIONS;
    setCurrency: (currency: CURRENCY_OPTIONS) => void;
};

const CurrencyContext = createContext<CurrencyContextType | undefined>(undefined);

type CurrencyProviderProps = {
    children: ReactNode;
};

export const CurrencyProvider: React.FC<CurrencyProviderProps> = ({ children }: CurrencyProviderProps) => {
    const defaultValue: CURRENCY_OPTIONS = __BROWSER__
        ? (localStorage.getItem("currency") as CURRENCY_OPTIONS) || CURRENCY_OPTIONS.USD
        : CURRENCY_OPTIONS.USD;
    const [currentCurrency, setCurrentCurrency] = useState<CURRENCY_OPTIONS>(defaultValue);

    const setCurrency = (currency: CURRENCY_OPTIONS) => {
        setCurrentCurrency(currency);
    };

    useEffect(() => {
        if (__BROWSER__) {
            localStorage.setItem("currency", currentCurrency);
        }
    }, [currentCurrency]);

    return <CurrencyContext.Provider value={{ currentCurrency, setCurrency }}>{children}</CurrencyContext.Provider>;
};

export const useCurrency = () => {
    const context = useContext(CurrencyContext);
    if (!context) {
        throw new Error("useCurrency must be used within a CurrencyProvider");
    }
    return context;
};
