import React from "react";
import { URLS } from "_configs/URLS";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";

export function CommunitiesBreadcrumb() {
    const { t } = useTranslation();

    const items = [
        {
            itemLabel: "BreadCrumbs",
            url: URLS.buy(),
            forceReload: true,
        },
    ];

    return (
        <Breadcrumb
            items={items}
            currentItem={{
                itemLabel: t("communitiesPage.h1Title"),
            }}
            noHome
            noMarginBottom
        />
    );
}
