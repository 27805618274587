import { TMapCoordinates } from "maps/Map";
import _ from "lodash";
import { PropertiesStore } from "properties/_stores/propertiesStore";
import { ListStore } from "_common/list/ListStore";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import * as H from "history";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { URLS } from "_configs/URLS";
import { getUrlSearchFromFilters, updateSearchFilter } from "properties/searchBar/filters/_utils/filtersUtils";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import i18next from "i18next";

export const DEFAULT_CITY = "Tulum";
const lat = 20.208771461477255;
const lng = -87.46535019999999;
export const DEFAULT_LOCATION = { lat, lng };
export const DEFAULT_ZOOM = 5;
export const DEFAULT_RADIUS = 10000;
export const DEFAULT_MAP_COORDINATES: TMapCoordinates = {
    n: 36.10237644873644,
    s: -0.7470491450051796,
    e: -76.77246093750001,
    w: -118.95996093750001,
};

// n_22.753630301822184,e_-84.99110825664059,s_16.549580938668612,w_-93.01112778789059

export const DEFAULT_MAP_COORDINATES_ZONE = [
    [DEFAULT_MAP_COORDINATES.w, DEFAULT_MAP_COORDINATES.n],
    [DEFAULT_MAP_COORDINATES.e, DEFAULT_MAP_COORDINATES.n],
    [DEFAULT_MAP_COORDINATES.e, DEFAULT_MAP_COORDINATES.s],
    [DEFAULT_MAP_COORDINATES.w, DEFAULT_MAP_COORDINATES.s],
    [DEFAULT_MAP_COORDINATES.w, DEFAULT_MAP_COORDINATES.n],
];

export const PARAMS_ZOOM_PREFIX = "z_";
export const PARAMS_PAGE_PREFIX = "p_";
export const PARAMS_NORTH_PREFIX = "n_";
export const PARAMS_BEACHFRONT_OR_LUXURY_PREFIX = "__";

export function getMapCoordinatesFromParams(coordinates: string[]) {
    const mapCoordinates = { ...DEFAULT_MAP_COORDINATES };
    if (coordinates && coordinates.length === 4) {
        mapCoordinates.n = parseFloat(coordinates[0].slice(2));
        mapCoordinates.e = parseFloat(coordinates[1].slice(2));
        mapCoordinates.s = parseFloat(coordinates[2].slice(2));
        mapCoordinates.w = parseFloat(coordinates[3].slice(2));
    }
    return mapCoordinates;
}

export function getMapZoneCoordinatesFromMapCoordinates(coordinates: TMapCoordinates) {
    return [
        [coordinates.w, coordinates.n],
        [coordinates.e, coordinates.n],
        [coordinates.e, coordinates.s],
        [coordinates.w, coordinates.s],
        [coordinates.w, coordinates.n],
    ];
}

export function getNewsCoordinatesFromMapCoordinates(coordinates?: TMapCoordinates) {
    if (coordinates) return `n_${coordinates.n},e_${coordinates.e},s_${coordinates.s},w_${coordinates.w}`;
    else {
        return `n_${DEFAULT_MAP_COORDINATES.n},e_${DEFAULT_MAP_COORDINATES.e},s_${DEFAULT_MAP_COORDINATES.s},w_${DEFAULT_MAP_COORDINATES.w}`;
    }
}

export const fetchSearchProperties = async (
    propertiesStore: PropertiesStore,
    listStore: ListStore<TPropertyListingMdl>,
    history: H.History,
    forceReload = false,
    deepListOnly = false,
) => {
    propertiesStore.resetItems();
    let p1, p2, p3, p4, p5, p6, p7;
    propertiesStore.deepList([
        ...listStore.filters.filter((filter) => filter.id !== "location"),
        {
            id: "location",
            value: {
                geoZoneCoordinates: propertiesStore.searchParams.mapCoordinates.n
                    ? [
                          [
                              propertiesStore.searchParams.mapCoordinates.w,
                              propertiesStore.searchParams.mapCoordinates.n,
                          ],
                          [
                              propertiesStore.searchParams.mapCoordinates.e,
                              propertiesStore.searchParams.mapCoordinates.n,
                          ],
                          [
                              propertiesStore.searchParams.mapCoordinates.e,
                              propertiesStore.searchParams.mapCoordinates.s,
                          ],
                          [
                              propertiesStore.searchParams.mapCoordinates.w,
                              propertiesStore.searchParams.mapCoordinates.s,
                          ],
                          [
                              propertiesStore.searchParams.mapCoordinates.w,
                              propertiesStore.searchParams.mapCoordinates.n,
                          ],
                      ]
                    : DEFAULT_MAP_COORDINATES_ZONE,
            },
            type: TFilterType.ZONE,
        },
    ]);
    if (deepListOnly) return;

    const filterBeachFront = listStore.filters.find((filter) => filter.id === "features.beachfront");
    const filterLuxury = listStore.filters.find((filter) => filter.id === "features.luxury");
    const filterPropertyType = listStore.filters.find((filter) => filter.id === "type");

    if (!geoZonesStore.geoZone) {
        const northEastSouthWest = getNewsCoordinatesFromMapCoordinates(propertiesStore.searchParams.mapCoordinates);
        const url = getUrlSearchFromFilters(listStore.filters);
        if (filterPropertyType && (filterBeachFront || filterLuxury)) {
            // ("CAS 1 filterPropertyType && filterBeachFront");
            p1 = i18next.t("property.types." + filterPropertyType.value[0]).toLowerCase();
            p2 = reformatStringForUrls(
                i18next.t(filterBeachFront ? "property.features.beachfront" : "property.features.luxury"),
            );
            if (!propertiesStore.searchParams.mapCoordinates) {
                // ("CAS 1-1 !mapCoordinate");

                if (listStore.selectedPage > 1) {
                    p3 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p4 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p3 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            } else {
                // ("CAS 1-2 mapCoordinate");
                p3 = northEastSouthWest;
                if (listStore.selectedPage > 1) {
                    p4 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p5 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p4 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            }
        } else if (filterPropertyType || filterBeachFront || filterLuxury) {
            // ("CAS 2 PROPERTY TYPE ou BEACHFRONT ou LUXURY );
            if (!filterPropertyType) {
                p1 = filterBeachFront
                    ? reformatStringForUrls(i18next.t("property.features.beachfront"))
                    : reformatStringForUrls(i18next.t("property.features.luxury"));
            } else {
                p1 = i18next.t("property.types." + filterPropertyType.value[0]).toLowerCase();
            }

            if (!propertiesStore.searchParams.mapCoordinates) {
                // ("CAS 2-1 !mapCoordinates");
                if (listStore.selectedPage > 1) {
                    p2 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p3 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p2 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            } else {
                // ("CAS 2-2 !mapCoordinates");
                p2 = northEastSouthWest;
                if (listStore.selectedPage > 1) {
                    p3 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p4 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p3 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            }
        } else {
            // ("CAS 3  PAS TYPE PAS BEACHFRONT PAS LUXURY");
            p1 = northEastSouthWest;
            if (listStore.selectedPage > 1) {
                p2 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                p3 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
            } else {
                p2 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
            }
        }

        propertiesStore.resetItems();
        await updateSearchFilter(listStore, [
            {
                id: "location",
                value: {
                    geoZoneCoordinates: [
                        [propertiesStore.searchParams.mapCoordinates.w, propertiesStore.searchParams.mapCoordinates.n],
                        [propertiesStore.searchParams.mapCoordinates.e, propertiesStore.searchParams.mapCoordinates.n],
                        [propertiesStore.searchParams.mapCoordinates.e, propertiesStore.searchParams.mapCoordinates.s],
                        [propertiesStore.searchParams.mapCoordinates.w, propertiesStore.searchParams.mapCoordinates.s],
                        [propertiesStore.searchParams.mapCoordinates.w, propertiesStore.searchParams.mapCoordinates.n],
                    ],
                },
                type: TFilterType.ZONE,
            },
        ]);
        history.push(URLS.buy(undefined, p1, p2, p3, p4, p5) + (url ? "?" + url : ""));
    } else {
        const isDifferentGeoZone =
            geoZonesStore.geoZone._id !==
                listStore.filters.find((filter) => filter.id === "location")?.value?.geoZoneId || forceReload;

        propertiesStore.resetItems();
        const { isCity, isNeighbourhood } = geoZonesStore.isType;
        const { city, neighbourhood, province, provinceLong } = geoZonesStore.address;

        if (isDifferentGeoZone) {
            propertiesStore.fetchBtns(
                [
                    {
                        id: "location",
                        value: {
                            geoZoneId: geoZonesStore.geoZone?._id,
                        },
                        type: TFilterType.ZONE,
                    },
                ],
                true,
                filterPropertyType?.value[0],
            );
        }
        await updateSearchFilter(
            listStore,
            [
                {
                    id: "location",
                    value: {
                        geoZoneId: geoZonesStore.geoZone._id,
                    },
                    type: TFilterType.ZONE,
                },
            ],
            true,
            isDifferentGeoZone,
        );
        const url = getUrlSearchFromFilters(listStore.filters);
        if (isNeighbourhood) {
            p1 = reformatStringForUrls(province);
            p2 = reformatStringForUrls(city);
            p3 = reformatStringForUrls(neighbourhood);
            if (filterPropertyType && (filterBeachFront || filterLuxury)) {
                p4 = i18next.t("property.types." + filterPropertyType.value[0]).toLowerCase();
                p5 = filterBeachFront
                    ? reformatStringForUrls(i18next.t("property.features.beachfront"))
                    : reformatStringForUrls(i18next.t("property.features.luxury"));
                if (listStore.selectedPage > 1) {
                    p6 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p7 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p6 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            } else if (filterPropertyType) {
                p4 = reformatStringForUrls(i18next.t("property.typesUrl." + filterPropertyType.value[0]));
                if (listStore.selectedPage > 1) {
                    p5 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p6 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p5 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            } else if (filterBeachFront || filterLuxury) {
                p4 = filterBeachFront
                    ? reformatStringForUrls(i18next.t("property.features.beachfront"))
                    : reformatStringForUrls(i18next.t("property.features.luxury"));
                if (listStore.selectedPage > 1) {
                    p5 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p6 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p5 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            } else {
                if (listStore.selectedPage > 1) {
                    p4 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p5 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p4 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            }
        } else if (isCity) {
            p1 = reformatStringForUrls(province);
            p2 = reformatStringForUrls(city);
            if (filterPropertyType && (filterBeachFront || filterLuxury)) {
                p3 = i18next.t("property.types." + filterPropertyType.value[0]).toLowerCase();
                p4 = filterBeachFront
                    ? reformatStringForUrls(i18next.t("property.features.beachfront"))
                    : reformatStringForUrls(i18next.t("property.features.luxury"));
                if (listStore.selectedPage > 1) {
                    p5 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p6 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p5 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            } else if (filterPropertyType) {
                p3 = i18next.t("property.types." + filterPropertyType.value[0]).toLowerCase();
                if (listStore.selectedPage > 1) {
                    p4 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p5 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p4 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            } else if (filterBeachFront || filterLuxury) {
                p3 = filterBeachFront
                    ? reformatStringForUrls(i18next.t("property.features.beachfront"))
                    : reformatStringForUrls(i18next.t("property.features.luxury"));
                if (listStore.selectedPage > 1) {
                    p4 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p5 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p4 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            } else {
                if (listStore.selectedPage > 1) {
                    p3 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p5 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p3 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            }
        } else {
            // GEO_ZONE_TYPE.PROVINCE
            p1 = reformatStringForUrls(provinceLong);
            if (filterPropertyType && (filterBeachFront || filterLuxury)) {
                p2 = i18next.t("property.types." + filterPropertyType.value[0]).toLowerCase();
                p3 = filterBeachFront
                    ? reformatStringForUrls(i18next.t("property.features.beachfront"))
                    : reformatStringForUrls(i18next.t("property.features.luxury"));
                if (listStore.selectedPage > 1) {
                    p4 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p5 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p4 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            } else if (filterPropertyType) {
                p2 = i18next.t("property.types." + filterPropertyType.value[0]).toLowerCase();
                if (listStore.selectedPage > 1) {
                    p4 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p5 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p4 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            } else if (filterBeachFront || filterLuxury) {
                p2 = filterBeachFront
                    ? reformatStringForUrls(i18next.t("property.features.beachfront"))
                    : reformatStringForUrls(i18next.t("property.features.luxury"));
                if (listStore.selectedPage > 1) {
                    p4 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p5 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p4 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            } else {
                if (listStore.selectedPage > 1) {
                    p2 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p3 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p3 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            }
        }

        const newUrl =
            URLS.buy(
                undefined,
                reformatStringForUrls(p1),
                p2 ?? reformatStringForUrls(city ?? propertiesStore.addressParams.city ?? DEFAULT_CITY),
                p3,
                p4,
                p5,
                p6,
                p7,
            ) + (url ? "?" + url : "");

        if (newUrl !== history.location.pathname + history.location.search) {
            history.push(newUrl);
        }
    }
};

export const fetchSearchPropertiesDebounced = _.debounce(fetchSearchProperties, 1000);
