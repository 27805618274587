import { TBuilding, TFloor, TInventory, TUnitOfInventory } from "properties/_models/PropertyMdl";
import { INVENTORY_STATUS, TStatusByPhase } from "units/_models/UnitTypeMdl";
import dayjs from "dayjs";
import _ from "lodash";

export function getAllFloorsOfBuildings(buildings: TBuilding[]) {
    const allFloors: TFloor[] = [];
    buildings.map((building) =>
        building.floors.map((floor) => allFloors.push({ ...floor, buildingName: building.name })),
    );
    return allFloors;
}

export function getAllBuildingsOfInventories(inventories: TInventory[]): TBuilding[] {
    const allBuildings: TBuilding[] = [];
    inventories.map((inventory) => inventory.buildings.map((building) => allBuildings.push(building)));
    return allBuildings;
}

export function getAllUnitsOfInventory(inventory: TInventory[]): TUnitOfInventory[] {
    const units: TUnitOfInventory[] = [];
    inventory.map((inventory, index) =>
        inventory.buildings.map((building) =>
            building.floors.map((floor) => floor?.units?.map((unit) => units.push({ ...unit, phaseIndex: index }))),
        ),
    );
    return units;
}

export function getAllUnitsForOneInventory(inventory: TInventory, unitId: string): TUnitOfInventory[] {
    const units: TUnitOfInventory[] = [];
    inventory.buildings.map((building, index) =>
        building.floors.map((floor) =>
            floor?.units?.map((unit) => {
                if (unit.unitId === unitId) {
                    units.push({ ...unit, phaseIndex: index });
                }
            }),
        ),
    );
    return units;
}

export function getAllAvailableUnitsOfInventory(
    inventory: TInventory[],
    status = INVENTORY_STATUS.AVAILABLE,
): TUnitOfInventory[] {
    return getAllUnitsOfInventory(inventory).filter((unit) => unit.status === status);
}

export function getUnitsOfInventory(inventory: TInventory[], unitId: string): TUnitOfInventory[] {
    return getAllUnitsOfInventory(inventory).filter((unit) => unit.unitId === unitId);
}

export function getUnitsByStatusFromInventory(
    inventory: TInventory[],
    unitId: string,
    status = INVENTORY_STATUS.AVAILABLE,
) {
    return getUnitsOfInventory(inventory, unitId).filter((unit) => unit.status === status);
}

export function getUnitsOfBuilding(buildings: TBuilding, unitId: string) {
    const unitOfBuilding: any[] = [];

    buildings.floors.map((floors, idx) => {
        floors?.units?.map((unit) => {
            if (unit.unitId === unitId) {
                unitOfBuilding.push({ ...unit, floorName: floors.name, floorIndex: idx });
            }
        });
    });
    return unitOfBuilding;
}

export function isSoldOutUnitInInventory(inventory: TInventory[], unitId: string) {
    return (
        getUnitsOfInventory(inventory, unitId).length > 0 &&
        !getAllUnitsOfInventory(inventory)
            .filter((availableUnit) => availableUnit.unitId === unitId)
            .some((unit) => unit.status !== INVENTORY_STATUS.SOLD)
    );
}

export function getUnitStatusByPhase(inventory: TInventory[], unitId: string): TStatusByPhase[] {
    const statusPerPhase: TStatusByPhase[] = [];
    inventory.map((inventory) => {
        const units = getAllUnitsForOneInventory(inventory, unitId);
        let status = INVENTORY_STATUS.SOLD;
        if (units.length > 0) {
            const isAtLeastOneAvailableUnit = units.some((unit) => unit.status === INVENTORY_STATUS.AVAILABLE);
            const isAtLeastOneReservedUnit = units.some((unit) => unit.status === INVENTORY_STATUS.RESERVED);
            status = isAtLeastOneAvailableUnit
                ? INVENTORY_STATUS.AVAILABLE
                : isAtLeastOneReservedUnit
                ? INVENTORY_STATUS.RESERVED
                : INVENTORY_STATUS.SOLD;
            statusPerPhase.push({ deliveryDate: inventory?.phase?.deliveryDate, status: status });
        }
    });
    return statusPerPhase;
}

export function groupStatusUnitByPhase(statusByPhase: TStatusByPhase[]) {
    const groupByDate = _.groupBy(statusByPhase, "deliveryDate");
    const statusByPhases: TStatusByPhase[] = [];
    for (const groupByDateKey in groupByDate) {
        const statusByPhaseByUnitId = groupByDate[groupByDateKey];
        let status = INVENTORY_STATUS.SOLD;
        const isAtLeastOneAvailableUnit = statusByPhaseByUnitId.some(
            (unit) => unit.status === INVENTORY_STATUS.AVAILABLE,
        );
        const isAtLeastOneReservedUnit = statusByPhaseByUnitId.some(
            (unit) => unit.status === INVENTORY_STATUS.RESERVED,
        );
        status = isAtLeastOneAvailableUnit
            ? INVENTORY_STATUS.AVAILABLE
            : isAtLeastOneReservedUnit
            ? INVENTORY_STATUS.RESERVED
            : INVENTORY_STATUS.SOLD;
        statusByPhases.push({ deliveryDate: statusByPhaseByUnitId[0].deliveryDate, status: status });
    }
    return statusByPhases;
}

export function getUnitTypesOfPhase(unitId: string, phase: TInventory) {
    const units: TUnitOfInventory[] = [];
    phase.buildings.map((building) =>
        building.floors.map((floor) =>
            floor?.units?.filter((unit) => unit.unitId === unitId).map((unit) => units.push(unit)),
        ),
    );
    return units;
}

export function getPropertyStatusFromUnits(
    units: ({ [key: string]: any } & { status?: INVENTORY_STATUS })[],
): INVENTORY_STATUS {
    let status = INVENTORY_STATUS.SOLD;
    if (units) {
        const isAtLeastOneAvailableUnit = units.some(
            (unit) => unit.status && unit.status === INVENTORY_STATUS.AVAILABLE,
        );
        const isAtLeastOneReservedUnit = units.some((unit) => unit.status && unit.status === INVENTORY_STATUS.RESERVED);
        status = isAtLeastOneAvailableUnit
            ? INVENTORY_STATUS.AVAILABLE
            : isAtLeastOneReservedUnit
            ? INVENTORY_STATUS.RESERVED
            : INVENTORY_STATUS.SOLD;
    }
    return status;
}

export function getStatusOfUnitInInventory(inventory: TInventory[], unitId: string): INVENTORY_STATUS {
    const units = getUnitsOfInventory(inventory, unitId);
    return getPropertyStatusFromUnits(units);
}

export function getAvailableUnitByPhaseOfInventory(
    inventorys: TInventory[],
    unitId: string,
    status = INVENTORY_STATUS.AVAILABLE,
): { deliveryDate?: dayjs.Dayjs; numberOfUnitAvailable: number }[] {
    const statusUnitPerPhase: { deliveryDate?: dayjs.Dayjs; numberOfUnitAvailable: number }[] = [];
    inventorys.map((inventory) => {
        const unitsAvailabelByUnitType = getAllUnitsForOneInventory(inventory, unitId).filter(
            (unit) => unit.status === status,
        );
        statusUnitPerPhase.push({
            deliveryDate: inventory.phase.deliveryDate,
            numberOfUnitAvailable: unitsAvailabelByUnitType.length,
        });
    });
    return statusUnitPerPhase;
}

export function getIndexOfFirstAvailableUnitInventory(inventory: TInventory[]) {
    const units = getAllUnitsOfInventory(inventory);
    const firstAvUnit = units.find((unit) => unit.status === INVENTORY_STATUS.AVAILABLE);
    return firstAvUnit?.phaseIndex ?? 0;
}
