import React, { useEffect, useState } from "react";
import { pagesStore } from "pages/_stores/pagesStore";
import { ListStorePaginator } from "admin/_common/list/ListStorePaginator";
import { ListStore } from "_common/list/ListStore";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { NewsCardComponent, NewsCardComponentProps } from "components/news/newsCard/NewsCardComponent";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { TLang } from "_configs/sharedConfig";
import i18next from "i18next";
import { useParams } from "react-router";
import { TFilter } from "admin/_common/filters/TFilter";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { useTranslation } from "react-i18next";

export enum ITEM_MODEL {
    PAGE = "page",
    ARTICLE = "article",
}

interface LocalizedContent {
    [key: string]: {
        title: string;
        meta: {
            description: string;
        };
        image: string;
        url: string;
        publishedAt: string;
    };
}

interface Article {
    _id: string;
    localized: LocalizedContent;
    publishedAt: string;
    blogType: string;
}

function renderItem(type: ITEM_MODEL, item: any) {
    const lang = i18next.language;
    const props: NewsCardComponentProps = {};
    if (!item) return null;
    switch (type) {
        case ITEM_MODEL.ARTICLE: {
            const article = item as Article;

            const localizedContent = article.localized[lang as TLang];

            props.title = localizedContent?.title;
            props.text = localizedContent?.meta.description;
            props.image = localizedContent?.image;
            props.url = localizedContent?.url;
            props.date = localizedContent?.publishedAt;
            props.blogType = article.blogType;
            return (
                <Grid key={article._id} item xs={12} md={4}>
                    <NewsCardComponent {...props} />
                </Grid>
            );
        }
    }
    return null;
}

type PaginatedItemsComponentProps = {
    listStore: ListStore<any>;
    itemType: ITEM_MODEL;
};

const PaginatedItemsComponent = observer((props: PaginatedItemsComponentProps) => {
    return (
        <Grid container spacing={5}>
            {props.listStore.paginatedItems.map((item) => renderItem(props.itemType, item))}
        </Grid>
    );
});

type ItemsComponentProps = {
    listStore: ListStore<any>;
    itemType: ITEM_MODEL;
    store: any;
};

export function ItemsComponent(props: ItemsComponentProps) {
    const start = props.listStore.selectedPage * (props.listStore.pageSize ?? 1);
    const displayCount = props.listStore.pageSize < (props.listStore.count ?? 1);
    const loadingState = props.listStore.currentLoadingState;
    const { t } = useTranslation();

    if (!loadingState) return null;
    return (
        <div>
            <LoadableFromLoading
                loading={loadingState}
                renderer={(_status, error) => {
                    if (error) {
                        return <ErrorBlock error={error} />;
                    }
                    return (
                        <>
                            <PaginatedItemsComponent listStore={props.listStore} itemType={props.itemType} />
                            {props.listStore.paginatedItems.length > 3 && (
                                <div className="flex flex-col items-center my-10">
                                    <ListStorePaginator
                                        listStore={props.listStore}
                                        shape={"rounded"}
                                        variant={"outlined"}
                                        color={"secondary"}
                                    />
                                    {displayCount && (
                                        <div
                                            className={"mt-2 text-center text-xs text-[color:var(--color-text-muted)]"}
                                        >
                                            {t("blog.results", {
                                                start: start - props.listStore.pageSize + 1,
                                                end:
                                                    start > (props.listStore.count ?? 1)
                                                        ? props.listStore.count
                                                        : start,
                                                count: props.listStore.count,
                                            })}
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    );
                }}
            />
        </div>
    );
}

type Props = {
    item_model: ITEM_MODEL;
    pageSize: number;
    className?: string;
};

export function ItemsListComponent(props: Props) {
    let listStore;

    const [forceReload, setForceReload] = useState(false);
    const { blogType } = useParams();
    const filters: TFilter[] = [];

    useEffect(() => {
        if (blogType) setForceReload(true);
    }, [blogType]);
    switch (props.item_model) {
        case ITEM_MODEL.PAGE:
            listStore = pagesStore.getListStore("pagesList", props.pageSize);
            break;
        case ITEM_MODEL.ARTICLE:
            if (blogType && blogType !== "") {
                filters.push({
                    id: "blogType",
                    type: TFilterType.ENUM,
                    value: blogType.toUpperCase(),
                });
            }
            listStore = pagesStore.getListStore("articlesList", props.pageSize, filters, undefined, forceReload);
            break;
    }

    return (
        <div className={props.className}>
            <ItemsComponent itemType={props.item_model} listStore={listStore} store={pagesStore} />
        </div>
    );
}
