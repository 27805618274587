import React from "react";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./_css/breadcrumb.module.css";
import clsx from "clsx";
import { refreshPage } from "_common/_utils/pageUtils";
import { KeyboardArrowRight } from "@material-ui/icons";

export type TBreadcrumbItem = {
    itemLabelKey?: string;
    itemLabel?: string;
    url?: string;
    forceReload?: boolean;
};

type Props = {
    currentItem: TBreadcrumbItem;
    items?: TBreadcrumbItem[];
    noMarginBottom?: boolean;
    noHome?: boolean;
};

export function formatBreadcrumbLabel(itemLabel: string) {
    return itemLabel.replace(/_/g, " ");
}

export function Breadcrumb(props: Props) {
    const { t } = useTranslation();
    return (
        <div className={clsx(styles.container, "text-xs", { ["mb-5"]: !props.noMarginBottom })}>
            <ul className={"flex items-center"}>
                {!props.noHome && (
                    <>
                        <li className="flex items-center underline capitalize">
                            <Link to={URLS.home()}>{t("words.home")}</Link>
                        </li>
                        <KeyboardArrowRight fontSize={"small"} />
                    </>
                )}
                {props.items &&
                    props.items.map((item, idx) => (
                        <>
                            <li className="flex items-center underline capitalize" key={idx}>
                                <Link
                                    onClick={(e) => {
                                        if (item.forceReload && item.url) {
                                            e.preventDefault();
                                            refreshPage(item.url);
                                        }
                                    }}
                                    to={item.url ?? ""}
                                >
                                    {item.itemLabel ?? t(item.itemLabelKey ?? "")}
                                </Link>
                            </li>
                            <KeyboardArrowRight fontSize={"small"} />
                        </>
                    ))}
                <li className={"capitalize truncate"}>
                    {formatBreadcrumbLabel(props.currentItem.itemLabel ?? t(props.currentItem.itemLabelKey ?? ""))}
                </li>
            </ul>
        </div>
    );
}
