import React from "react";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import arrow from "../../../assets/images/rightArrowTiny.svg";

type Props = {
    property: TPropertyListingMdl;
};

export function PropertyCardHeader({ property }: Props) {
    const { t } = useTranslation();

    return (
        <div className={"flex_row justifyContent_spaceBetween"}>
            <div className={"text_small"}>{t("property.unitsAvailable", { count: property.units.length })}</div>
            <div className={"flex_row_center text_xxsmall"}>
                {t("words.viewAll")}
                <img src={arrow} alt={"arrow"} className={"ml_5"} />
            </div>
        </div>
    );
}
