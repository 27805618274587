import React, { PropsWithChildren, useState } from "react";
import { Collapse } from "@material-ui/core";
import clsx from "clsx";
import { KeyboardArrowDown } from "@material-ui/icons";

type Props = PropsWithChildren<{
    title: string;
    heightOfCollapsable: number;
    className?: string;
    titleClassName?: string;
}>;

export function CollapseSection({ title, children, heightOfCollapsable, className, titleClassName }: Props) {
    const [isCollapse, setIsCollapse] = useState(true);

    return (
        <div>
            <div
                className={clsx(
                    "text-sm flex_row_center justifyContent_spaceBetween cursor_pointer py-2 px-4",
                    className,
                )}
                onClick={() => setIsCollapse(!isCollapse)}
            >
                <div className={clsx("m-0 text_xsmall fontWeight_bold", titleClassName)}>{title}</div>
                <div
                    className={clsx("flex_center_center transition-all duration-[0.3s]", {
                        ["rotate-180"]: !isCollapse,
                    })}
                >
                    <KeyboardArrowDown fontSize={"large"} />
                </div>
            </div>
            <Collapse in={!isCollapse} collapsedHeight={heightOfCollapsable}>
                {children}
            </Collapse>
        </div>
    );
}
