import React from "react";
import { CommunitiesTitle } from "communities/components/CommunitiesTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { ListStore } from "_common/list/ListStore";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { PropertyCardListing } from "properties/propertyCard/PropertyCardListing";

SwiperCore.use([Navigation]);

const NAME = "communitiesCardSlider";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};
export function CommunitiesCardSlider({ listStore }: Props) {
    return (
        <>
            <CommunitiesTitle title={"Projects for Sale in Costa Palmas"} delimiter={3} />
            <div id={NAME} className={"relative "}>
                <Swiper
                    loop
                    navigation={{
                        prevEl: `.swiper-button-prev.${NAME}`,
                        nextEl: `.swiper-button-next.${NAME}`,
                    }}
                    slidesPerView={3.1}
                    spaceBetween={20}
                    breakpoints={{
                        300: {
                            slidesPerView: 1,
                        },
                        690: {
                            slidesPerView: 1,
                        },
                        1180: {
                            slidesPerView: 2.5,
                        },
                        1400: {
                            slidesOffsetBefore: 20,
                            slidesPerView: 3,
                        },
                    }}
                >
                    {listStore.items.map((property) => (
                        <>
                            {property && (
                                <SwiperSlide key={property._id}>
                                    <PropertyCardListing property={property} key={property?._id} />
                                </SwiperSlide>
                            )}
                        </>
                    ))}
                </Swiper>
            </div>
        </>
    );
}
