import React from "react";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/zisla-logo-white.png";

export function FooterDescription() {
    const { t } = useTranslation();

    return (
        <>
            <img src={logo} alt={"Logo Zisla"} height={62} width={205} />
            <div className={"text_normal mt_20"}>{t("footer.description")}</div>
        </>
    );
}
