import { IAddressMdl } from "users/_models/UserMdl";
import { appConfig } from "_configs/appConfig";
import { TAddressMdl } from "properties/_models/PropertyMdl";

const googleApiUrl = "https://maps.googleapis.com/maps/api";

export const googleUri = {
    locationFromAddress: (address: IAddressMdl | TAddressMdl | string) => {
        return typeof address === "string"
            ? `${googleApiUrl}/geocode/json?address=${address}&key=${appConfig.google.apiKey}`
            : `${googleApiUrl}/geocode/json?address=${address.street} ${address.extra} ${address.city} ${address.province} ${address.postalCode} ${address.country}&key=${appConfig.google.apiKey}`;
    },
    geoLocate: () => {
        return `https://www.googleapis.com/geolocation/v1/geolocate?key=${appConfig.google.apiKey}`;
    },
};
