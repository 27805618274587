import React from "react";
import { propertiesBuyingStore } from "properties/_stores/propertiesBuyingStore";
import { CardTypesList } from "home/CardTypesList";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { useTranslation } from "react-i18next";
import { usePropertiesPageMeta } from "properties/usePropertiesPageMeta";
import { ListStore } from "_common/list/ListStore";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { usePropertyPageContext } from "properties/PropertyPageContext";
import { MAP_OR_LIST_SELECTED } from "properties/PropertiesView";
import { Container } from "@material-ui/core";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};

export function PropertiesTypesSection({ listStore }: Props) {
    propertiesBuyingStore.fetchSortedTypes();
    const { t } = useTranslation();
    const propertiesStore = usePropertiesStore();
    const { mapOrListSelected } = usePropertyPageContext();
    const { geoZoneName } = usePropertiesPageMeta(propertiesStore, listStore);
    const breakPoints =
        mapOrListSelected === MAP_OR_LIST_SELECTED.LIST
            ? {
                  500: {
                      slidesPerView: 1.5,
                  },
                  600: {
                      slidesPerView: 2,
                  },
                  750: {
                      slidesPerView: 3,
                  },
                  900: {
                      slidesPerView: 3.5,
                  },
                  1280: {
                      slidesPerView: 5,
                  },
              }
            : {
                  500: {
                      slidesPerView: 1.5,
                  },
                  600: {
                      slidesPerView: 2,
                  },
                  750: {
                      slidesPerView: 2.5,
                  },
                  900: {
                      slidesPerView: 2.5,
                  },
                  1280: {
                      slidesPerView: 3,
                  },
              };
    return (
        <Container className={"max-w-[1260px]"}>
            <h2 className={"mb-4 text-center"}>
                {geoZoneName
                    ? t(`propertiesPage.propertyTypeSection.titleGeozone`, { geoZoneName: geoZoneName })
                    : t(`propertiesPage.propertyTypeSection.title`)}
            </h2>
            <LoadableFromLoading
                noSpinner
                loading={propertiesBuyingStore.sortTypesState}
                renderer={(_status, error) => {
                    if (error) return null;
                    return (
                        <CardTypesList
                            removeGeoZone={false}
                            items={propertiesBuyingStore.sortTypes}
                            breakPoints={breakPoints}
                        />
                    );
                }}
            />
        </Container>
    );
}
