import { LazyLoadImage } from "react-lazy-load-image-component";
import React from "react";

type Props = {
    text: string;
    src: string;
    alt: string;
    size?: number;
};

export function CommunitiesSectionLogo({ size = 16, src, alt, text }: Props) {
    return (
        <div className={"flex gap-6"}>
            <LazyLoadImage width={size} height={size} src={src} alt={alt} />
            <p>{text}</p>
        </div>
    );
}
