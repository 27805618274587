import { TDef } from "admin/pages/builder/props/values/ValueEditor";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";

export type TOptionConfigDef = TDef & {
    key: string;
    type: string;
    types?: PROPERTY_TYPE[];
    filter?: boolean;
    optionType: OPTION_TYPE;
    isPopular?: boolean;
    max?: number;
};

export enum OPTION_TYPE {
    AMENITIES = "amenities",
    FEATURES = "features",
    PARTICULARITY = "particularity",
    PARKING = "parking",
    UNIT = "unit",
    VIEWS = "views",
}

export enum PROPERTY_OPTION {
    amphitheater = "amphitheater",
    artGallery = "artGallery",
    artWorkshop = "artWorkshop",
    bagageRoom = "bagageRoom",
    bar = "bar",
    bbqArea = "bbqArea",
    beachclub = "beachclub",
    bicycleParking = "bicycleParking",
    bicycles = "bicycles",
    businessCenter = "businessCenter",
    cabanas = "cabanas",
    cenote = "cenote",
    cinema = "cinema",
    climbingWall = "climbingWall",
    coffeeShop = "coffeeShop",
    commonLaundry = "commonLaundry",
    concierge = "concierge",
    dock = "dock",
    dogPark = "dogPark",
    electricVehicleChargingStation = "electricVehicleChargingStation",
    eventRoom = "eventRoom",
    firepit = "firepit",
    floatingRoom = "floatingRoom",
    garbageChute = "garbageChute",
    garden = "garden",
    gatedCommunity = "gatedCommunity",
    guestApartments = "guestApartments",
    gym = "gym",
    hammock = "hammock",
    hotelServices = "hotelServices",
    icePit = "icePit",
    intercom = "intercom",
    jacuzzi = "jacuzzi",
    joggingTrack = "joggingTrack",
    juiceOrganicBar = "juiceOrganicBar",
    kidsClub = "kidsClub",
    library = "library",
    lookoutTower = "lookoutTower",
    loungeArea = "loungeArea",
    marina = "marina",
    marketplace = "marketplace",
    massageRoom = "massageRoom",
    meditationRoom = "meditationRoom",
    musicRoom = "musicRoom",
    observationDeck = "observationDeck",
    outdoorGym = "outdoorGym",
    ownersLounge = "ownersLounge",
    paddleCourt = "paddleCourt",
    playgroundArea = "playgroundArea",
    pool = "pool",
    privateBeach = "privateBeach",
    readingArea = "readingArea",
    receptionDeskLobby = "receptionDeskLobby",
    receptionHall = "receptionHall",
    restaurant = "restaurant",
    sauna = "sauna",
    security = "security",
    shoppingCenter = "shoppingCenter",
    snackBar = "snackBar",
    soccerField = "soccerField",
    spa = "spa",
    storage = "storage",
    sundeck = "sundeck",
    temazcal = "temazcal",
    terrace = "terrace",
    walkingTrails = "walkingTrails",
    wetBar = "wetBar",
    yachtClub = "yachtClub",
    yogaLounge = "yogaLounge",
    zenDeck = "zenDeck",
    balcony = "balcony",
    bikePathAccess = "bikePathAccess",
    centralAirConditioner = "centralAirConditioner",
    composting = "composting",
    elevator = "elevator",
    leedCertification = "leedCertification",
    petFriendly = "petFriendly",
    securityCameras = "securityCameras",
    wallMountAirConditioner = "wallMountAirConditioner",
    mobilityAccess = "mobilityAccess",
    internet = "internet",
    appliances = "appliances",
    automatedBlinds = "automatedBlinds",
    blinds = "blinds",
    centralAirConditioning = "centralAirConditioning",
    controlledAccess = "controlledAccess",
    dehumidificationSystem = "dehumidificationSystem",
    furnishedEquipped = "furnishedEquipped",
    furniturePackagesAvailable = "furniturePackagesAvailable",
    ledLightning = "ledLightning",
    lockOffUnitsAvailable = "lockOffUnitsAvailable",
    muralAirConditioning = "muralAirConditioning",
    pluvialWaterSystem = "pluvialWaterSystem",
    recycling = "recycling",
    smartHome = "smartHome",
    solarPanels = "solarPanels",
    washerDryerInSuit = "washerDryerInSuit",
    wasteWaterTreatmentPlant = "wasteWaterTreatmentPlant",
    waterSoftenerSystem = "waterSoftenerSystem",
    covered = "covered",
    garage = "garage",
    outdoor = "outdoor",
    street = "street",
    underground = "underground",
    beachfront = "beachfront",
    closingInPesos = "closingInPesos",
    squareFeet = "squareFeet",
    closingInMultipleCurrencies = "closingInMultipleCurrencies",
    rentalProgram = "rentalProgram",
    financingAvailable = "financingAvailable",
    mortgageCredit = "mortgageCredit",
    fiberInternet = "fiberInternet",
    commonAreaWifi = "commonAreaWifi",
    bitcoinAccepted = "bitcoinAccepted",
    longTermFinancing = "longTermFinancing",
    lowDown = "lowDown",
    luxury = "luxury",
    golf = "golf",
    course = "course",
    miniPutt = "miniPutt",
}

export const PROPERTY_AMENITIES: TOptionConfigDef[] = [
    { key: PROPERTY_OPTION.amphitheater, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.artGallery, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.artWorkshop, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.bagageRoom, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.bar, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.bbqArea, type: "boolean", filter: true, optionType: OPTION_TYPE.AMENITIES },
    {
        key: PROPERTY_OPTION.beachclub,
        type: "boolean",
        filter: true,
        optionType: OPTION_TYPE.AMENITIES,
    },
    { key: PROPERTY_OPTION.bicycleParking, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.bicycles, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    {
        key: PROPERTY_OPTION.businessCenter,
        type: "boolean",
        filter: true,
        optionType: OPTION_TYPE.AMENITIES,
    },
    { key: PROPERTY_OPTION.cabanas, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.cenote, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.cinema, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.climbingWall, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.coffeeShop, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    {
        key: PROPERTY_OPTION.commonLaundry,
        type: "boolean",
        filter: true,
        optionType: OPTION_TYPE.AMENITIES,
    },
    {
        key: PROPERTY_OPTION.concierge,
        type: "boolean",
        filter: true,
        optionType: OPTION_TYPE.AMENITIES,
    },
    { key: PROPERTY_OPTION.dock, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.dogPark, type: "boolean", filter: true, optionType: OPTION_TYPE.AMENITIES },
    {
        key: PROPERTY_OPTION.electricVehicleChargingStation,
        type: "boolean",
        optionType: OPTION_TYPE.AMENITIES,
    },
    { key: PROPERTY_OPTION.eventRoom, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.firepit, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.floatingRoom, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.garbageChute, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.garden, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.guestApartments, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.pool, type: "boolean", filter: true, optionType: OPTION_TYPE.AMENITIES, isPopular: true },
    { key: PROPERTY_OPTION.gym, type: "boolean", filter: true, optionType: OPTION_TYPE.AMENITIES, isPopular: true },
    { key: PROPERTY_OPTION.hammock, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    {
        key: PROPERTY_OPTION.hotelServices,
        type: "boolean",
        filter: true,
        optionType: OPTION_TYPE.AMENITIES,
    },
    { key: PROPERTY_OPTION.icePit, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.jacuzzi, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.joggingTrack, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.juiceOrganicBar, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.kidsClub, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.library, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.lookoutTower, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.loungeArea, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.marina, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.marketplace, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.massageRoom, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.meditationRoom, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.musicRoom, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.observationDeck, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.outdoorGym, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.ownersLounge, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.paddleCourt, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.playgroundArea, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.privateBeach, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.readingArea, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.receptionDeskLobby, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.receptionHall, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.restaurant, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.sauna, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.shoppingCenter, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.snackBar, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.soccerField, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.spa, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.storage, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.sundeck, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.temazcal, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.terrace, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.walkingTrails, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.wetBar, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.yachtClub, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.yogaLounge, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.zenDeck, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.golf, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.course, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.miniPutt, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
];

export const PROPERTY_FEATURES: TOptionConfigDef[] = [
    { key: PROPERTY_OPTION.balcony, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.composting, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    {
        key: PROPERTY_OPTION.electricVehicleChargingStation,
        type: "boolean",
        optionType: OPTION_TYPE.FEATURES,
    },
    { key: PROPERTY_OPTION.garbageChute, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.intercom, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.leedCertification, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.mobilityAccess, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.internet, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.automatedBlinds, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.blinds, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.controlledAccess, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.dehumidificationSystem, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.ledLightning, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.muralAirConditioning, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.pluvialWaterSystem, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.recycling, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.smartHome, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.solarPanels, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    {
        key: PROPERTY_OPTION.wasteWaterTreatmentPlant,
        type: "boolean",
        optionType: OPTION_TYPE.FEATURES,
    },
    { key: PROPERTY_OPTION.waterSoftenerSystem, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.security, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.fiberInternet, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.commonAreaWifi, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.washerDryerInSuit, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.lockOffUnitsAvailable, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.furnishedEquipped, type: "boolean", optionType: OPTION_TYPE.FEATURES },

    { key: PROPERTY_OPTION.elevator, type: "boolean", filter: true, optionType: OPTION_TYPE.FEATURES },
    {
        key: PROPERTY_OPTION.gatedCommunity,
        type: "boolean",
        filter: true,
        optionType: OPTION_TYPE.FEATURES,
    },
    {
        key: PROPERTY_OPTION.petFriendly,
        type: "boolean",
        filter: true,
        optionType: OPTION_TYPE.FEATURES,
    },
    {
        key: PROPERTY_OPTION.securityCameras,
        type: "boolean",
        filter: true,
        optionType: OPTION_TYPE.FEATURES,
    },
    {
        key: PROPERTY_OPTION.appliances,
        type: "boolean",
        filter: true,
        optionType: OPTION_TYPE.FEATURES,
    },
    {
        key: PROPERTY_OPTION.centralAirConditioning,
        type: "boolean",
        filter: true,
        optionType: OPTION_TYPE.FEATURES,
    },
    {
        key: PROPERTY_OPTION.furniturePackagesAvailable,
        type: "boolean",
        filter: true,
        optionType: OPTION_TYPE.FEATURES,
    },

    { key: PROPERTY_OPTION.beachfront, type: "boolean", optionType: OPTION_TYPE.PARTICULARITY, isPopular: true },
    { key: PROPERTY_OPTION.luxury, type: "boolean", optionType: OPTION_TYPE.PARTICULARITY, isPopular: true },
    { key: PROPERTY_OPTION.closingInPesos, type: "boolean", optionType: OPTION_TYPE.PARTICULARITY },
    { key: PROPERTY_OPTION.squareFeet, type: "boolean", optionType: OPTION_TYPE.PARTICULARITY },
    {
        key: PROPERTY_OPTION.closingInMultipleCurrencies,
        type: "boolean",
        optionType: OPTION_TYPE.PARTICULARITY,
    },
    { key: PROPERTY_OPTION.rentalProgram, type: "boolean", optionType: OPTION_TYPE.PARTICULARITY },
    {
        key: PROPERTY_OPTION.financingAvailable,
        type: "boolean",
        optionType: OPTION_TYPE.PARTICULARITY,
    },
    { key: PROPERTY_OPTION.mortgageCredit, type: "boolean", optionType: OPTION_TYPE.PARTICULARITY },
    { key: PROPERTY_OPTION.bitcoinAccepted, type: "boolean", optionType: OPTION_TYPE.PARTICULARITY },
    { key: PROPERTY_OPTION.longTermFinancing, type: "boolean", optionType: OPTION_TYPE.PARTICULARITY },
    { key: PROPERTY_OPTION.lowDown, type: "boolean", optionType: OPTION_TYPE.PARTICULARITY },

    { key: PROPERTY_OPTION.covered, type: "boolean", optionType: OPTION_TYPE.PARKING },
    { key: PROPERTY_OPTION.garage, type: "boolean", optionType: OPTION_TYPE.PARKING },
    { key: PROPERTY_OPTION.outdoor, type: "boolean", optionType: OPTION_TYPE.PARKING },
    { key: PROPERTY_OPTION.street, type: "boolean", optionType: OPTION_TYPE.PARKING },
    { key: PROPERTY_OPTION.underground, type: "boolean", optionType: OPTION_TYPE.PARKING },
];

export const PROPERTY_FEATURES_AMENITIES = PROPERTY_AMENITIES.concat(PROPERTY_FEATURES);
