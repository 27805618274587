import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { settingsStore } from "settings/_stores/settingsStore";
import { ISettingsMdl } from "settings/_models/SettingsMdl";
import { useTranslation } from "react-i18next";
import phoneWhite from "../../assets/images/icons/phoneWhite.svg";
import email from "../../assets/images/icons/email.svg";
import canada from "../../assets/images/flags/canRound.svg";
import mexico from "../../assets/images/flags/mexRound.svg";
import usa from "../../assets/images/flags/usRound.svg";
import styles from "./_css/footerContact.module.css";
import sharedConfig from "_configs/sharedConfig";
import clsx from "clsx";
import { FooterCollapsedTitle } from "main/footer/FooterCollapsedTitle";
import { FooterSocial } from "main/footer/FooterSocial";

export function FooterContact() {
    const settings = settingsStore.getOne() as ISettingsMdl;
    const { t } = useTranslation();

    return (
        <FooterCollapsedTitle title={t("words.contact")}>
            <div className={styles.container}>
                {settings?.contacts?.address && (
                    <div className={clsx(styles.contactBlock, "pb_20 mb_20")}>
                        <div className={"flex_row gap-1 mb_15"}>
                            <LazyLoadImage src={usa} />
                            <LazyLoadImage src={canada} />
                            <a
                                href={"https://maps.app.goo.gl/CiatK6oYB1SMqECx7"}
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                                className={styles.link}
                            >
                                {t("footer.viewAdress")}
                            </a>
                        </div>
                        <a href={"tel:" + sharedConfig.caPhone} className={clsx("flex_row gap-3", styles.link)}>
                            <LazyLoadImage src={phoneWhite} />
                            {sharedConfig.caPhone}
                        </a>
                    </div>
                )}
                {settings?.contacts?.mexicanAddress && (
                    <div className={clsx(styles.contactBlock, "pb_20")}>
                        <div className={"flex_row gap-3 mb_15"}>
                            <LazyLoadImage src={mexico} />
                            <a
                                href={"https://maps.app.goo.gl/V4mk2aXRoh9FDAUs6"}
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                                className={styles.link}
                            >
                                {t("footer.viewAdress")}
                            </a>
                        </div>
                        <div hidden dangerouslySetInnerHTML={{ __html: settings.contacts.mexicanAddress }} />
                        <a href={"tel:" + sharedConfig.mexPhone} className={clsx("flex_row gap-3", styles.link)}>
                            <LazyLoadImage src={phoneWhite} />
                            {sharedConfig.mexPhone}
                        </a>
                    </div>
                )}
                <a href={"mailto:" + sharedConfig.email} className={clsx("flex_row gap-3 mb_15 mt_15", styles.link)}>
                    <LazyLoadImage src={email} />
                    {t("footer.mailUs")}
                </a>
                <div className={"desktopDisplay"}>
                    <FooterSocial />
                </div>
            </div>
        </FooterCollapsedTitle>
    );
}
