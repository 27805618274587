import React from "react";
import { PaginationItem, PaginationItemProps } from "@material-ui/lab";

interface CustomPaginationItemProps extends PaginationItemProps {
    rel?: string;
}

const CustomPaginationItem = ({ rel, ...rest }: CustomPaginationItemProps) => {
    return <PaginationItem {...rest} component={(itemProps) => <a {...itemProps} rel={rel} />} />;
};

export default CustomPaginationItem;
