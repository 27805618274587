import React from "react";
import clsx from "clsx";
import styles from "./_css/homeCardTypeOrCity.module.css";
import arrow from "../assets/images/rightArrowTinyPrimary.svg";
import { useTranslation } from "react-i18next";

type Props = {
    img?: string;
    text: string;
};

export function CardTypeOrCity({ img, text }: Props) {
    const { t } = useTranslation();
    return (
        <div className={clsx("flex_column_center", styles.container)}>
            {!!img && <img src={img} alt={text} className={styles.img} />}
            <div className={clsx(styles.contentText, "flex_row_center justifyContent_spaceBetween p_15")}>
                <div>{text}</div>
                <div className={clsx(styles.cta, "flex items-center gap-3")}>
                    {t("homePage.viewAll")} <img src={arrow} alt={"arrow"} />{" "}
                </div>
            </div>
        </div>
    );
}
