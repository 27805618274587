import React, { useEffect } from "react";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { useCommunityStore } from "communities/CommunityContext";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { CommunitiesCardSlider } from "communities/components/CommunitiesCardSlider";

export function CommunitiesCardSliderContainer() {
    const communityStore = useCommunityStore();
    const listStore = propertiesStore.getListStore("community-properties", undefined, [
        {
            id: "_id",
            value: communityStore.community.propertyIds,
            type: TFilterType.IN,
        },
    ]);

    useEffect(() => {
        listStore.load();
    }, []);

    return (
        <LoadableFromLoading
            loading={listStore.currentLoadingState}
            renderer={(_status, error) => {
                if (error) return null;
                return <CommunitiesCardSlider listStore={listStore} />;
            }}
        />
    );
}
