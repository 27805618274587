import React from "react";
import { Select } from "@material-ui/core";
import styles from "./_css/uiSelect.module.css";

export const UiSelect = React.forwardRef(({ ...props }: React.ComponentProps<typeof Select>, ref) => {
    return (
        <Select
            onClick={(e) => e.stopPropagation()}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
                classes: { list: styles.list, paper: styles.listContaine },
                getContentAnchorEl: null,
                variant: "menu",
            }}
            disableUnderline
            ref={ref}
            {...props}
            className={props.className}
            classes={{
                root: styles.root,
                select: styles.select,
                outlined: styles.outlined,
                icon: styles.icon,
                disabled: styles.disabled,
            }}
        />
    );
});
