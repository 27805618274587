import React from "react";
import { IconBed } from "properties/propertyPage/icons/IconBed";
import { IconBath } from "properties/propertyPage/icons/IconBath";
import { IconType } from "properties/propertyPage/icons/IconType";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "../properties/propertyPage/_css/propertyIconsInfos.module.css";
import { useUnitStore } from "units/UnitContext";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { UNIT_TYPE } from "units/_models/UnitTypeMdl";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { IconLandSquare } from "units/Icons/IconLandSquare";
import { convert, UNIT_CONVERSION } from "_common/_utils/unityUtils";

export function UnitIconsInfos() {
    const propertyStore = usePropertyStore();
    const unitStore = useUnitStore();
    const { t } = useTranslation();
    return (
        <div className={clsx(styles.container, "flex_row mv_40")}>
            {unitStore.unitType.type === UNIT_TYPE.commercialLand ||
            propertyStore.property.type === PROPERTY_TYPE.land ? (
                <>
                    <div className={clsx("flex_row", styles.iconContainer, styles.iconLargeContainer)}>
                        <div className={clsx(styles.icon, "mt_10 mr_20")}>
                            <IconLandSquare />
                        </div>
                        <div className={clsx("flex_column")}>
                            <h3> {t("unitPage.landArea")}</h3>
                            <p className={styles.textContainer}>
                                {unitStore.unitType.landSurface?.max !== unitStore.unitType.landSurface?.min
                                    ? "Min : "
                                    : ""}{" "}
                                {convert(unitStore.unitType.landSurface.min, UNIT_CONVERSION.M2_TO_FT2)}{" "}
                                {t("words.ft2")} ({unitStore.unitType.landSurface?.min} {t("words.m2")})
                            </p>
                            {!!unitStore.unitType.landSurface?.max &&
                                unitStore.unitType.landSurface?.max !== unitStore.unitType.landSurface?.min && (
                                    <p className={styles.textContainer}>
                                        Max: {convert(unitStore.unitType.landSurface.max, UNIT_CONVERSION.M2_TO_FT2)}{" "}
                                        {t("words.ft2")} ({unitStore.unitType.landSurface.max} {t("words.m2")})
                                    </p>
                                )}
                        </div>
                    </div>
                    <div className={clsx(styles.iconContainer, "flex_column_center")}>
                        <IconType size={"60"} />
                        <div className={"mt_10 textAlign_center"}>{t("propertyPage.propertyType")}</div>
                        <div className={"mt_5"}>{t("property.types." + propertyStore.property.type)}</div>
                    </div>
                </>
            ) : (
                <>
                    <div className={clsx(styles.iconContainer, "flex_column_center ")}>
                        <IconBed size={60} />
                        <div className={clsx("mt_10", styles.propertyTypeText)}>
                            {t("unit.infos.bedrooms", { count: unitStore.unitType.bedrooms })}
                        </div>
                    </div>
                    <div className={clsx(styles.iconContainer, "flex_column_center")}>
                        <IconBath size={60} />
                        <div className={clsx("mt_10", styles.propertyTypeText)}>
                            {t("unit.infos.bathrooms", { count: unitStore.unitType.bathrooms })}
                        </div>
                    </div>
                    <div className={clsx(styles.iconContainer, "flex_column_center")}>
                        <IconType size={"60"} />
                        <div className={clsx("mt_10", styles.propertyTypeText)}>{t("propertyPage.propertyType")}</div>
                        <div>{t("property.types." + propertyStore.property.type)}</div>
                    </div>
                </>
            )}
        </div>
    );
}
