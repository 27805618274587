import React, { lazy, useState } from "react";
import { observer } from "mobx-react";
import { Meta } from "_common/_utils/Meta";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { PropertiesSort } from "properties/PropertiesSort";
import clsx from "clsx";
import { usePropertiesPageMeta } from "properties/usePropertiesPageMeta";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { ListStore } from "_common/list/ListStore";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { URLS } from "_configs/URLS";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { PropertiesListLoader } from "properties/PropertiesListLoader";
import { PropertiesListBreadcrumb } from "properties/PropertiesListBreadcrumb";
import { GEO_ZONE_TYPE } from "geoZones/_models/GeoZoneMdl";
import { removeInlineCssForMceText } from "_common/_utils/alphaNumUtils";
import { getUrlWithPagination } from "_common/_utils/pageUtils";
import { useHistory } from "react-router-dom";
import Suspenser from "_common/loaders/Suspenser";
import { usePropertyPageContext } from "properties/PropertyPageContext";
import { MAP_OR_LIST_SELECTED } from "properties/PropertiesView";
import { PropertiesCount } from "properties/PropertiesCount";
import { PropertyCardListing } from "properties/propertyCard/PropertyCardListing";
import { PropertiesViewPagination } from "properties/PropertiesViewPagination";
import { PropertiesTagsSection } from "properties/PropertiesTagsSection";
import { PropertiesTypesSection } from "properties/PropertiesTypesSection";
import { PropertiesFaq } from "properties/PropertiesFaq";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};

const LazyPropertiesChildGeoZones = lazy(() => import("properties/components/PropertiesChildGeoZones"));

export const PropertiesViewList = observer(({ listStore }: Props) => {
    const propertiesStore = usePropertiesStore();
    const history = useHistory();
    const [hidden, setHidden] = useState(true);
    const { mapOrListSelected } = usePropertyPageContext();
    const { isGeoZonePage, isCityFromCitiesList, propertyType, isLuxury } = useSearchPageParams();
    const { title, metaTitle, seoText, metaDescription } = usePropertiesPageMeta(propertiesStore, listStore);

    if (!listStore.isCurrentPageExisting) {
        listStore.setSelectedPage(1);
        history.push(getUrlWithPagination(history.location.pathname, 1) + history.location.search);
    }
    const noIndex = isGeoZonePage && !isCityFromCitiesList && !geoZonesStore.geoZone;
    const propertyTypeFilter = listStore.filters.find((filter) => filter.id === "type")?.value[0];

    const geoZone = geoZonesStore.geoZone;
    const purpose = propertiesStore.purpose;

    return (
        <>
            <Meta title={metaTitle} description={metaDescription} url={noIndex ? URLS.home() : undefined} />
            <div className={"md:hidden pv_10"}>
                <PropertiesTagsSection listStore={listStore} />
            </div>
            {!noIndex && (
                <h1
                    className={"text-xl"}
                    onClick={() => {
                        if (isGeoZonePage || propertyType) {
                            setHidden(!hidden);
                        }
                    }}
                >
                    {title}
                </h1>
            )}
            <div
                className="mv_20"
                hidden={hidden}
                dangerouslySetInnerHTML={{ __html: removeInlineCssForMceText(seoText) }}
            />
            <div className="flex items-center justify-between my-5">
                <PropertiesSort listStore={listStore} />
                <PropertiesCount listStore={listStore} />
            </div>
            <div
                className={clsx("grid grid-cols-1 gap-4", {
                    ["md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"]:
                        mapOrListSelected === MAP_OR_LIST_SELECTED.LIST && !isLuxury,
                    ["xl:grid-cols-2"]: mapOrListSelected === MAP_OR_LIST_SELECTED.MAP,
                    ["md:grid-cols-2 xl:grid-cols-3"]: isLuxury,
                })}
            >
                {listStore.currentLoadingState ? (
                    <LoadableFromLoading
                        loading={listStore.currentLoadingState}
                        contentRender={<PropertiesListLoader />}
                        renderer={() => {
                            return (
                                <>
                                    {listStore.paginatedItems.map((property) => {
                                        if (!property) return null;
                                        return (
                                            <PropertyCardListing
                                                key={property._id}
                                                property={property}
                                                onMouseEnter={() => {
                                                    propertiesStore.setMapPropertiesSelected(property);
                                                }}
                                                onMouseLeave={() => {
                                                    propertiesStore.setMapPropertiesSelected.cancel();
                                                    propertiesStore.setMapPropertiesSelected(undefined);
                                                }}
                                                isLuxury={isLuxury}
                                            />
                                        );
                                    })}
                                </>
                            );
                        }}
                    />
                ) : (
                    <>
                        {listStore.paginatedItems.map((property) => {
                            if (!property) return null;
                            return (
                                <PropertyCardListing
                                    key={property._id}
                                    property={property}
                                    onMouseEnter={() => {
                                        propertiesStore.setMapPropertiesSelected(property);
                                    }}
                                    onMouseLeave={() => {
                                        propertiesStore.setMapPropertiesSelected.cancel();
                                        propertiesStore.setMapPropertiesSelected(undefined);
                                    }}
                                    isLuxury={isLuxury}
                                />
                            );
                        })}
                    </>
                )}
            </div>
            <div id={"PropertiesViewListPaginator"} className={"flex justify-center rela mt-10"}>
                <PropertiesViewPagination listStore={listStore} />
            </div>
            <div className={"flex_center_center pv_10"}>
                <PropertiesListBreadcrumb />
            </div>
            <div className={"hidden md:block pv_10"}>
                <PropertiesTagsSection listStore={listStore} />
            </div>
            <div className={"pv_10"}>
                <PropertiesTypesSection listStore={listStore} />
            </div>
            <div className={"pv_10"}>
                <PropertiesFaq geoZone={geoZone} purpose={purpose} />
            </div>
            {geoZonesStore.geoZone && geoZonesStore.geoZone.type !== GEO_ZONE_TYPE.NEIGHBORHOOD && (
                <Suspenser noText noSpinner>
                    <LazyPropertiesChildGeoZones type={propertyTypeFilter} />
                </Suspenser>
            )}
        </>
    );
});
