import React, { useContext } from "react";
import { UnitTypeStore } from "units/_stores/UnitTypeStore";

const UnitCtxt = React.createContext<UnitTypeStore>({} as UnitTypeStore);

type Props = {
    unitStore: UnitTypeStore;
};

export function useUnitStore() {
    return useContext(UnitCtxt);
}

export function UnitContext(props: React.PropsWithChildren<Props>) {
    return <UnitCtxt.Provider value={props.unitStore}>{props.children}</UnitCtxt.Provider>;
}
