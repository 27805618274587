import React from "react";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import i from "../../../assets/images/icons/i.svg";
import { PropertyCardPriceValue } from "./PropertyCardPriceValue";

type Props = {
    property: TPropertyListingMdl;
};

export function PropertyCardPrice({ property }: Props) {
    const { t } = useTranslation();

    return (
        <div className={"flex_row_center gap-1"}>
            <div className={"flex_center_center"}>
                <img src={i} alt={"informations"} />
            </div>
            {property.priceIsNotDisplayed ? (
                <div>{t("propertiesPage.card.priceOnRequest")}</div>
            ) : (
                <>
                    {t("words.from")}
                    <PropertyCardPriceValue property={property} />
                </>
            )}
        </div>
    );
}
