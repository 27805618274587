import { RefObject, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { isIOS } from "react-device-detect";

export const useClickOutside = (
    containerRef: RefObject<HTMLDivElement> | null,
    callback: () => void,
    expectClasses: string[] = [],
) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            let isExpectElement = false;
            for (const expectClass of expectClasses) {
                if ((event.target as HTMLElement).classList.contains(expectClass)) {
                    isExpectElement = true;
                    break;
                }
            }
            if (
                containerRef &&
                containerRef.current &&
                !containerRef.current.contains(event.target as Node) &&
                !isExpectElement
            ) {
                callback();
            }
        };

        window.addEventListener("mousedown", handleClickOutside);
        return () => window.removeEventListener("mousedown", handleClickOutside);
    }, [containerRef, callback]);
};

const DEFAULT_EVENTS = ["mousedown", "touchstart"];

// From https://github.com/mantinedev/mantine/blob/master/packages/%40mantine/hooks/src/use-click-outside/use-click-outside.ts?fbclid=IwAR2poapUaeiPkQFgiHpU1j13y96rbzyB8BluZKVLHM_ISxAJCIT_1hflEPY
export function useClickOutsideAlt<T extends HTMLElement = any>(
    handler: () => void,
    events?: string[] | null,
    nodes?: (HTMLElement | null)[],
) {
    const ref = useRef<T>();

    useEffect(() => {
        const listener = (event: any) => {
            const { target } = event ?? {};
            if (Array.isArray(nodes)) {
                const shouldIgnore =
                    target?.hasAttribute("data-ignore-outside-clicks") ||
                    (!document.body.contains(target) && target.tagName !== "HTML");
                const shouldTrigger = nodes.every((node) => !!node && !event.composedPath().includes(node));
                shouldTrigger && !shouldIgnore && handler();
            } else if (ref.current && !ref.current.contains(target)) {
                handler();
            }
        };

        (events || DEFAULT_EVENTS).forEach((fn) => document.addEventListener(fn, listener));

        return () => {
            (events || DEFAULT_EVENTS).forEach((fn) => document.removeEventListener(fn, listener));
        };
    }, [ref, handler, nodes]);

    return ref;
}

export function useSSR() {
    const [isFirstRender, setIsFirstRender] = useState(true);

    useEffect(() => {
        setIsFirstRender(false);
    }, []);

    return isFirstRender;
}

export function useScrollPosition(pageRef: { current: any }) {
    const [scrollPosition, setScrollPosition] = useState<number>(pageRef.current?.scrollTop);
    const handleScroll = () => {
        if (__BROWSER__) {
            const position = pageRef.current.scrollTop;
            setScrollPosition(position);
        }
    };

    useEffect(() => {
        if (__BROWSER__) {
            pageRef.current.addEventListener("scroll", handleScroll, { passive: false });
            return () => {
                pageRef.current ? pageRef.current.removeEventListener("scroll", handleScroll) : undefined;
            };
        }
    }, []);

    return scrollPosition;
}

export function useWindowSize(throttle?: number) {
    const [windowSize, setWindowSize] = useState({ width: -1, height: -1 });
    useEffect(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
        const listener = _.throttle(
            () =>
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                }),
            throttle ?? 100,
        );
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener);
    }, []);
    return windowSize;
}

export function useMousePosition() {
    const [mousePosition, setMousePosition] = useState({ x: null, y: null });

    const updateMousePosition = _.debounce((ev) => setMousePosition({ x: ev.clientX, y: ev.clientY }), 200);

    useEffect(() => {
        !isIOS ? window.addEventListener("mousemove", updateMousePosition) : undefined;

        return () => (!isIOS ? window.removeEventListener("mousemove", updateMousePosition) : undefined);
    }, []);

    return mousePosition;
}
