export enum PRICE_CONVERSION {
    PESOS_TO_USD = 0.0486827,
    USD_TO_PESOS = 1 / 0.0486827,

    PESOS_TO_EUR = 0.053,
    EUR_TO_PESOS = 1 / 0.053,

    PESOS_TO_CAD = 0.078,
    CAD_TO_PESOS = 1 / 0.078,

    USD_TO_EUR = 0.93,
    EUR_TO_USD = 1 / 0.93,

    USD_TO_CAD = 1.36,
    CAD_TO_USD = 1 / 1.36,

    EUR_TO_CAD = 1.48,
    CAD_TO_EUR = 1 / 1.48,
}

export enum CURRENCY_OPTIONS {
    USD = "USD",
    MXN = "MXN",
    CAD = "CAD",
    EUR = "EUR",
}
