import React, { lazy, useEffect } from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { useTranslation } from "react-i18next";
import styles from "./_css/propertyOrUnitSections.module.css";
import clsx from "clsx";
import { PropertyOptions } from "properties/propertyPage/PropertyOptions";
import { unitsStore } from "units/_stores/unitTypesStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { PropertyOrUnitHeaderMobile } from "properties/propertyPage/PropertyOrUnitHeaderMobile";
import { PropertyOrUnitCta } from "properties/propertyPage/PropertyOrUnitCta";
import { DEFAULT_PAGE_SIZE } from "_common/resources/BaseResourceStore";
import { PropertyPrice } from "properties/propertyPage/PropertyPrice";
import { PropertyAddress } from "properties/propertyPage/PropertyAddress";
import { NavHashLink } from "react-router-hash-link";
import { PropertyOrUnitInfosForm } from "properties/propertyPage/_forms/PropertyOrUnitInfosForm";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import { PAGE_REF } from "pages/PageRegular";
import { PropertyIconsInfos } from "properties/propertyPage/PropertyIconsInfos";
import { PropertyOrUnitDescription } from "properties/propertyPage/PropertyOrUnitDescription";
import { PROPERTY_SUBTYPE, PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { PropertyLandIconsInfos } from "properties/propertyPage/PropertyLandIconsInfos";
import { useLocation } from "react-router-dom";
import { PropertyMapRender } from "properties/propertyPage/PropertyMapRender";
import Suspenser from "_common/loaders/Suspenser";
import { HorizontalUnitCardLoader } from "units/HorizontalUnitCardLoader";

export enum PROPERTY_SECTION_TABS {
    INFO = "INFO",
    MAP = "MAP",
    FEATURES = "FEATURES",
    AVAILABLE_UNIT_TYPE = "AVAILABLE_UNIT_TYPE",
    INVENTORY = "INVENTORY",
    LEVEL_PLAN = "LEVEL_PLAN",
}

const REFS: { name: string; ref: { current: HTMLDivElement | null } }[] = Object.keys(PROPERTY_SECTION_TABS).map(
    (refName) => {
        return {
            name: refName,
            ref: { current: null },
        };
    },
);

const getRef = (refName: string) => {
    return REFS.find((ref) => ref.name === refName);
};

const LazyPropertySimilarProperties = lazy(() => import("properties/propertyPage/PropertySimilarProperties"));
const LazyPropertySimilarPropertiesPremium = lazy(() => import("properties/propertyPage/PropertySimilarProperties"));
const LazyPropertyHistory = lazy(() => import("properties/propertyPage/PropertyHistory"));
const LazyPropertyOrUnitFinancing = lazy(() => import("properties/propertyPage/PropertyOrUnitFinancing"));
const LazyPropertyOrUnitFloorPlans = lazy(() => import("properties/propertyPage/PropertyOrUnitFloorPlans"));
const LazyPropertyInventory = lazy(() => import("properties/propertyPage/inventory/PropertyInventory"));
const LazyPropertyUnits = lazy(() => import("properties/propertyPage/PropertyUnits"));

export const PropertySections = () => {
    const propertyStore = usePropertyStore();
    const { t } = useTranslation();
    const location = useLocation();
    const unitList = unitsStore.getListStore(propertyStore.property._id).items;

    const displayUnits = unitList.length > 0;
    const displayInventory =
        propertyStore.property?.inventory &&
        propertyStore.property?.inventory?.length > 0 &&
        !!propertyStore.property.inventory.find((inventory) => inventory?.buildings?.length > 0) &&
        !propertyStore.property?.hideInventory;
    const displayFinancing = propertyStore.property?.financing?.length > 0;
    const displayPlan = propertyStore.property?.floorPlans?.length > 0 && !propertyStore.property?.hideInventory;

    const isBuildingConstruct = propertyStore.property.subType === PROPERTY_SUBTYPE.buildableHouse;
    useEffect(() => {
        if (location.hash) {
            const refToScroll = getRef(location.hash.slice(1));
            if (refToScroll) {
                refToScroll.ref.current?.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, []);
    const priceCustom = t("propertyPage.priceDependOfUnit");
    return (
        <div className={"position_relative"}>
            <h1 className={clsx(styles.title, "mb_0")}>{propertyStore.property.localized.title}</h1>
            <PropertyOrUnitHeaderMobile resource={RESOURCE.PROPERTIES} units={unitList} />
            <div className={clsx(styles.sectionNavLinks, "flex_row flex-1 textAlign_center")}>
                {Object.values(PROPERTY_SECTION_TABS).map((section) => {
                    if (section === PROPERTY_SECTION_TABS.INVENTORY && !displayInventory) return null;
                    if (section === PROPERTY_SECTION_TABS.LEVEL_PLAN && !displayPlan) return null;
                    if (section === PROPERTY_SECTION_TABS.INVENTORY && !displayUnits) return null;
                    if (section === PROPERTY_SECTION_TABS.AVAILABLE_UNIT_TYPE && !displayInventory) return null;
                    return (
                        <NavHashLink
                            smooth
                            to={`#${section}`}
                            key={section}
                            scroll={(el) => {
                                if (PAGE_REF.current && el?.offsetTop) {
                                    PAGE_REF.current?.scrollTo({ top: el.offsetTop, behavior: "smooth" });
                                }
                            }}
                            onClick={() => {
                                propertyStore.setSectionTab(section);
                            }}
                            className={clsx(styles.linkSections, "ph_5 pb_5 flex_center_center", {
                                [styles.selectedSection]: propertyStore.selectedSection === section,
                            })}
                        >
                            {t(`propertyPage.section.${section}`)}
                        </NavHashLink>
                    );
                })}
            </div>
            <div className={"flex_column mt_10"}>
                {propertyStore.property.type !== PROPERTY_TYPE.land && <PropertyIconsInfos />}
                <div ref={getRef("INFO")?.ref} id={"INFO"} className={clsx(styles.infos, "mb_40")}>
                    <div className={clsx(styles.priceAddressContainer, "flex_row alignItems_center")}>
                        <div className={clsx(styles.priceDesktop, "flex-1 p_20 flex_row")}>
                            <PropertyPrice units={unitList} />
                        </div>
                        <div className={"flex-1 flex_row"}>
                            <PropertyAddress />
                        </div>
                    </div>
                    {propertyStore.property.type === PROPERTY_TYPE.land && <PropertyLandIconsInfos />}
                    <PropertyOrUnitCta store={propertyStore} />
                </div>

                {!propertyStore.property?.hideInventory && (
                    <div
                        ref={getRef("AVAILABLE_UNIT_TYPE")?.ref}
                        id={"AVAILABLE_UNIT_TYPE"}
                        className={clsx(styles.availableUnits, "mb_10")}
                    >
                        <h2 className={clsx("textAlign_center mt_20", styles.sectionTitle)}>
                            {t("propertyPage.availableUnitType")}
                        </h2>
                        <Suspenser fallback={<HorizontalUnitCardLoader />}>
                            <LoadableFromLoading
                                className={"flex_center_center"}
                                contentRender={<HorizontalUnitCardLoader />}
                                loading={
                                    unitsStore.getListStore(
                                        propertyStore.property._id,
                                        DEFAULT_PAGE_SIZE,
                                        undefined,
                                        undefined,
                                        false,
                                        {
                                            sold: 1,
                                            bedrooms: 1,
                                            bathrooms: -1,
                                        },
                                    ).currentLoadingState
                                }
                                renderer={() => {
                                    return <LazyPropertyUnits />;
                                }}
                            />
                        </Suspenser>
                    </div>
                )}
                <div ref={getRef("MAP")?.ref} id={"MAP"}>
                    <PropertyMapRender />
                </div>
                <div ref={getRef("FEATURES")?.ref} id={"FEATURES"} className={"mb_40"}>
                    <PropertyOptions />
                </div>
                {displayPlan && (
                    <div ref={getRef("LEVEL_PLAN")?.ref} id={"LEVEL_PLAN"} className={"mb_40"}>
                        <Suspenser noSpinner noText>
                            <LazyPropertyOrUnitFloorPlans
                                propertyOrUnit={propertyStore.property}
                                resource={RESOURCE.PROPERTIES}
                                store={propertyStore}
                            />
                        </Suspenser>
                    </div>
                )}
                {displayFinancing && (
                    <Suspenser noSpinner noText>
                        <div className={"mb_40"}>
                            <LazyPropertyOrUnitFinancing propertyOrUnit={propertyStore.property} />
                        </div>
                    </Suspenser>
                )}
                <div className={"mb_40"}>
                    <PropertyOrUnitDescription
                        description={propertyStore.property.localized?.description}
                        resource={RESOURCE.PROPERTIES}
                    />
                </div>
                {displayInventory && (
                    <div ref={getRef("INVENTORY")?.ref} id={"INVENTORY"} className={clsx("mb_40", styles.inventory)}>
                        <Suspenser noSpinner noText>
                            <LazyPropertyInventory
                                customData={
                                    isBuildingConstruct
                                        ? {
                                              bedrooms: `${propertyStore.property.bedrooms.min} ${
                                                  propertyStore.property.bedrooms?.max
                                                      ? "- " + propertyStore.property.bedrooms?.max
                                                      : ""
                                              }`,
                                              bathrooms: `${propertyStore.property.bathrooms.min} ${
                                                  propertyStore.property.bathrooms?.max
                                                      ? "- " + propertyStore.property.bathrooms?.max
                                                      : ""
                                              }`,
                                              price: priceCustom,
                                          }
                                        : undefined
                                }
                            />
                        </Suspenser>
                        <Suspenser noSpinner noText>
                            <LazyPropertyHistory />
                        </Suspenser>
                    </div>
                )}
                <PropertyOrUnitInfosForm resource={RESOURCE.PROPERTIES} />
                <Suspenser noSpinner noText>
                    <LazyPropertySimilarPropertiesPremium premium />
                </Suspenser>
                <Suspenser noSpinner noText>
                    <LazyPropertySimilarProperties />
                </Suspenser>
            </div>
        </div>
    );
};
