import React from "react";
import { IconFavorite } from "properties/propertyPage/icons/IconFavorite";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { ToggleToFavoriteBtn } from "components/propertyOrUnit/ToogleToFavoriteContainer";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import styles from "../_css/propertyCardFavoriteBtn.module.css";
import clsx from "clsx";

type Props = {
    property: TPropertyListingMdl;
};

export function PropertyCardFavoriteBtn({ property }: Props) {
    return (
        <ToggleToFavoriteBtn
            item={property}
            type={RESOURCE.PROPERTIES}
            className={clsx(styles.container, "position_absolute flex_center_center")}
        >
            {({ isFavorite }) => {
                return <IconFavorite color={"black"} size={"15"} isFavorite={isFavorite} />;
            }}
        </ToggleToFavoriteBtn>
    );
}
