import React from "react";
import styles from "./_css/testimonialHomeCard.module.css";
import stars from "../assets/images/icons/stars.svg";
import clsx from "clsx";
import { TReviewLocalizedMdl } from "admin/reviews/_models/ReviewsMdl";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {
    review: TReviewLocalizedMdl;
};

export function TestimonialHomeCard({ review }: Props) {
    return (
        <>
            <img className={"mb_10"} src={stars} alt={"stars"}>
                {review.rate}
            </img>
            {review?.localized.title && (
                <div className={clsx(styles.reviewTitle, "mb_20")}>{review?.localized.title}</div>
            )}
            <div className={clsx(styles.text, "mb_30")}>{review?.localized?.description}</div>
            <div className={"flex_row_center gap-3"}>
                <LazyLoadImage className={styles.img} src={review.image} alt={"image review"} />
                <div className={"flex_column"}>
                    <div className={styles.location}>{review.location}</div>
                    <div className={styles.name}>{review.name}</div>
                </div>
            </div>
        </>
    );
}
