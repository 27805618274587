import React from "react";
import styles from "./_css/propertiesListLoader.module.css";

export function PropertiesListLoader() {
    return (
        <>
            <div className={styles.card}>
                <div className={styles.img} />
                <div className={styles.content}>
                    <h2 />
                    <p />
                </div>
            </div>
            <div className={styles.card}>
                <div className={styles.img} />
                <div className={styles.content}>
                    <h2 />
                    <p />
                </div>
            </div>
            <div className={styles.card}>
                <div className={styles.img} />
                <div className={styles.content}>
                    <h2 />
                    <p />
                </div>
            </div>
            <div className={styles.card}>
                <div className={styles.img} />
                <div className={styles.content}>
                    <h2 />
                    <p />
                </div>
            </div>
            <div className={styles.card}>
                <div className={styles.img} />
                <div className={styles.content}>
                    <h2 />
                    <p />
                </div>
            </div>
            <div className={styles.card}>
                <div className={styles.img} />
                <div className={styles.content}>
                    <h2 />
                    <p />
                </div>
            </div>
        </>
    );
}
