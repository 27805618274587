import React, { useState } from "react";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { MenuItem } from "@material-ui/core";
import { ListStore } from "_common/list/ListStore";
import { useTranslation } from "react-i18next";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import sortIcon from "../assets/images/icons/sort.svg";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};

export function PropertiesSort(props: Props) {
    const [keySort, setKeySort] = useState({
        key: props.listStore.sort ? Object.keys(props.listStore.sort)[0] : "position",
        sort: props.listStore.sort ? Object.values(props.listStore.sort)[0] : 1,
    });
    const { t } = useTranslation();

    function sort(updatedSort: { key: string; sort: number }) {
        setKeySort(updatedSort);
        props.listStore.setSort({ [updatedSort.key]: updatedSort.sort });
    }

    return (
        <div className="flex items-center gap-2">
            <img src={sortIcon} className={"h-4 w-4"} alt={"icon sort"} />
            <div>{t("propertiesPage.sortBy")}</div>
            <UiSelect
                defaultValue={keySort.key}
                title={"Sort"}
                onChange={(e) => sort({ ...keySort, key: e.target.value as string })}
            >
                <MenuItem value={"position"}>{t("propertiesPage.sort.popular")}</MenuItem>
                <MenuItem value={"deliveryDates"}>{t("propertiesPage.sort.date")}</MenuItem>
                <MenuItem value={"price.min"}>{t("propertiesPage.sort.price")}</MenuItem>
            </UiSelect>
        </div>
    );
}
