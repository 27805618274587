import React from "react";
import { CommunitiesTitle } from "communities/components/CommunitiesTitle";
import { useCommunityStore } from "communities/CommunityContext";
import { Swiper, SwiperSlide } from "swiper/react";

const NAME = "communitiesUsefulFacts";

export function CommunitiesUsefulFacts() {
    const communityStore = useCommunityStore();

    return (
        <div className={"my-10"}>
            <CommunitiesTitle title={"Useful Facts"} delimiter={1} />
            <div id={NAME} className={"relative"}>
                <Swiper
                    loop
                    navigation={{
                        prevEl: `.swiper-button-prev.${NAME}`,
                        nextEl: `.swiper-button-next.${NAME}`,
                    }}
                    slidesPerView={3.1}
                    spaceBetween={20}
                    breakpoints={{
                        300: {
                            slidesPerView: 1,
                        },
                        690: {
                            slidesPerView: 1,
                        },
                        1180: {
                            slidesPerView: 2.5,
                        },
                        1400: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    {communityStore.community.facts.map((fact, index) => (
                        <SwiperSlide key={index}>
                            <div className={"flex flex-col p-5 gap-5 border rounded "}>
                                <p className={"font-bold"}>{fact.title}</p>
                                <p>{fact.description}</p>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}
