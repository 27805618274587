import { IPageMdl, PAGE_TYPE } from "pages/_models/PageMdl";
import { TBreadcrumbItem } from "breadcrumb/Breadcrumb";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { useLocation } from "react-router";
import frImage from "../../../shared/assets/images/fr.png";
import enImage from "../../../shared/assets/images/ca.png";
import esImage from "../../../shared/assets/images/es.svg";
import zhImage from "../../../shared/assets/images/china.svg";
import _ from "lodash";
import sharedConfig from "_configs/sharedConfig";
import { URLS } from "_configs/URLS";
import { PARAMS_PAGE_PREFIX } from "_common/_utils/searchUtils";

export function getTabFromParams(search: string) {
    const searchParams = new URLSearchParams(search);
    return searchParams.get("tab");
}

export function useGetItemsForBreadCrumb(page: IPageMdl) {
    let item: TBreadcrumbItem[] = [];
    const url = useLocation().pathname;
    const urlItems = url.split("/").slice(2);
    urlItems.pop();
    urlItems.forEach(() =>
        item.push({
            itemLabelKey: "breadcrumb.ourServices",
            url: "/" + i18nextInstance.language + "/" + urlItems,
        }),
    );
    if (page.type === PAGE_TYPE.ARTICLE) {
        item = [
            {
                itemLabelKey: "breadcrumb.blog",
                url: "/" + i18nextInstance.language + "/blog",
            },
        ];
    }
    return item;
}

export function getFlagImg(lang: string) {
    let flag;
    switch (lang) {
        case "fr":
            flag = frImage;
            break;
        case "en":
            flag = enImage;
            break;
        case "es":
            flag = esImage;
            break;
        case "zh":
            flag = zhImage;
            break;
        default:
    }
    return flag;
}

export function getI18nExpByLang(lang: string, key: string, args?: { key: string; value?: string }[]) {
    let exp = _.get(i18nextInstance.getDataByLanguage(lang)?.translation, key);
    if (!exp) _.get(i18nextInstance.getDataByLanguage(sharedConfig.defaultLang)?.translation, key);
    if (!exp) return key;
    if (exp && args) {
        for (const arg of args) {
            if (arg.value) exp = exp.replace("{{" + arg.key + "}}", arg.value);
        }
    }
    return exp;
}

export function isBuyOrRentPage(url: string) {
    return url.startsWith(URLS.buy()) || url.startsWith(URLS.map());
}

export function refreshPage(url: string) {
    setTimeout(() => {
        window.location.href = url;
    }, 50);
}

export function getUrlWithPagination(url: string, page: number) {
    let newUrl: string;
    if (/\/p_[0-9]+/.test(url)) {
        newUrl = url.replace(/p_[0-9]+/, PARAMS_PAGE_PREFIX + page);
    } else if (/\/z_[0-9]+/.test(url)) {
        const zoom = new RegExp(/z_[0-9]+/).exec(url)?.[0];
        newUrl = url.replace(/z_[0-9]+/, PARAMS_PAGE_PREFIX + page) + "/" + zoom;
    } else {
        newUrl = url + "/" + PARAMS_PAGE_PREFIX + page;
    }
    return newUrl;
}

export function getPageFromUrl(url: string): number | undefined {
    const pageIndicator = new RegExp(/p_[0-9]+/).exec(url);
    if (pageIndicator) {
        return parseInt(pageIndicator[0].replace("p_", ""));
    }
}
