import React from "react";
import { Pagination } from "@material-ui/lab";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getUrlWithPagination, isBuyOrRentPage } from "_common/_utils/pageUtils";
import styles from "./_css/paginator.module.css";
import clsx from "clsx";
import CustomPaginationItem from "_common/ui/mui/pagination/PaginationItem";

type Props = {
    count?: number;
    selectedPage: number;
    pageSize: number;
    onPageClick: (page: number) => void;
    variant?: "text" | "outlined";
    shape?: "round" | "rounded";
    color?: "primary" | "secondary" | "standard";
};
export const Paginator = (props: Props) => {
    const location = useLocation();
    const history = useHistory();
    const isBuyRentPage = isBuyOrRentPage(location.pathname);
    if (props.count === undefined) return null;
    const nbPages = Math.ceil(props.count / props.pageSize);
    return (
        <Pagination
            id={"pagination"}
            count={nbPages}
            page={props.selectedPage}
            onChange={(event, page) => {
                event.preventDefault();
                if (isBuyRentPage) history.push(getUrlWithPagination(location.pathname, page) + location.search);
                props.onPageClick(page);
            }}
            renderItem={(item) => {
                if (item?.page === 0 || item?.page > nbPages) return null;
                const link = item.page
                    ? getUrlWithPagination(location.pathname, item.page) + location.search
                    : undefined;
                return (
                    <>
                        {isBuyRentPage && link ? (
                            <Link to={link} onClick={() => props.onPageClick(item.page)} className={"bg-red"}>
                                <CustomPaginationItem
                                    {...item}
                                    classes={{
                                        root: styles.button,
                                        selected: styles.selected,
                                        page: styles.page,
                                    }}
                                    className={clsx({
                                        [styles.arrow]: item.type.startsWith("next") || item.type.startsWith("prev"),
                                    })}
                                    rel={item.type.substring(0, 4)}
                                />
                            </Link>
                        ) : (
                            <CustomPaginationItem
                                rel={item.type.substring(0, 4)}
                                {...item}
                                classes={{
                                    root: styles.button,
                                    selected: styles.selected,
                                    page: styles.page,
                                }}
                                className={clsx({
                                    [styles.arrow]: item.type.startsWith("next") || item.type.startsWith("prev"),
                                })}
                            />
                        )}
                    </>
                );
            }}
        />
    );
};
