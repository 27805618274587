import React from "react";
import styles from "./_css/header.module.css";
import { Logo } from "_common/icons/Logo";
import clsx from "clsx";
import { HeaderContent } from "main/header/HeaderContent";
import { HeaderContentMobile } from "main/header/HeaderContentMobile";
import { useHomePage } from "_common/_utils/hookUtils";
import { Separator } from "_common/ui/components/Separator";

export function Header() {
    const isHomePage = useHomePage();

    return (
        <header>
            <div className={clsx("flex_row_center justifyContent_spaceBetween px-4 md:px-7", styles.container)}>
                <div className={styles.contentDesktop}>
                    <Logo width={210} height={65} />
                </div>
                <div className={clsx(styles.contentMobile)}>
                    <Logo width={122} height={37} />
                </div>
                <div className={styles.contentDesktop}>
                    <HeaderContent />
                </div>
                <div className={clsx(styles.contentMobile)}>
                    <HeaderContentMobile />
                </div>
            </div>
            <div className={styles.placeholder} />
            {!isHomePage && <Separator className={"mx-7 hidden lg:flex"} />}
        </header>
    );
}
