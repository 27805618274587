import React from "react";
import { HeaderBlock } from "home/HeaderBlock";
import { HomeCardTypeSection } from "home/HomeCardTypeSection";
import { Meta } from "_common/_utils/Meta";
import i18next from "i18next";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { useHistory } from "react-router-dom";
import { Container, NoSsr } from "@material-ui/core";
import { HomeTestimonials } from "home/HomeTestimonials";
import { HomeServices } from "home/HomeServices";
import { HomeCardCitiesSection } from "home/HomeCardCitiesSection";
import { HomeValues } from "home/HomeValues";
import { HomePageConsultation } from "home/HomePageConsultation";
import { HomeFeaturesPropertiesSection } from "home/HomeFeaturesPropertiesSection";
import { HomeNewsSection } from "home/HomeNewsSection";

export function HomePage() {
    const history = useHistory();
    const meta = {
        title: i18next.t("homePage.meta.title"),
        description: i18next.t("homePage.meta.description"),
        url: sharedConfig.appUrl + history.location.pathname,
        alternateUrls: [] as { url: string; lang: TLang }[],
    };
    for (const lang of Object.keys(sharedConfig.languages)) {
        if (lang === "en") {
            meta.alternateUrls.push({ url: sharedConfig.appUrl + "/", lang: lang as TLang });
        } else {
            meta.alternateUrls.push({ url: sharedConfig.languages[lang as TLang].baseUrl, lang: lang as TLang });
        }
    }
    return (
        <>
            <Meta title={meta.title} url={meta.url} alternateUrls={meta.alternateUrls} description={meta.description} />
            <div className="mb-12 lg:mb-8">
                <HeaderBlock />
            </div>
            <Container className="!max-w-[1260px] mb-[175px] lg:mb-[76px]">
                <HomeFeaturesPropertiesSection />
            </Container>
            <Container className="!max-w-[1260px] mb-[175px] lg:mb-[76px]">
                <HomeServices />
            </Container>
            <Container className="!max-w-[1260px] mb-[143px] lg:mb-[50px]">
                <HomeCardCitiesSection />
            </Container>
            <Container className="!max-w-[1260px] mb-[145px] lg:mb-[67px]">
                <HomeTestimonials />
            </Container>
            <Container className="!max-w-[1260px] mb-[120px] lg:mb-[71px]">
                <HomeCardTypeSection />
            </Container>
            <Container className="!max-w-[1260px] mb-[147px] lg:mb-[67px]">
                <HomeValues />
            </Container>
            <Container className="!max-w-[1260px] mb-[147px] lg:mb-[67px]">
                <HomeNewsSection />
            </Container>
            <NoSsr defer>
                <HomePageConsultation />
            </NoSsr>
        </>
    );
}
