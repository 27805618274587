import React from "react";
import styles from "./_css/propertyOrUnitPlans.module.css";
import clsx from "clsx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PropertyStore } from "properties/_stores/PropertyStore";
import { UnitTypeStore } from "units/_stores/UnitTypeStore";
import { RESOURCE, RESOURCE_KEY } from "admin/_common/photos/AdminPhotosStore";
import { isImage } from "_common/_utils/fileUtils";

type Props = {
    store: PropertyStore | UnitTypeStore;
    resource: RESOURCE;
    resourceKey: RESOURCE_KEY;
};

export function PropertyOrUnitGalleryAndDocuments(props: Props) {
    const item =
        props.resource === RESOURCE.PROPERTIES
            ? (props.store as PropertyStore).property
            : (props.store as UnitTypeStore).unitType;
    if (!item || !(props.resourceKey in item)) return null;
    return (
        <div className={styles.container}>
            <div className={clsx(styles.gallery, "flex_row flexWrap_wrap justifyContent_center")}>
                {item[props.resourceKey].map((photo, index) => {
                    return (
                        <div key={index} className={styles.galleryImage}>
                            {isImage(photo.url) ? (
                                <LazyLoadImage
                                    onClick={() => props.store.setOpenedImageModal(props.resourceKey, index)}
                                    className={styles.image}
                                    src={photo.url}
                                    alt={`${item.localized.title}-${index}`}
                                    title={`${item.localized.title} photo ${index}`}
                                />
                            ) : (
                                <div className={styles.pdfContainer}>
                                    <object
                                        data={photo.url + "#toolbar=0&navpanes=0"}
                                        type="application/pdf"
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
