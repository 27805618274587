import { observable } from "mobx";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { TReviewLocalizedMdl } from "admin/reviews/_models/ReviewsMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";
import i18next from "i18next";
import { getInitialStateValue, putPromiseResultInInitialState } from "_common/_utils/initialStateUtils";

export class TestimonialsStore extends BaseResourceStore<TReviewLocalizedMdl> {
    @observable reviews: TReviewLocalizedMdl[] = [];
    @observable reviewsState: LoadingStateMdl<boolean> = new LoadingStateMdl<boolean>();

    constructor() {
        super("reviews");
        this.onInit();
    }

    fetchReviews(numberOfReviews = 3, sort = { position: 1 }) {
        if (!this.reviewsState.isLoading && !this.reviewsState.isSucceeded) {
            this.reviewsState.startLoading();
            const sortParam = sort ? `&sort=${JSON.stringify(sort)}` : "";
            const promise = fetchUtils.get<TReviewLocalizedMdl[]>(
                `${this.apiPath}/listing?lang=${i18next.language}&limit=${numberOfReviews}&sort=${sortParam}`,
            );
            promise
                .then(({ data }) => {
                    this.reviews = data;
                    this.reviewsState.setSuccess(true);
                })
                .catch((error) => console.error(error));
            putPromiseResultInInitialState("testimonials", promise);
        }
        return this.reviews;
    }
    onInit() {
        const initialState = getInitialStateValue<{ data: TReviewLocalizedMdl[] }>("testimonials");
        if (initialState) {
            const { data } = initialState;
            this.reviews = data;
            this.reviewsState.setSuccess(true);
        }
    }
}

export const testimonialsStore = new TestimonialsStore();
