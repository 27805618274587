import React, { useState } from "react";
import styles from "main/header/_css/langSelector.module.css";
import clsx from "clsx";
import { KeyboardArrowDown } from "@material-ui/icons";
import { CURRENCY_OPTIONS } from "_common/currency/CurrencyTypes";
import { useCurrency } from "_common/currency/CurrencyProvider";
import { useTranslation } from "react-i18next";

export function CurrencySelector() {
    const [opened, setOpened] = useState(false);
    const { t } = useTranslation();
    const { currentCurrency, setCurrency } = useCurrency();

    return (
        <div
            onClick={() => setOpened(!opened)}
            onMouseEnter={() => setOpened(true)}
            onMouseLeave={() => setOpened(false)}
            className={styles.container}
        >
            <div className={clsx("flex_row_center position_relative", styles.flag)}>
                {t("searchBar.currencies." + currentCurrency)}
                <KeyboardArrowDown fontSize={"small"} />
                <div
                    className={clsx(styles.subMenuContainer, {
                        ["hidden"]: !opened,
                    })}
                >
                    <ul className={styles.list}>
                        {Object.values(CURRENCY_OPTIONS).map((currency) => (
                            <li key={currency}>
                                <div
                                    className={styles.link}
                                    onClick={() => {
                                        setOpened(false);
                                        setCurrency(currency);
                                    }}
                                >
                                    {t("searchBar.currencies." + currency)}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}
