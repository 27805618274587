import React from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import shaheen from "../assets/images/founders/shaheen.jpg";

export function AboutUsFounders() {
    const { t } = useTranslation();

    return (
        <div className={"flex flex-col mt-10 xl:flex-row xl:items-center md:mt-20"}>
            <div className={"w-[250px] mr-5 xl:ml-4"}>
                <div
                    className="font-semibold mb-5 text-4xl lg:mr-10"
                    dangerouslySetInnerHTML={{ __html: t("aboutUsPage.founders.title", { color: "#5FB8DF" }) }}
                />
                <div className="text-2xl font-medium">{t("aboutUsPage.founders.shaheen.title")}</div>
                <div className="text-2xl font-medium">{t("aboutUsPage.founders.shaheen.subTitle")}</div>
            </div>

            <div className={"mt-4 md:w-[388px] xl:ml-5"}>
                <LazyLoadImage
                    src={shaheen}
                    className="rounded-lg w-full h-[336px] md:w-[388px] md:h-[388px] object-cover object-top"
                />
            </div>

            <div className={"flex-1 flex flex-col justify-center xl:ml-10"}>
                <div
                    className={"font-normal mt-4 text-base md:pr-0"}
                    dangerouslySetInnerHTML={{ __html: t("aboutUsPage.founders.shaheen.text") }}
                />
            </div>
        </div>
    );
}
