import { CommunitiesBreadcrumb } from "communities/CommunitiesBreadcrumb";
import { Container } from "@material-ui/core";
import { CommunitiesImageSlider } from "communities/CommunitiesImageSlider";
import { CommunitiesInformationSection } from "communities/CommunitiesInformationSection";
import { Button } from "_common/ui/mui/buttons/Button";
import React, { lazy, Suspense, useMemo } from "react";
import { TCommunitiesListingMdl } from "admin/communities/_models/CommunitiesMdl";
import { CommunityStore } from "communities/_stores/communityStore";
import { CommunityContext } from "communities/CommunityContext";
import { CommunitiesCardSliderContainer } from "communities/components/CommunitiesCardSliderContainer";
import { Loader } from "_common/loaders/Loader";
import { CommunitiesTitle } from "communities/components/CommunitiesTitle";
import { CommunitiesFeaturesAndAmenties } from "communities/components/CommunitiesFeaturesAndAmenties";
import { CommunitiesUsefulFacts } from "communities/components/CommunitiesUsefulFacts";

const CommunitiesActivitiesMap = lazy(() => import("./components/CommunitiesActivitiesMap"));

type Props = {
    community: TCommunitiesListingMdl;
};

export function CommunityView({ community }: Props) {
    const communityStore = useMemo(() => new CommunityStore(community), [community]);

    return (
        <CommunityContext communityStore={communityStore}>
            <div className={"m-10"}>
                <CommunitiesBreadcrumb />
            </div>

            <Container className="!max-w-[1260px] mb-[75px]">
                <CommunitiesImageSlider />
            </Container>

            <Container className="!max-w-[1260px] mb-[36px]">
                <CommunitiesInformationSection />
            </Container>

            <Container className="!max-w-[1260px] mb-[75px]">
                <Button variant={"contained"}>Request Info </Button>
            </Container>

            <Container className="!max-w-[1260px] mb-[75px]">
                <CommunitiesCardSliderContainer />
            </Container>

            <Container className="!max-w-[1260px] mb-[75px]">
                <Button variant={"outlined"}>View all properties</Button>
            </Container>

            <Container className="!max-w-[1260px] mb-[75px]">
                <CommunitiesTitle title={"Discover Costa Palmas in Los Cabos"} delimiter={3} />
                <div className={" h-[300px] bg-red-100"}></div>
            </Container>

            <Container className="!max-w-[1260px] mb-[75px]">
                <CommunitiesTitle title={"The Map"} delimiter={1} />
                <Suspense fallback={<Loader />}>
                    <CommunitiesActivitiesMap />
                </Suspense>
            </Container>

            <Container>
                <CommunitiesFeaturesAndAmenties />
            </Container>

            <Container>
                <CommunitiesUsefulFacts />
            </Container>
        </CommunityContext>
    );
}
