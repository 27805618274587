import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import phone from "../assets/images/aboutUs/phone.svg";
import email from "../assets/images/aboutUs/email.svg";
import whatsApp from "../assets/images/aboutUs/whatsApp.svg";
import { PHONE, WHATSAPP_PHONE } from "contact/ContactUsForm";
import { settingsStore } from "settings/_stores/settingsStore";
import { ISettingsMdl } from "settings/_models/SettingsMdl";
import { useTranslation } from "react-i18next";
import sharedConfig from "_configs/sharedConfig";

export function ContactUsInfo() {
    const settings = settingsStore.getOne() as ISettingsMdl;
    const { t } = useTranslation();
    if (!settings?.contacts) return null;
    return (
        <div className={"flex-1 mt-5"}>
            <div className={"text-base font-bold mt-8"}>{t("aboutUsPage.consultation.orContactUs")}</div>
            <div className={"flex flex-col md:flex-row"}>
                <div className={"flex flex-row items-center mt-3.5 md:mr-[70px]"}>
                    <LazyLoadImage src={whatsApp} alt={"WhatsApp"} title={"WhatsApp"} />
                    <div className={"ml-4"}>
                        <div>{t("aboutUsPage.consultation.whatsApp")}</div>
                        <a href={"https://wa.me/5149325688"} target="_blank" rel="noreferrer">
                            {WHATSAPP_PHONE}
                        </a>
                    </div>
                </div>
                <div className={"flex flex-row items-center mt-6"}>
                    <LazyLoadImage src={phone} alt={"Phone"} title={"Phone"} />
                    <div className={"ml-5"}>
                        <div>{t("aboutUsPage.consultation.phone")}</div>
                        <div>
                            {t("aboutUsPage.consultation.phoneLabel")}{" "}
                            <a href={"tel:" + settings?.contacts?.phone ?? PHONE}>
                                {settings?.contacts?.phone ?? PHONE}
                            </a>
                        </div>
                        <div>
                            {t("aboutUsPage.consultation.mexicanPhoneLabel")}{" "}
                            <a href={"tel:" + settings?.contacts?.mexicanPhone ?? PHONE}>
                                {settings?.contacts?.mexicanPhone ?? PHONE}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"flex flex-row items-center mt-6 flex-1"}>
                <LazyLoadImage src={email} alt={"Email"} title={"Email"} />
                <div className={"ml-5 flex items-center"}>
                    <a href={"mailto:" + sharedConfig.email}>{t("aboutUsPage.consultation.emailUs")}</a>
                </div>
            </div>
        </div>
    );
}
