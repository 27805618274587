import React from "react";
import { Container, Grid } from "@material-ui/core";
import { PageTitle } from "_common/ui/pages/PageTitle";
import { FeaturedArticles } from "blog/FeaturedArticles";
import { ArticleCategories } from "blog/ArticleCategories";
import { ArticlesSearchBar } from "blog/ArticlesSearchBar";
import { useParams } from "react-router";
import { ITEM_MODEL, ItemsListComponent } from "components/base/itemsList/itemsListComponent";
import { useTranslation } from "react-i18next";
import { Meta } from "_common/_utils/Meta";
import { URLS_ALTERNATES } from "_configs/URLS_ALTERNATES";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { URLS } from "_configs/URLS";

export function BlogPage() {
    const { blogType } = useParams();

    const alternateUrls = [];
    for (const lang of Object.keys(sharedConfig.languages)) {
        alternateUrls.push({
            url: URLS_ALTERNATES.blog(lang),
            lang: lang as TLang,
        });
    }

    const items = [
        {
            itemLabel: "Blog",
            url: URLS.blog(),
        },
    ];

    const { t } = useTranslation();
    return (
        <Container className="!max-w-[1260px]">
            <Meta
                title={
                    blogType
                        ? t("blogPage.meta.blogTypes.title", {
                              type: t("blogPage.blogTypes." + blogType.toUpperCase()),
                          })
                        : t("blogPage.meta.blog.title")
                }
                description={
                    blogType
                        ? t("blogPage.meta.blogTypes.description", {
                              type: t("blogPage.blogTypes." + blogType.toUpperCase()),
                          })
                        : t("blogPage.meta.blog.description")
                }
                alternateUrls={alternateUrls}
            />
            <Breadcrumb
                items={blogType ? items : undefined}
                currentItem={
                    blogType
                        ? { itemLabel: blogType.charAt(0).toUpperCase() + blogType.slice(1) }
                        : { itemLabel: "Blog" }
                }
            />
            <PageTitle style={{ display: "none" }}>Blog</PageTitle>
            <div className={"text-center mb-8"}>
                <div
                    className={"hidden md:block md:font-semibold md:text-4xl md:mb-3.5 md:mt-8 capitalize"}
                    dangerouslySetInnerHTML={{
                        __html: blogType
                            ? t("blogPage.blogTypes." + blogType.toUpperCase())
                            : t("blog.title", { color: "#5FB8DF" }),
                    }}
                />
                <div className={"hidden md:block md:text-xl md:font-normal"}>{t("blog.subtitle")}</div>
            </div>

            <div className={"flex flex-col gap-8 md:flex-row"}>
                <div className={"hidden md:block flex_column order-2 md:order-1 md:w-4/6"}>
                    <FeaturedArticles />
                </div>
                <div className={"flex !flex-col md:flex-[1/3] order-1 md:order-2 md:w-3/6"}>
                    <Grid container>
                        <Grid item xs={12}>
                            <ArticlesSearchBar />
                        </Grid>
                        <Grid item xs={12} className={"block md:hidden flex_column py-5"}>
                            <FeaturedArticles />
                        </Grid>
                        <Grid item xs={12}>
                            <ArticleCategories />
                        </Grid>
                    </Grid>
                </div>
            </div>

            <ItemsListComponent className={"mt_40 mb-10"} item_model={ITEM_MODEL.ARTICLE} pageSize={14} />
        </Container>
    );
}
