import React from "react";
import { IPageMdl } from "pages/_models/PageMdl";
import i18next from "i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
    blog: IPageMdl;
};

export function ArticleSimilar(props: Props) {
    const lang = i18next.language as keyof typeof props.blog.localized;
    const { t } = useTranslation();
    const localizedData = props.blog.localized[lang];
    if (!localizedData) return null;

    return (
        <Link to={"/" + lang + localizedData.url}>
            <div className="flex flex-row items-center mt-5">
                <div className="flex-1 mr-2.5">
                    <LazyLoadImage
                        className={"rounded-[var(--border-radius)] min-h-[60px] object-center object-cover"}
                        src={localizedData.image}
                        alt={localizedData.title}
                        title={localizedData.title}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "https://placehold.co/60?text=Zisla.com";
                        }}
                    />
                </div>
                <div className={"flex-2 flex-col"}>
                    <div
                        className={
                            "w-fit capitalize text-xs font-medium px-3 py-[3px] mb-1 text-[var(--color-text-on-primary)] bg-[var(--color-primary)]  rounded-[20px]"
                        }
                    >
                        {t("blogPage.blogTypes." + props.blog.blogType)}
                    </div>
                    <div className={"line-clamp-2 text-sm"}>{localizedData.title}</div>
                </div>
            </div>
        </Link>
    );
}
