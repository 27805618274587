import React from "react";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { CardTypesList } from "home/CardTypesList";
import { propertiesBuyingStore } from "properties/_stores/propertiesBuyingStore";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { Button } from "_common/ui/mui/buttons/Button";

export function HomeCardTypeSection() {
    const { t } = useTranslation();
    propertiesBuyingStore.fetchSortedTypes();
    return (
        <>
            <h2 className={"mb_30 headerHome colorPrimary"}>
                {t(`homePage.swiperLinks.propertyType.title`)}{" "}
                <span className={"colorSecondary"}>{t(`homePage.swiperLinks.propertyType.title2`)}</span>
            </h2>
            <LoadableFromLoading
                noSpinner
                loading={propertiesBuyingStore.sortTypesState}
                renderer={(_status, error) => {
                    if (error) return null;
                    return (
                        <CardTypesList
                            items={propertiesBuyingStore.sortTypes}
                            breakPoints={{
                                380: {
                                    slidesPerView: 1.7,
                                },
                                600: {
                                    slidesPerView: 2.5,
                                },
                                900: {
                                    slidesPerView: 3.5,
                                },
                                960: {
                                    slidesPerView: 4,
                                },
                                1440: {
                                    slidesPerView: 5,
                                },
                            }}
                        />
                    );
                }}
            />
            <div className={"flex_center_center mt_20"}>
                <Link to={URLS.buy()}>
                    <Button variant={"outlined"} color={"primary"} size={"large"}>
                        {t("homePage.viewAllProperties")}
                    </Button>
                </Link>
            </div>
        </>
    );
}
