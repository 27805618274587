import React, { ComponentPropsWithoutRef, PropsWithChildren } from "react";
import clsx from "clsx";
import styles from "../_css/propertyCardComponents.module.css";

type CardProps = ComponentPropsWithoutRef<"div">;

export type CardPropsContainer = CardProps & {
    isHover?: boolean;
};
const CardContainer = ({ className, children, ...props }: PropsWithChildren<CardPropsContainer>) => {
    return (
        <div {...props} className={clsx(styles.cardContainer, "p-3", className)}>
            {children}
        </div>
    );
};
CardContainer.displayName = "CardContainer";

const CardGalleryContainer = ({ className, ...props }: PropsWithChildren<CardProps>) => {
    return (
        <div {...props} className={clsx(styles.cardGalleryContainer, "my-2", className)}>
            {props.children}
        </div>
    );
};
CardGalleryContainer.displayName = "CardGalleryContainer";

type CardGalleryLabelTypeProps = CardProps & {
    type: string;
};
const CardGalleryLabelType = ({ className, ...props }: CardGalleryLabelTypeProps) => {
    return (
        <div {...props} className={clsx(styles.CardGalleryLabelType, "flex_center_center", className)}>
            {props.type}
        </div>
    );
};
CardGalleryLabelType.displayName = "CardGalleryLabelType";

type CardGalleryLabelNew = CardProps & {
    text: string;
};

const CardGalleryLabelNew = ({ className, ...props }: CardGalleryLabelNew) => {
    return (
        <div {...props} className={clsx(styles.CardGalleryLabelNew, "flex_center_center", className)}>
            {props.text}
        </div>
    );
};
CardGalleryLabelNew.displayName = "CardGalleryLabelNew";

const CardTitle = ({ className, ...props }: CardProps) => {
    return (
        <div {...props} className={clsx(styles.CardTitle, className)}>
            {props.children}
        </div>
    );
};
CardTitle.displayName = "CardTitle";
const CardContent = ({ className, ...props }: CardProps) => {
    return (
        <div {...props} className={clsx("flex_row_center  text-xs", className)}>
            {props.children}
        </div>
    );
};
CardContent.displayName = "CardContent";

export { CardContainer, CardGalleryContainer, CardGalleryLabelType, CardGalleryLabelNew, CardTitle, CardContent };
