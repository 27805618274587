import React from "react";
import { BLOG_TYPE } from "pages/_models/PageMdl";
import { ArticleCategoryCard } from "blog/ArticleCategoryCard";

export function ArticleCategories() {
    return (
        <div className={"max-h-[400px]"}>
            {Object.keys(BLOG_TYPE).map((blogType) => {
                return (
                    <div key={blogType}>
                        <ArticleCategoryCard blogType={blogType} />
                    </div>
                );
            })}
        </div>
    );
}
