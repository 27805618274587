import React, { createRef, useEffect } from "react";
import styles from "./_css/propertyOrUnitGallery.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs } from "swiper";
import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/swiper-bundle.min.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { userStore } from "users/_stores/userStore";
import { PropertyStore } from "properties/_stores/PropertyStore";
import { UnitTypeStore } from "units/_stores/UnitTypeStore";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import { useScrollPosition, useSSR } from "_common/_utils/hookWindow";
import { PAGE_REF } from "pages/PageRegular";
import { FOOTER_REF } from "main/footer/Footer";
import play from "../../assets/images/icons/play.svg";
import photos from "../../assets/images/icons/photos.svg";
import floorPlans from "../../assets/images/icons/floorPlans.svg";
import { GALLERY_TABS } from "properties/gallery/PropertyOrUnitGalleryPage";
import clsx from "clsx";
import { reformatImagesForGallery } from "_common/_utils/propertyUtils";
import { PhaseLabel } from "properties/gallery/PhaseLabel";
import { PROPERTY_VIEW_REF } from "properties/propertyPage/PropertyView";
import { PropertyOrUnitGalleryOpenBtn } from "properties/gallery/PropertyOrUnitGalleryOpenBtn";
import { UNIT_VIEW_REF } from "units/UnitView";
import { UnitLeftLabel } from "properties/gallery/UnitLeftLabel";
import { UnitLeftLabelForUnit } from "properties/gallery/UnitLeftLabelForUnit";
import { PhaseLabelForUnit } from "properties/gallery/PhaseLabelForUnit";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { NoSsr } from "@material-ui/core";

SwiperCore.use([Navigation, Thumbs]);

type Props = {
    store: PropertyStore | UnitTypeStore;
    resource: RESOURCE;
    onFooter?: boolean;
    numberOfUnitLeft: number;
};

export function PropertyOrUnitGallery(props: Props) {
    const refGallery = createRef<HTMLDivElement>();
    const scrollPosition = useScrollPosition(PAGE_REF);
    const { t } = useTranslation();
    const store =
        props.resource === RESOURCE.PROPERTIES ? (props.store as PropertyStore) : (props.store as UnitTypeStore);
    const RESOURCE_VIEW_REF = props.resource === RESOURCE.PROPERTIES ? PROPERTY_VIEW_REF : UNIT_VIEW_REF;
    const item =
        props.resource === RESOURCE.PROPERTIES
            ? (props.store as PropertyStore).property
            : (props.store as UnitTypeStore).unitType;

    const isFirstRender = useSSR();

    useEffect(() => {
        if (
            refGallery.current &&
            RESOURCE_VIEW_REF.current &&
            refGallery.current?.offsetWidth != RESOURCE_VIEW_REF.current.offsetWidth
        ) {
            refGallery.current.style.width = RESOURCE_VIEW_REF.current.offsetWidth + "px";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [RESOURCE_VIEW_REF.current?.offsetWidth]);

    if (!item || !item.photos || item.photos.length === 0) return null;

    const reformattedItem = reformatImagesForGallery({ ...item });

    if (userStore.isLogged && !userStore.favoritesPropertiesStates.isSucceeded) userStore.fetchFavoritesProperties();

    return (
        <div
            ref={refGallery}
            className={clsx(styles.galleryContent, {
                [styles.galleryAbsolute]:
                    !isFirstRender &&
                    FOOTER_REF.current &&
                    scrollPosition + window.screen.height > FOOTER_REF.current.offsetTop,
            })}
        >
            <div id={"propertyGallery"} className={clsx(styles.container, "position_relative flex-1 ph_20")}>
                {!(item as TPropertyListingMdl)?.hideInventory && (
                    <div className={clsx("position_absolute flex_row mt_20", styles.unitsLabel)}>
                        {props.resource === RESOURCE.UNIT_TYPES ? (
                            <UnitLeftLabelForUnit numberOfUnitLeft={props.numberOfUnitLeft} />
                        ) : (
                            <UnitLeftLabel numberOfUnitLeft={props.numberOfUnitLeft} />
                        )}
                    </div>
                )}
                {!(item as TPropertyListingMdl)?.hideInventory && (
                    <NoSsr>
                        <div className={clsx("position_absolute flex_row mt_20", styles.phaseLabel)}>
                            {props.resource === RESOURCE.UNIT_TYPES ? <PhaseLabelForUnit /> : <PhaseLabel />}
                        </div>
                    </NoSsr>
                )}
                <Swiper
                    navigation
                    loop
                    observer
                    observeParents
                    pagination={{
                        clickable: true,
                    }}
                    initialSlide={0}
                >
                    {reformattedItem.photos.map((image, index) => {
                        return (
                            <SwiperSlide key={index} className={styles.swiperContainer}>
                                <div className={"position_relative"}>
                                    {index === 0 ? (
                                        <img
                                            onClick={() => store.openGallery(0)}
                                            alt={`${reformattedItem.localized.title}-${index}`}
                                            title={`${reformattedItem.localized.title} photo ${index}`}
                                            className={styles.mainPhoto}
                                            src={image.url}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = "https://placehold.co/1000?text=Zisla.com";
                                            }}
                                        />
                                    ) : (
                                        <LazyLoadImage
                                            onClick={() => store.openGallery(0)}
                                            alt={`${reformattedItem.localized.title}-${index}`}
                                            title={`${reformattedItem.localized.title} photo ${index}`}
                                            effect={"blur"}
                                            className={styles.mainPhoto}
                                            src={image.url}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = "https://placehold.co/1000?text=Zisla.com";
                                            }}
                                        />
                                    )}
                                    {image?.title && (
                                        <div
                                            className={clsx(styles.overlay, "flex_column_center")}
                                            onClick={() => store.openGallery(0, image?.title as GALLERY_TABS)}
                                        >
                                            <LazyLoadImage
                                                src={
                                                    image?.title === GALLERY_TABS.VIDEO
                                                        ? play
                                                        : image?.title === GALLERY_TABS.GALLERY
                                                        ? photos
                                                        : floorPlans
                                                }
                                                className={styles.mainIcon}
                                                alt={"overlay see more"}
                                            />
                                            <div className={styles.mainTextOverlay}>
                                                {image?.title === GALLERY_TABS.VIDEO
                                                    ? t("propertyPage.gallery.overlay.videos")
                                                    : image?.title === GALLERY_TABS.GALLERY
                                                    ? t("propertyPage.gallery.overlay.photos")
                                                    : t("propertyPage.gallery.overlay.plans")}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                <div
                    className={clsx("position_absolute flex_center_center", styles.navigation)}
                    onClick={() => store.openGallery()}
                >
                    <PropertyOrUnitGalleryOpenBtn />
                </div>
            </div>
        </div>
    );
}
