import { useEffect } from "react";

export const useInput = () => {
    useEffect(() => {
        const handleWheel = () => {
            const activeElement = document.activeElement as HTMLInputElement | null;
            if (activeElement?.type === "number") {
                activeElement.blur();
            }
        };
        if (__BROWSER__) {
            document.addEventListener("wheel", handleWheel);
            return () => window.removeEventListener("wheel", handleWheel);
        }
    }, []);
};
