import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import { CollapseSection } from "_common/ui/components/CollapseSection";
import { Separator } from "_common/ui/components/Separator";
import { TGeoZoneMdl } from "geoZones/_models/GeoZoneMdl";
import { PROPERTY_PURPOSE, PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { TLang } from "_configs/sharedConfig";

type Props = {
    geoZone?: TGeoZoneMdl;
    purpose?: PROPERTY_PURPOSE;
};

export function PropertiesFaq({ geoZone, purpose }: Props) {
    const { t, i18n } = useTranslation();
    const { propertyType } = useSearchPageParams();
    const userLang = i18n.language;

    const getFaq = () => {
        if (propertyType) {
            return (
                geoZone?.localized[userLang as TLang]?.[purpose as PROPERTY_PURPOSE]?.[propertyType as PROPERTY_TYPE]
                    ?.faq || []
            );
        }
        return geoZone?.localized[userLang as TLang]?.[purpose as PROPERTY_PURPOSE]?.faq || [];
    };

    const faq = getFaq();

    if (!geoZone || !faq.length) return null;

    return (
        <Container className={"max-w-[690px] py-4"}>
            <h2 className={"mb-12 text-center text-xl text-[var(--color-primary)]"}>{t("propertiesPage.faq.title")}</h2>
            {faq.map((faqItem, index) => (
                <div className={"max-w-[690px] mx-auto"} key={index}>
                    <CollapseSection
                        key={index}
                        title={faqItem.question}
                        heightOfCollapsable={0}
                        titleClassName={"text-base font-normal"}
                        className={"pl-0 pr-0"}
                    >
                        <div className={"text-base py-3"}>{faqItem.answer}</div>
                    </CollapseSection>
                    <Separator className={"py-1"} />
                </div>
            ))}
        </Container>
    );
}
