import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { fetchUtils } from "_common/_utils/fetchUtils";
import i18next from "i18next";
import { TCommunitiesListingMdl } from "admin/communities/_models/CommunitiesMdl";

export class CommunitiesStore extends BaseResourceStore<TCommunitiesListingMdl> {
    constructor() {
        super("communities");
    }

    getByAliasUrl(urlAlias: string, lang: string = i18next.language) {
        const url = `${this.apiPath}/urlAlias/${urlAlias}/${lang}`;
        return fetchUtils
            .get<TCommunitiesListingMdl>(url)
            .then(({ data }) => data)
            .catch((e) => console.error(e));
    }
}

const communitiesStore = new CommunitiesStore();
export { communitiesStore };
