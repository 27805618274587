import { TFilter } from "admin/_common/filters/TFilter";
import { PROPERTY_FILTERS } from "properties/searchBar/filters/_utils/filtersUtils";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import i18next from "i18next";
import { getI18nExpByLang } from "_common/_utils/pageUtils";
import { MEXICO_PROVINCES } from "_common/regions/regions";

export function valueToLowerCase(value: any) {
    return typeof value === "string" ? value.toLowerCase() : value;
}

export function deletePropertyTypeAfterResetFilter(url: string) {
    const tmpUrl = url.split("/");
    const propertyType = tmpUrl.find(
        (str: string) =>
            str === PROPERTY_TYPE.condo ||
            str === PROPERTY_TYPE.house ||
            str === PROPERTY_TYPE.villa ||
            str === PROPERTY_TYPE.land ||
            str === PROPERTY_TYPE.hotel ||
            str === PROPERTY_TYPE.commercial,
    );

    if (propertyType) {
        const index = tmpUrl.findIndex((str: string) => str === propertyType);
        tmpUrl.splice(index, 1);
    }
    return tmpUrl.join("/");
}

export function isPropertyType(supposedPropertyType: string) {
    const allTypes = Object.values(PROPERTY_TYPE);
    const allPropertiesInAllLang: string[] = [];
    allTypes.map((type) =>
        allPropertiesInAllLang.push(
            reformatStringForUrls(getI18nExpByLang(i18next.language, `property.typesUrl.${type}`)),
        ),
    );
    return allPropertiesInAllLang.includes(supposedPropertyType);
}

export function isProvinceShortname(supposedProvinceShortname: string) {
    return Object.values(MEXICO_PROVINCES)
        .map((provinceShortname) => provinceShortname.toLowerCase())
        .includes(supposedProvinceShortname);
}

export function getFiltersFromQuery(query: { [key: string]: any }) {
    const filterFromQuery: TFilter[] = [];
    const filterType: string[] = [];
    Object.entries(query).forEach(([filterKey, filterValue]) => {
        if (filterKey.startsWith(PROPERTY_FILTERS.SQUARE_SURFACE_MAX)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.SQUARE_SURFACE_MAX,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue as string),
                op: "gte",
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.SQUARE_SURFACE_MIN)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.SQUARE_SURFACE_MIN,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue as string),
                op: "lte",
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.BATHROOMS_MAX)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.BATHROOMS_MAX,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue as string),
                op: "gte",
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.BATHROOMS_MIN)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.BATHROOMS_MIN,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue as string),
                op: "lte",
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.BEDROOMS_MAX)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.BEDROOMS_MAX,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue as string),
                op: "gte",
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.BEDROOMS_MIN)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.BEDROOMS_MIN,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue as string),
                op: "lte",
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.PRICE_MAX)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.PRICE_MAX,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue as string),
                op: "gte",
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.PRICE_MIN)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.PRICE_MIN,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue as string),
                op: "lte",
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.TYPE)) {
            (filterValue as string).split(",").map((filterValue) => filterType.push(filterValue));
        } else if (filterKey.startsWith("amenities.")) {
            const amenityKey = filterKey.slice("amenities.".length);
            const id = `amenities.${amenityKey}`;
            filterFromQuery.push({ id, value: filterValue, type: TFilterType.BOOLEAN });
        } else if (filterKey.startsWith("features.")) {
            const featureKey = filterKey.slice("features.".length);
            const id = `features.${featureKey}`;
            filterFromQuery.push({ id, value: filterValue, type: TFilterType.BOOLEAN });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.DELIVERY_TYPE_END)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.DELIVERY_TYPE_END,
                type: TFilterType.DELIVERY_DATE,
                value: filterValue,
                op: "lte",
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.STATUS)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.STATUS,
                type: TFilterType.STRING,
                value: filterValue,
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.DELIVERY_TYPE_START)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.DELIVERY_TYPE_START,
                type: TFilterType.DELIVERY_DATE,
                value: filterValue,
                op: "gte",
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.UNIT_TYPE)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.UNIT_TYPE,
                type: TFilterType.ENUM,
                value: filterValue,
            });
        }
    });

    return { filterFromQuery, filterType };
}
