import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import { action, computed, observable } from "mobx";
import { GALLERY_TABS } from "properties/gallery/PropertyOrUnitGalleryPage";
import { TUnitListingMdl } from "units/_models/UnitTypeMdl";
import { UNIT_SECTION_TABS } from "units/UnitSections";
import { RESOURCE_KEY } from "admin/_common/photos/AdminPhotosStore";
import { reformatImagesForGallery } from "_common/_utils/propertyUtils";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { TUnitAvailableMdl } from "unitAvailable/_models/UnitAvailableMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";

export class UnitTypeStore {
    @observable unitType: TUnitListingMdl;
    @observable isGalleryOpened = -1;
    @observable openedGalleryTab = GALLERY_TABS.GALLERY;
    @observable sideBarIsDisplay = false;
    @observable selectedSection = UNIT_SECTION_TABS.INFO;
    @observable imageModalIsOpen = -1;
    @observable typeOfImage = RESOURCE_KEY.PHOTOS;
    @observable infosModalIsOpen = false;
    @observable unitNumber = "";
    @observable logHistory: TUnitAvailableMdl[] = [];
    @observable logHistoryState = new LoadingStateMdl<TUnitAvailableMdl[]>();
    @observable historyModalIsOpen = false;

    constructor(unit: TUnitListingMdl) {
        this.unitType = unit;
        this.onInit();
    }

    fetchHistoryLogs(limit?: number, sort?: { [key: string]: number }) {
        if (!this.logHistoryState.isLoading && !this.logHistoryState.isSucceeded) {
            this.logHistoryState.startLoading();
            const filtersParam = `filters=${JSON.stringify([
                this.unitType.placeholdersUnitsIds?.length
                    ? {
                          id: "unitId",
                          type: TFilterType.IN,
                          value: this.unitType.placeholdersUnitsIds,
                      }
                    : {
                          id: "unitId",
                          type: TFilterType.ENUM,
                          value: this.unitType._id,
                      },
            ])}`;
            const sortParam = sort ? `&sort=${JSON.stringify(sort)}` : "";
            const limitParam = limit ? `&limit=${JSON.stringify(limit)}` : "";
            const urlUnitAvailables = `${sharedConfig.apiUrl}/unitAvailables/listing?${limitParam}${sortParam}${filtersParam}`;
            const promise = fetchUtils.get<{ items: TUnitAvailableMdl[] }>(urlUnitAvailables);
            promise.then(
                action(({ data }) => {
                    this.logHistory = data.items;
                    this.logHistoryState.setSuccess(data.items);
                }),
                action((error) => {
                    this.logHistoryState.setError(error);
                }),
            );
        }
    }

    @action setInfosModalClose() {
        this.infosModalIsOpen = false;
    }

    @action setInfosModalOpen(unitNumber?: string) {
        this.infosModalIsOpen = true;
        if (unitNumber) this.unitNumber = unitNumber;
    }

    @action setHistoryModalIsClose() {
        this.historyModalIsOpen = false;
    }

    @action setHistoryModalIsOpen() {
        this.historyModalIsOpen = true;
    }

    @computed get isForBuyingPurpose() {
        return this.unitType.property.purpose === PROPERTY_PURPOSE.BUY;
    }

    @action openGallery(imageIdx = 0, tabName = GALLERY_TABS.GALLERY) {
        this.setOpenedGalleryTab(tabName);
        this.isGalleryOpened = imageIdx;
    }

    @action setOpenedGalleryTab(tabName = GALLERY_TABS.GALLERY) {
        this.openedGalleryTab = tabName;
    }

    @action setSideBarIsDisplay(boolean: boolean) {
        this.sideBarIsDisplay = boolean;
    }

    @action setSectionTab(tabName?: UNIT_SECTION_TABS) {
        this.selectedSection = tabName ?? UNIT_SECTION_TABS.INFO;
    }

    @action setOpenedImageModal(typeOfImage = RESOURCE_KEY.PHOTOS, imageIndex?: number) {
        this.typeOfImage = typeOfImage;
        this.imageModalIsOpen = imageIndex ?? 0;
    }

    @action closeImageModal() {
        this.imageModalIsOpen = -1;
    }

    private reformatImagesForGallery() {
        reformatImagesForGallery(this.unitType);
    }

    reformatPhotosForModal() {
        this.unitType.photos = this.unitType.photos.filter((photo) => !photo.title);
    }

    closeGallery() {
        this.isGalleryOpened = -1;
    }

    onInit() {
        this.reformatImagesForGallery();
    }
}
