import React from "react";
import { useTranslation } from "react-i18next";
import educationLogo from "../assets/images/aboutUs/educationLogo.svg";
import professionalismLogo from "../assets/images/aboutUs/professionalismLogo.svg";
import integrityLogo from "../assets/images/aboutUs/integrityLogo.svg";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Navigation } from "swiper";
import { AboutUsValueCard } from "aboutUs/AboutUsValueCard";

const VALUES_BLOCKS = [
    {
        img: educationLogo,
        titleKey: "aboutUsPage.ourValues.value1Title",
        descriptionKey: "aboutUsPage.ourValues.value1Text",
    },
    {
        img: professionalismLogo,
        titleKey: "aboutUsPage.ourValues.value2Title",
        descriptionKey: "aboutUsPage.ourValues.value2Text",
    },
    {
        img: integrityLogo,
        titleKey: "aboutUsPage.ourValues.value3Title",
        descriptionKey: "aboutUsPage.ourValues.value3Text",
    },
];

SwiperCore.use([Navigation]);

export function AboutUsOurValues() {
    const { t } = useTranslation();

    return (
        <div id="aboutUsValues" className="flex flex-col mt_80 lg:flex-row lg:items-center lg:justify-between">
            <div
                className="font-semibold mb-5 text-4xl lg:mr-10"
                dangerouslySetInnerHTML={{ __html: t("aboutUsPage.ourValues.title", { color: "#5FB8DF" }) }}
            />
            <div className="lg:w-3/4">
                <Swiper
                    navigation
                    slidesPerView={1.2}
                    spaceBetween={20}
                    centeredSlides={true}
                    centeredSlidesBounds={true}
                    initialSlide={0}
                    breakpoints={{
                        375: {
                            slidesPerView: 1.5,
                        },
                        560: {
                            slidesPerView: 2,
                        },
                        680: {
                            slidesPerView: 2.5,
                        },
                        900: {
                            slidesPerView: 3,
                        },
                        1290: {
                            slidesPerView: 3,
                            spaceBetween: 53,
                        },
                    }}
                >
                    {VALUES_BLOCKS.map((value) => {
                        return (
                            <SwiperSlide key={value.titleKey}>
                                <AboutUsValueCard
                                    title={t(value.titleKey)}
                                    description={t(value.descriptionKey)}
                                    img={value.img}
                                />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    );
}
