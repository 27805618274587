import React from "react";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { pagesStore } from "pages/_stores/pagesStore";
import { useTranslation } from "react-i18next";
import { Button } from "_common/ui/mui/buttons/Button";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { BlogCard } from "home/BlogCard";

export function HomeNewsSection() {
    const { t } = useTranslation();
    const loading = pagesStore.getHomeArticles();
    return (
        <>
            <div
                className="mb_20 headerHome colorPrimary text-left md:text-center"
                dangerouslySetInnerHTML={{ __html: t("homePage.news.title") }}
            />
            <div className="mb_20 textHome colorPrimary text-left mb-5 md:max-w-[800px] md:m-auto md:text-center">
                {t("homePage.news.subTitle")}
            </div>
            <LoadableFromLoading
                loading={loading}
                renderer={(_status, error, homeArticles) => {
                    if (!homeArticles || homeArticles.length === 0 || error) return null;
                    return (
                        <div className="flex items-center justify-center flex-wrap gap-4 mt-12 mx-auto mb-0">
                            {homeArticles.map((blog) => {
                                return <BlogCard key={blog._id} blog={blog} />;
                            })}
                        </div>
                    );
                }}
            />
            <Link to={URLS.blog()}>
                <Button variant={"outlined"} color={"primary"} className="!mt-10 mb-0 mx-0 md:!mx-auto" size={"large"}>
                    {t("homePage.news.visitBlog")}{" "}
                </Button>
            </Link>
        </>
    );
}
