import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./_css/propertyOrUnitInfos.module.css";
import clsx from "clsx";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { PropertyStore } from "properties/_stores/PropertyStore";
import { UnitTypeStore } from "units/_stores/UnitTypeStore";

type Props = {
    store: PropertyStore | UnitTypeStore;
};

export function PropertyOrUnitCta(props: Props) {
    const { t } = useTranslation();

    return (
        <div className={styles.buttonCta}>
            <div className={clsx("flex_column_center mt_20 mb_20")}>
                <UiButton
                    variant={"contained"}
                    color={"secondary"}
                    className={clsx("mt_20", styles.button)}
                    onClick={() => props.store.setInfosModalOpen()}
                >
                    {t("propertyPage.cta.info")}
                </UiButton>
            </div>
        </div>
    );
}
