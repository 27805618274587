import React, { lazy } from "react";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { Button } from "_common/ui/mui/buttons/Button";
import { URLS } from "_configs/URLS";
import i18next from "i18next";
import { fetchSearchProperties, getNewsCoordinatesFromMapCoordinates } from "_common/_utils/searchUtils";
import Suspenser from "_common/loaders/Suspenser";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { ListStore } from "_common/list/ListStore";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";

const LazyMapRenderLeaflet = lazy(() => import("../maps/MapRenderLeaflet"));

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};

export const PropertiesViewMap = ({ listStore }: Props) => {
    const propertiesStore = usePropertiesStore();

    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    return (
        <div className={"flex_row flex-1 position_relative"}>
            <Suspenser noSpinner noText fallback={<div>MAP</div>}>
                <LazyMapRenderLeaflet listStore={listStore} />
                {geoZonesStore.geoZone && (
                    <div
                        className={
                            "absolute z-[1] translate-x-2/4 opacity-[95%] underline m-2.5 left-[unset] right-2/4 bottom-2.5"
                        }
                    >
                        <Button
                            onClick={async () => {
                                geoZonesStore.resetGeoZone();
                                propertiesStore.addressParams.city = "";
                                propertiesStore.addressParams.address = "";
                                propertiesStore.addressParams.region = "";
                                history.push(
                                    URLS.buy(
                                        i18next.language,
                                        getNewsCoordinatesFromMapCoordinates(
                                            propertiesStore.searchParams.mapCoordinates,
                                        ),
                                    ) + location.search,
                                );
                                fetchSearchProperties(propertiesStore, listStore, history, true);
                            }}
                            variant={"contained"}
                            color={"primary"}
                            className={"!underline"}
                        >
                            {t("zone.reset")}
                        </Button>
                    </div>
                )}
            </Suspenser>
        </div>
    );
};
