import React from "react";
import house from "../../assets/images/communities/house.svg";
import doubleHouse from "../../assets/images/communities/doubleHouse.svg";
import beachFront from "../../assets/images/communities/beachFront.svg";
import surface from "../../assets/images/communities/surface.svg";
import { CommunitiesSectionLogo } from "communities/components/CommunitiesSectionLogo";

export function CommunitiesSectionCard() {
    const LOGO_ARRAY = [
        {
            src: house,
            alt: "house",
            text: "25 Properties for sale",
        },
        {
            src: doubleHouse,
            alt: "doubleHouse",
            text: "100 Units for sale",
        },
        {
            src: beachFront,
            alt: "beachFront",
            text: "Total Surface: 526.42m2 / 5664.39ft2",
        },
        {
            src: surface,
            alt: "Beachfront",
            text: "Total Surface: 526.42m2 / 5664.39ft2",
        },
    ];
    return (
        <div className={" flex flex-col p-5 mt-10 gap-6 border rounded "}>
            {LOGO_ARRAY.map((section, index) => (
                <CommunitiesSectionLogo {...section} key={index} size={26} />
            ))}
        </div>
    );
}
