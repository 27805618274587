import React, { useContext } from "react";
import { CommunityStore } from "communities/_stores/communityStore";

const CommunityCtxt = React.createContext<CommunityStore>({} as CommunityStore);

type Props = {
    communityStore: CommunityStore;
};

export function useCommunityStore() {
    return useContext(CommunityCtxt);
}

export function CommunityContext(props: React.PropsWithChildren<Props>) {
    return <CommunityCtxt.Provider value={props.communityStore}>{props.children}</CommunityCtxt.Provider>;
}
