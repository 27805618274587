import React, { CSSProperties } from "react";
import { IPageShortMdl, TComponent } from "pages/_models/PageMdl";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { pagesStore } from "pages/_stores/pagesStore";
import { NewsCardComponent } from "components/news/newsCard/NewsCardComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import { useWindowSize } from "_common/_utils/hookWindow";

type Props = {
    style?: CSSProperties;
    numberOfCardsToDisplay?: number;
    $component?: TComponent;
};

function LatestNewsSliderLoaded(props: Props & { latestArticles: IPageShortMdl[] }) {
    const { width } = useWindowSize();
    if (props.latestArticles.length === 0) return null;
    return (
        <div style={{ margin: "0 -20px" }}>
            <Swiper slidesPerView={width > 1500 ? 3 : width > 750 ? 2 : 1} initialSlide={0}>
                {props.latestArticles.map((article) => {
                    if (!article) return;
                    return (
                        <SwiperSlide key={article._id}>
                            <div className={"mh_20 mb_20"}>
                                <NewsCardComponent
                                    date={article.localized.publishedAt}
                                    title={article.localized.title}
                                    text={article.localized.description}
                                    image={article.localized.image}
                                    url={article.localized.url}
                                />
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
}

export function LatestNewsSliderComponent(props: Props) {
    return (
        <LoadableFromLoading
            loading={pagesStore.getLatestArticles(Math.min(props.numberOfCardsToDisplay ?? 5, 10))}
            renderer={(_status, _error, latestArticles) => {
                if (!latestArticles) return null;
                return <LatestNewsSliderLoaded {...props} latestArticles={latestArticles} />;
            }}
        />
    );
}
