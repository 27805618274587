import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { TFilter } from "admin/_common/filters/TFilter";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { PropertyCardFeatured } from "properties/propertyCard/PropertyCardFeatured";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { Button } from "_common/ui/mui/buttons/Button";

SwiperCore.use([Navigation]);

const NAME = "blogExploreProperties";

type Props = {
    geoZoneId: string | undefined;
};

export const BlogExplorePropertiesInGeoZone = observer(({ geoZoneId }: Props) => {
    const { t } = useTranslation();
    const [properties, setProperties] = useState<any[]>([]);
    const [geoZoneName, setGeoZoneName] = useState<string>("");

    if (!geoZoneId) return null;

    useEffect(() => {
        if (geoZoneId) {
            const fetchProperties = async () => {
                const filters: TFilter[] = [
                    {
                        id: "published",
                        type: TFilterType.BOOLEAN,
                        value: true,
                    },
                    {
                        id: "location",
                        value: {
                            geoZoneId: geoZoneId,
                        },
                        type: TFilterType.ZONE,
                    },
                ];
                const result = await propertiesStore.list(0, 6, undefined, undefined, filters);
                if (result && result.items) {
                    setProperties(result.items);
                }
            };

            const fetchGeoZoneName = async () => {
                if (geoZoneId) {
                    const geoZone = await geoZonesStore.get(geoZoneId);
                    if (geoZone && geoZone.name) {
                        setGeoZoneName(geoZone.name);
                    }
                }
            };

            fetchProperties();
            fetchGeoZoneName();
        }
    }, [geoZoneId]);

    return (
        <div className={"mt-12"}>
            <div className={"flex flex-col md:flex-row justify-center items-center mb-5"}>
                <h2 className={"text-xl text-[var(--color-primary)] text-center md:mr-1"}>
                    {t("blogPage.exploreProperties")} {geoZoneName}
                </h2>
            </div>
            <div id={NAME} className={"position_relative desktopDisplay"}>
                <Swiper
                    loop
                    navigation={{
                        prevEl: `.swiper-button-prev.${NAME}`,
                        nextEl: `.swiper-button-next.${NAME}`,
                    }}
                    slidesPerView={3.1}
                    spaceBetween={20}
                    breakpoints={{
                        690: {
                            slidesPerView: 2,
                        },
                        1180: {
                            slidesPerView: 2.5,
                        },
                        1400: {
                            slidesOffsetBefore: 20,
                            slidesPerView: 3.1,
                        },
                    }}
                >
                    {properties.map((property, index) => (
                        <SwiperSlide key={index}>
                            <PropertyCardFeatured property={property} />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className={`swiper-button-prev ${NAME}`} />
                <div className={`swiper-button-next ${NAME}`} />
                <div className={clsx("flex items-center justify-center mt-12")}>
                    <Link to={URLS.buy()}>
                        <Button variant={"outlined"} color={"primary"} size={"large"}>
                            {t("homePage.viewAllProperties")}
                        </Button>
                    </Link>
                </div>
            </div>
            <div className={"mobileDisplay"}>
                {properties.map((property, index) => (
                    <SwiperSlide key={index}>
                        <div className={" mb-4"} key={property._id}>
                            <PropertyCardFeatured property={property} />
                        </div>
                    </SwiperSlide>
                ))}
                <div className={clsx("flex items-center justify-center mt-12")}>
                    <Link to={URLS.buy()}>
                        <Button variant={"outlined"} color={"primary"} size={"large"}>
                            {t("homePage.viewAllProperties")}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
});
