import React from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import beachfront from "../assets/images/aboutUs/beachfront.jpg";

export function AboutUsHeader() {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col md:flex-row mt-10 md:mt-20">
            <div className="flex-1 md:h-auto">
                <div
                    className="font-semibold mb-4 text-4xl block md:hidden"
                    dangerouslySetInnerHTML={{ __html: t("aboutUsPage.header.title", { color: "#5FB8DF" }) }}
                />
                <div className={"flex flex-row h-full"}>
                    <LazyLoadImage className={"flex-1 rounded-lg h-full object-cover object-center"} src={beachfront} />
                </div>
            </div>
            <div className="flex-1 md:ml-10 flex flex-col justify-center">
                <div
                    className="font-semibold text-4xl hidden md:!block"
                    dangerouslySetInnerHTML={{ __html: t("aboutUsPage.header.title", { color: "#5FB8DF" }) }}
                />
                <div
                    className="font-normal mt-10 md:pr-0 text-base"
                    dangerouslySetInnerHTML={{ __html: t("aboutUsPage.header.text") }}
                />
            </div>
        </div>
    );
}
