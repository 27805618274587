import cancun from "../../../shared/assets/images/cities/Cancun.png";
import tulum from "../../../shared/assets/images/cities/Tulum.png";
import playaDelCarmen from "../../../shared/assets/images/cities/playaDelCarmen.jpg";
import akumal from "../../../shared/assets/images/cities/akumal.jpg";
import merida from "../../../shared/assets/images/cities/Merida.png";
import chelem from "../../../shared/assets/images/cities/chelem.jpg";
import mahahual from "../../../shared/assets/images/cities/mahahual.jpg";
import elCuyo from "../../../shared/assets/images/cities/elCuyo.jpg";
import cozumel from "../../../shared/assets/images/cities/cozumel.jpg";
import playacar from "../../../shared/assets/images/cities/Playa_Del_Carmen.png";
import progresso from "../../../shared/assets/images/cities/progresso.jpg";
import puertoAventuras from "../../../shared/assets/images/cities/puertoAventuras.jpg";
import puertoMorelos from "../../../shared/assets/images/cities/puertoMorelos.jpg";
import puertoVallarta from "../../../shared/assets/images/cities/puertoVallarta.jpg";
import { WithRequiredProperties } from "_common/types/GenericTypes";

type TCityBase = { name: string; region: MEXICO_PROVINCES; lat: number; lng: number; nbOfProperties?: number };

type TCityOptional = {
    img?: string;
    isHomeCard?: boolean;
};

export type TCity = TCityBase & (TCityOptional | WithRequiredProperties<TCityOptional>);

export enum MEXICO_PROVINCES {
    QUINTANA_ROO = "QUINTANA_ROO",
    YUCATAN = "YUCATAN",
    JALISCO = "JALISCO",
    OAXACA = "OAXACA",
    BAJA_CALIFORNIA_SUR = "BAJA_CALIFORNIA_SUR",
    NAYARIT = "NAYARIT",
    STATE_OF_PUEBLA = "STATE_OF_PUEBLA",
    "CAM" = "CAMPECHE",
    "GTO" = "STATE_OF_GUANAJUATO",
}

export const MEXICO_CITIES: TCity[] = [
    {
        name: "Cancun",
        region: MEXICO_PROVINCES.QUINTANA_ROO,
        lat: 21.161908,
        lng: -86.8515279,
        img: cancun,
        isHomeCard: true,
    },
    {
        name: "Playa del Carmen",
        region: MEXICO_PROVINCES.QUINTANA_ROO,
        lat: 20.6295586,
        lng: -87.0738851,
        img: playaDelCarmen,
        isHomeCard: true,
    },
    { name: "Playa Mujeres", region: MEXICO_PROVINCES.QUINTANA_ROO, lat: 21.1648, lng: -86.8514 },
    { name: "Isla Mujeres", region: MEXICO_PROVINCES.QUINTANA_ROO, lat: 21.1648, lng: -86.8514 },
    {
        name: "Tulum",
        region: MEXICO_PROVINCES.QUINTANA_ROO,
        lat: 20.208771461477255,
        lng: -87.46535019999999,
        img: tulum,
        isHomeCard: true,
    },
    {
        name: "Akumal",
        region: MEXICO_PROVINCES.QUINTANA_ROO,
        lat: 21.1648,
        lng: -86.8514,
        img: akumal,
        isHomeCard: true,
    },
    { name: "Mahahual", region: MEXICO_PROVINCES.QUINTANA_ROO, lat: 21.1648, lng: -86.8514, img: mahahual },
    { name: "Coba", region: MEXICO_PROVINCES.QUINTANA_ROO, lat: 21.1648, lng: -86.8514 },
    { name: "Cozumel", region: MEXICO_PROVINCES.QUINTANA_ROO, lat: 21.1648, lng: -86.8514, img: cozumel },
    { name: "Bacalar", region: MEXICO_PROVINCES.QUINTANA_ROO, lat: 21.1648, lng: -86.8514 },
    { name: "Chetumal", region: MEXICO_PROVINCES.QUINTANA_ROO, lat: 21.1648, lng: -86.8514 },
    { name: "Merida", region: MEXICO_PROVINCES.YUCATAN, lat: 21.1648, lng: -86.8514, img: merida, isHomeCard: true },
    { name: "San", region: MEXICO_PROVINCES.YUCATAN, lat: 21.1648, lng: -86.8514 },
    { name: "Progreso", region: MEXICO_PROVINCES.YUCATAN, lat: 21.1648, lng: -86.8514, img: progresso },
    { name: "Chelem", region: MEXICO_PROVINCES.YUCATAN, lat: 21.1648, lng: -86.8514, img: chelem, isHomeCard: true },
    {
        name: "Puerto Aventuras",
        region: MEXICO_PROVINCES.QUINTANA_ROO,
        lat: 21.1648,
        lng: -86.8514,
        img: puertoAventuras,
    },
    { name: "Holbox", region: MEXICO_PROVINCES.QUINTANA_ROO, lat: 21.1648, lng: -86.8514 },
    { name: "Chemuyil", region: MEXICO_PROVINCES.QUINTANA_ROO, lat: 21.1648, lng: -86.8514 },
    { name: "Puerto Morelos", region: MEXICO_PROVINCES.QUINTANA_ROO, lat: 21.1648, lng: -86.8514, img: puertoMorelos },
    {
        name: "Puerto Vallarta",
        region: MEXICO_PROVINCES.JALISCO,
        lat: 20.641,
        lng: -105.2945,
        img: puertoVallarta,
        isHomeCard: true,
    },
    { name: "El Cuyo", region: MEXICO_PROVINCES.YUCATAN, lat: 21.1648, lng: -86.8514, img: elCuyo },
    { name: "Playacar", region: MEXICO_PROVINCES.QUINTANA_ROO, lat: 21.1648, lng: -86.8514, img: playacar },
].sort((a, b) => (a.name > b.name ? 1 : -1));

export const DEFAULT_CITY = MEXICO_CITIES.find((city) => city.name === "Tulum") as TCity;

type ExtractCityNames<T extends { name: string }[]> = T[number]["name"];

export type CityName = ExtractCityNames<typeof MEXICO_CITIES>;
