import React from "react";
import { CommunitiesTitle } from "communities/components/CommunitiesTitle";
import { useCommunityStore } from "communities/CommunityContext";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import checkLogo from "../../assets/images/communities/featuresAndAmentiesCheck.svg";

export function CommunitiesFeaturesAndAmenties() {
    const { t } = useTranslation();
    const { community } = useCommunityStore();

    return (
        <div className={" flex flex-col p-5 mt-10 gap-6 border rounded "}>
            <CommunitiesTitle title={"Features & Amenties"} delimiter={2} />
            <ul>
                <>
                    {Object.keys(community.features).map((key) => (
                        <li key={key} className={"flex items-center"}>
                            <LazyLoadImage width={26} height={26} src={checkLogo} alt={key} />
                            {t(`property.features.${key}`)}
                        </li>
                    ))}
                    {Object.keys(community.amenities).map((key) => (
                        <li key={key} className={"flex items-center"}>
                            <LazyLoadImage width={26} height={26} src={checkLogo} alt={key} />
                            {t(`property.amenities.${key}`)}
                        </li>
                    ))}
                </>
            </ul>
        </div>
    );
}
