import React from "react";
import { HomeServiceCard } from "home/HomeServiceCard";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { ArrowPaginated } from "_common/ui/swiper/ArrowPaginated";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { Button } from "_common/ui/mui/buttons/Button";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

SwiperCore.use([Navigation]);

const SECTIONS = [
    {
        preTitleKey: "homePage.serviceBlock.1.preTitle",
        titleKey: "homePage.serviceBlock.1.title",
        subTitleKey: "homePage.serviceBlock.1.subTitle",
        textKey: "homePage.serviceBlock.1.text",
    },
    {
        preTitleKey: "homePage.serviceBlock.2.preTitle",
        titleKey: "homePage.serviceBlock.2.title",
        subTitleKey: "homePage.serviceBlock.2.subTitle",
        textKey: "homePage.serviceBlock.2.text",
    },
    {
        preTitleKey: "homePage.serviceBlock.3.preTitle",
        titleKey: "homePage.serviceBlock.3.title",
        subTitleKey: "homePage.serviceBlock.3.subTitle",
        textKey: "homePage.serviceBlock.3.text",
    },
];
const NAME = "homeService";

export function HomeServices() {
    const { t } = useTranslation();

    return (
        <div id={NAME} className={clsx("position_relative")}>
            <Swiper
                allowTouchMove={false}
                className={"position_relative"}
                navigation={{ prevEl: `.swiper-button-prev.${NAME}`, nextEl: `.swiper-button-next.${NAME}` }}
            >
                {SECTIONS.map((section, idx) => {
                    return (
                        <SwiperSlide key={idx}>
                            <HomeServiceCard numberOfSection={idx} content={section} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <div className="mb-5 md:mb-0 md:absolute md:bottom-0 md:right-0">
                <ArrowPaginated numberOfSection={SECTIONS.length} name={NAME} />
            </div>
            <Link to={URLS.contact()} className="text-white">
                <Button variant={"contained"} size={"large"}>
                    {t("homePage.values.contactUs")}
                </Button>
            </Link>
        </div>
    );
}
