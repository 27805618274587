import React from "react";
import { URLS } from "_configs/URLS";
import { getResourceInitialStateValue } from "_common/_utils/initialStateUtils";
import { useTranslation } from "react-i18next";
import { TCity } from "_common/regions/regions";
import { FooterLinkList } from "main/footer/FooterLinkList";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import { POPULAR_SEARCHES_CITIES } from "main/footer/popularSearches";

export function FooterPopularSearches() {
    const { t } = useTranslation();
    const pickedCitiesFromPreloadedState = getResourceInitialStateValue("pickedCities") as { items: TCity[] };

    const popularSearches = (pickedCitiesFromPreloadedState.items.length > 0
        ? pickedCitiesFromPreloadedState.items
        : POPULAR_SEARCHES_CITIES
    )
        .slice(0, 3)
        .map((city) => {
            return [
                {
                    label: city.name,
                    url: URLS.buy(
                        undefined,
                        reformatStringForUrls(t("provinces.short." + city.region)),
                        reformatStringForUrls(city.name),
                        t("property.types.house"),
                    ).toLowerCase(),
                    labelKey: `footer.popularSearchesLinks.house`,
                },
                {
                    label: city.name,
                    url: URLS.buy(
                        undefined,
                        reformatStringForUrls(t("provinces.short." + city.region)),
                        reformatStringForUrls(city.name),
                        t("property.types.condo"),
                    ).toLowerCase(),
                    labelKey: `footer.popularSearchesLinks.condo`,
                },
                {
                    label: city.name,
                    url: URLS.buy(
                        undefined,
                        reformatStringForUrls(t("provinces.short." + city.region)),
                        reformatStringForUrls(city.name),
                        t("property.types.villa"),
                    ).toLowerCase(),
                    labelKey: `footer.popularSearchesLinks.villa`,
                },
                {
                    label: city.name,
                    url: URLS.buy(
                        undefined,
                        reformatStringForUrls(t("provinces.short." + city.region)),
                        reformatStringForUrls(city.name),
                        t("property.types.land"),
                    ).toLowerCase(),
                    labelKey: `footer.popularSearchesLinks.land`,
                },
            ];
        })
        .flat();

    return <FooterLinkList titleKeyLabel={t("footer.popularSearches")} list={popularSearches} />;
}
