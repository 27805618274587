import { observable } from "mobx";
import { TCommunitiesListingMdl } from "admin/communities/_models/CommunitiesMdl";

export class CommunityStore {
    @observable community: TCommunitiesListingMdl;

    constructor(community: TCommunitiesListingMdl) {
        this.community = community;
    }
}
