import React, { CSSProperties, useRef } from "react";
import { TComponent } from "pages/_models/PageMdl";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Link } from "react-router-dom";

export type NewsCardComponentProps = {
    date?: Dayjs | string;
    title?: string;
    text?: string;
    image?: string;
    featured?: boolean;
    url?: string;
    style?: CSSProperties;
    $component?: TComponent;
    blogType?: string;
};

export function NewsCardComponent(props: NewsCardComponentProps) {
    const mouseDownStart = useRef(0);
    const { t } = useTranslation();
    const lang = i18next.language;
    const date = typeof props.date === "string" ? dayjs(props.date) : props.date;

    return (
        <Link
            style={{ ...props.style }}
            className={
                "flex flex-col relative flex-1 min-h-[410px] shadow-[0_0_5px_0_#1a3a4533] rounded-lg text-[var(--color-primary)] bg-[var(--color-secondary)]"
            }
            onMouseDown={() => (mouseDownStart.current = Date.now())}
            to={"/" + lang + props.url}
        >
            {props.blogType && (
                <div
                    className={
                        "absolute capitalize text-sm font-medium text-[var(--color-text-on-primary)] bg-[var(--color-primary)] z-[var(--zIndex-product-card-label)] px-3 py-[3px] rounded-[20px] left-[20px] top-3"
                    }
                >
                    {t("blogPage.blogTypes." + props.blogType)}
                </div>
            )}
            <div className="relative flex-shrink-0 rounded-t-lg overflow-hidden">
                <img src={props.image} alt={props.title} className="object-cover w-full h-[205px]" />
            </div>
            <div className="flex flex-col  bg-[var(--color-firstLvl-bg)] w-full h-[205px] pb-2.5 px-6 rounded-b-lg">
                <div className="flex flex-row align-center justify-between pt-3 pb-2.5 text-[var(--color-secondary)] text-sm">
                    <div>{date?.format("MMMM D, YYYY")}</div>
                </div>
                <h2 className="text-base font-semibold w-full text-[var(--color-primary)]">{props.title}</h2>
                <div>
                    <div className="pt-2.5 text-[var(--color-primary)] line-clamp-3">{props.text}</div>
                </div>
            </div>
        </Link>
    );
}
