import React from "react";
import { ListStore } from "_common/list/ListStore";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import clsx from "clsx";
import styles from "./_css/propertiesViewButtonsMobile.module.css";
import { PropertiesViewButtonMapList } from "properties/PropertiesViewButtonMapList";
import { SavedSearchButton } from "properties/SavedSearchButton";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};

export function PropertiesViewButtonsMobile({ listStore }: Props) {
    return (
        <div
            className={clsx(
                styles.container,
                "fixed w-full z-10 bottom-5 sm:hidden flex items-center justify-center gap-4",
            )}
        >
            <PropertiesViewButtonMapList />
            <SavedSearchButton listStore={listStore} />
        </div>
    );
}
