import React, { Suspense } from "react";
import { Loader } from "_common/loaders/Loader";
import { NoSsr } from "@material-ui/core";

type Props = {
    fallback?: JSX.Element;
    noSpinner?: boolean;
    noText?: boolean;
};

class Suspenser extends React.Component<Props, any> {
    render() {
        return (
            <NoSsr>
                {__BROWSER__ ? (
                    <Suspense
                        fallback={
                            this.props.fallback ?? (
                                <Loader noSpinner={this.props.noSpinner} noText={this.props.noText} />
                            )
                        }
                    >
                        {this.props.children}
                    </Suspense>
                ) : (
                    <>{this.props.fallback ?? <Loader noSpinner={this.props.noSpinner} noText={this.props.noText} />}</>
                )}
            </NoSsr>
        );
    }
}

export default Suspenser;
