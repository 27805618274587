export type TInquiry = {
    [lang in string]: string[];
};
export const Inquiry: TInquiry = {
    fr: [
        "Intéressé par l'achat d'une propriété au Mexique",
        "Vendre ma propriété",
        "Demande d'information pour un courtier",
        "Publicité sur ce site",
        "Demande de services de marketing",
        "Autre",
    ],
    en: [
        "Interested in buying property in Mexico",
        "Sell my property",
        "Broker inquiry for information",
        "Advertising on this site",
        "Marketing services inquiry",
        "Other",
    ],
    es: [
        "Interesado en comprar propiedad en México",
        "Vender mi propiedad",
        "Consulta de información para corredores",
        "Publicidad en este sitio",
        "Consulta de servicios de marketing",
        "Otro",
    ],
    zh: [
        "Interested in buying property in Mexico",
        "Sell my property",
        "Broker inquiry for information",
        "Advertising on this site",
        "Marketing services inquiry",
        "Other",
    ],
};
