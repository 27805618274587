import React from "react";
import styles from "./_css/button.module.css";
import { Button as MuiButton, ButtonProps } from "@material-ui/core";

type Props = ButtonProps & {
    isDeleteButton?: boolean;
};

export function Button(props: Props) {
    return (
        <MuiButton
            {...props}
            variant={props.variant}
            className={props.className}
            classes={{
                root: styles.root,
                label: styles.label,
                outlined: styles.outlined,
                sizeSmall: styles.sizeSmall,
                sizeLarge: styles.sizeLarge,
                contained: styles.contained,
                disabled: styles.disabled,
            }}
        />
    );
}
