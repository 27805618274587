import React, { useState } from "react";
import clsx from "clsx";
import { isEmail } from "_common/_utils/alphaNumUtils";
import { marketingStore } from "marketing/_stores/marketingStore";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import styles from "./_css/footerForm.module.css";

export function FooterForm() {
    const [formData, setFormData] = useState({ name: "", email: "", phone: "" });
    const { t } = useTranslation();

    return (
        <>
            <div
                className={clsx(styles.title, "mb_20")}
                dangerouslySetInnerHTML={{ __html: t("footer.newsletterTitle") }}
            />
            <div className={"mb_20 text_normal"}>{t("footer.newsletterContent")}</div>
            <input
                name="firstName"
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                placeholder={t("footer.firstName")}
                className={styles.input}
            />
            <input
                name="phone"
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                placeholder={t("footer.phone")}
                className={clsx(styles.input, "mt_20")}
            />
            <input
                name="email"
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                placeholder={t("footer.email")}
                className={clsx(styles.input, "mt_20")}
            />
            <div
                onClick={() => {
                    if (formData.email && formData.name && formData.phone && isEmail(formData.email)) {
                        marketingStore.subscribe(formData).then(
                            () => toast.success(t("footer.youVeBeenSubscribed")),
                            () => toast.error(t("footer.error")),
                        );
                    } else {
                        toast.error(t("footer.checkACFields"));
                    }
                }}
                className={clsx(styles.button, "mt_20")}
            >
                {t("words.submit")}
            </div>
        </>
    );
}
