import React from "react";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { CardContent } from "./PropertyCardComponents";

type Props = {
    property: TPropertyListingMdl;
};

export function PropertyCardAddress(props: Props) {
    const { t } = useTranslation();

    const sortAddress = [
        props.property.address.neighbourhood,
        props.property.address.city,
        t("provinces." + props.property.address.province.toUpperCase()),
        t("propertyPage.country." + (props.property.address.country ?? "MEX")),
    ];
    const completeAddress = sortAddress
        .filter((addressPart) => addressPart !== undefined && addressPart !== "")
        .slice(0)
        .join(", ");

    return <CardContent dangerouslySetInnerHTML={{ __html: completeAddress }} />;
}
