import React from "react";
import { pagesStore } from "pages/_stores/pagesStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { TLang } from "_configs/sharedConfig";
import { NewsCardComponent, NewsCardComponentProps } from "components/news/newsCard/NewsCardComponent";
import { IPageMdl } from "pages/_models/PageMdl";
import i18next from "i18next";

export function FeaturedArticles() {
    const listStore = pagesStore.getListStore("featuredArticlesList", 1);
    const loadingState = listStore.currentLoadingState;
    if (!loadingState) return null;
    return (
        <LoadableFromLoading<{ count: number; items: IPageMdl[] }>
            loading={loadingState}
            renderer={() => {
                if (listStore.paginatedItems.length <= 0) return null;
                const lang = i18next.language;
                const item: IPageMdl = listStore.paginatedItems[0] as IPageMdl;
                if (!item) return null;
                const news: NewsCardComponentProps | undefined = {
                    ...item,
                    title: item.localized[lang as TLang]?.title,
                    date: item.localized[lang as TLang]?.publishedAt,
                    text: item.localized[lang as TLang]?.description,
                    image: item.localized[lang as TLang]?.image,
                    url: item.localized[lang as TLang]?.url,
                };
                return <NewsCardComponent featured {...news} />;
            }}
        />
    );
}
