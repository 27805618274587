import React from "react";
import { HeaderContact } from "main/header/HeaderContact";
import { Menu } from "main/header/menu/Menu";
import { LangSelector } from "main/header/LangSelector";
import { CurrencySelector } from "main/header/CurrencySelector";
import { NoSsr } from "@material-ui/core";

export function HeaderContent() {
    return (
        <>
            <HeaderContact />
            <div className={"flex_row_center gap-7"}>
                <Menu />
                <LangSelector />
                <NoSsr>
                    <CurrencySelector />
                </NoSsr>
            </div>
        </>
    );
}
