import React, { lazy, useEffect } from "react";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { PropertiesMeta } from "properties/PropertiesMeta";
import { getPageFromUrl } from "_common/_utils/pageUtils";
import Suspenser from "_common/loaders/Suspenser";
import { useHistory } from "react-router-dom";
import { getUrlSearchFromFilters } from "properties/searchBar/filters/_utils/filtersUtils";
import styles from "./_css/propertiesPage.module.css";
import { toJS } from "mobx";
import { PropertiesViewContext } from "properties/PropertyPageContext";
import clsx from "clsx";
import { Separator } from "_common/ui/components/Separator";
import { PropertiesView } from "properties/PropertiesView";

const LazyPropertiesSearchBar = lazy(() => import("properties/searchBar/PropertiesSearchBar"));

export const PropertiesPage = () => {
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose, undefined, undefined, true);
    const history = useHistory();
    listStore.setSelectedPage(getPageFromUrl(history.location.pathname) ?? 1);
    useEffect(() => {
        history.push({
            pathname: history.location.pathname,
            search: getUrlSearchFromFilters(listStore.filters),
        });
    }, []);
    console.table(toJS(listStore.filters));
    if (!propertiesStore.purpose) return null;
    return (
        <PropertiesViewContext>
            <PropertiesMeta />
            <div className={"px-3 md:px-7"}>
                <div className={clsx(styles.searchBar, "flex items-center mb-2 lg:m-0")}>
                    <Suspenser noSpinner fallback={<div className={"h-[height:var(--height-searchBar)]"}></div>}>
                        <LazyPropertiesSearchBar listStore={listStore} />
                    </Suspenser>
                </div>
                <Separator className={"lg:hidden"} />
                <PropertiesView listStore={listStore} />
            </div>
        </PropertiesViewContext>
    );
};
