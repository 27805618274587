import React from "react";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";

type Props = {
    property: TPropertyListingMdl;
};

export function PropertyCardTitle({ property }: Props) {
    return <>{property.localized.title}</>;
}
