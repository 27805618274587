import React from "react";
import { CommunitiesSectionCard } from "communities/components/CommunitiesSectionCard";
import { CommunitiesTitle } from "communities/components/CommunitiesTitle";

export function CommunitiesInformationSection() {
    return (
        <div className={"flex flex-col lg:flex-row"}>
            <div>
                <span>Communities:</span>
                <CommunitiesTitle title={"Costa Palmas in Los Cabos"} delimiter={2} />
                <p>
                    Set at the edge of the Sea of Cortéz, Costa Palmas is a dramatic landscape, a private marina, a
                    village to stroll, a golf course to master…and a home. Spread across the resort are organic farms,
                    orchards and an estuary, the Beach & Yacht Club, the Marina Village, and a Robert Trent Jones II
                    golf course and club. Costa Palmas is a unique invitation to elemental luxury and spirited adventure
                    on a calm blue sea of dreams.
                </p>
            </div>
            <CommunitiesSectionCard />
        </div>
    );
}
