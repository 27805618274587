import React from "react";
import clsx from "clsx";
import styles from "properties/propertyPage/_css/propertyOrUnitHeader.module.css";
import { useTranslation } from "react-i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { PropertyPrice } from "properties/propertyPage/PropertyPrice";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { useUnitStore } from "units/UnitContext";
import { UnitPrice } from "units/UnitPrice";
import { TUnitListingMdl } from "units/_models/UnitTypeMdl";

type Props = {
    resource: RESOURCE;
    units?: (TUnitListingMdl | undefined)[];
};

export function PropertyOrUnitHeaderMobile(props: Props) {
    const { t } = useTranslation();
    const propertyStore = usePropertyStore();
    const unitStore = useUnitStore();

    return (
        <div className={clsx(styles.mobileContainer, "mb_10")}>
            <div className={clsx("flex_column_center", styles.mobileSection)}>
                <UiButton
                    variant={"contained"}
                    color={"secondary"}
                    className={styles.button}
                    onClick={() =>
                        props.resource === RESOURCE.PROPERTIES
                            ? propertyStore.setInfosModalOpen()
                            : unitStore.setInfosModalOpen()
                    }
                >
                    {t("propertyPage.cta.info")}
                </UiButton>
            </div>
            <div className={"mb_20"}>
                {props.resource === RESOURCE.PROPERTIES ? <PropertyPrice units={props.units} /> : <UnitPrice />}
            </div>
        </div>
    );
}
