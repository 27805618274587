import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { observer } from "mobx-react";
import { URLS } from "_configs/URLS";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";

type Props = {
    image: string;
    selectedGeozone: string;
};

export const DiscoverPropertiesComponent = observer((props: Props) => {
    const { t } = useTranslation();
    const [selectedGeozoneName, setSelectedGeozoneName] = useState("");
    const [url, setUrl] = useState("");

    useEffect(() => {
        geoZonesStore.getAsync(props.selectedGeozone).then((geozone) => {
            if (!geozone) return;

            const { name, address, type } = geozone;
            const addressParts = name.split(",").map((part) => part.trim());

            let displayName = "";
            if (type === "CITY") {
                displayName = addressParts[0];
            } else if (type === "NEIGHBORHOOD") {
                const neighborhood = addressParts[0];
                const city = addressParts[1];
                displayName = `${neighborhood}, ${city}`;
            }

            setSelectedGeozoneName(displayName);
            setUrl(
                URLS.buy(
                    undefined,
                    reformatStringForUrls(address.province),
                    reformatStringForUrls(address.city),
                ).toLowerCase(),
            );
        });
    }, [props.selectedGeozone]);

    return (
        <div
            className="w-full h-[216px] md:h-[389px] bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url(${props.image})` }}
        >
            <div className="flex items-center justify-center h-full">
                <div className="flex flex-col md:flex-row items-center bg-white bg-opacity-90 mx-3 md:mx-0">
                    <h2 className="py-[11px] text-center text-base md:!text-xl p-[27px] md:p-[27px] md:pr-0">
                        {t("words.discoverProperties")} {selectedGeozoneName}
                    </h2>
                    <Link
                        to={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm p-[15px] border !border-[var(--color-primary)] rounded-[35px] mr-[27px] mb-[11px] md:ml-[27px] md:mb-0"
                    >
                        {t("homePage.viewAllProperties")}
                    </Link>
                </div>
            </div>
        </div>
    );
});
