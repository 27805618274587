import React from "react";
import dayjs from "dayjs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { IPageMdl, TLocalized } from "pages/_models/PageMdl";

type Props = {
    localizedPage: TLocalized;
    page: IPageMdl;
};
export function BlogHeader(props: Props) {
    const { t } = useTranslation();

    const displayDate =
        props.page.updatedAt && !props.page.updatedAt.isSame(props.localizedPage.publishedAt as dayjs.Dayjs, "day")
            ? t("words.updated") + dayjs(props.page.updatedAt).format("MMMM D, YYYY")
            : dayjs(props.localizedPage.publishedAt).format("MMMM D, YYYY");

    return (
        <>
            <div className={"flex flex-row items-center mr-5 ml-5 md:ml-8"}>
                <div className={"flex flex-col items-center mt-5"}>
                    <div className={"flex flex-col flex-1"}>
                        {props.localizedPage.image && (
                            <div className={"mb-4 md:mb-10"}>
                                <LazyLoadImage
                                    className={
                                        "w-[340px] h-[127px] md:w-[924px] md:h-[344px] object-cover rounded-[var(--border-radius)]"
                                    }
                                    src={props.localizedPage.image}
                                    alt={props.localizedPage.title}
                                    title={props.localizedPage.title}
                                />
                            </div>
                        )}

                        <div className={"flex flex-row"}>
                            <div
                                className={
                                    "capitalize text-sm font-medium px-3 py-[3px] text-[var(--color-text-on-primary)] bg-[var(--color-primary)]  rounded-[20px]"
                                }
                            >
                                {t("blogPage.blogTypes." + props.page.blogType)}
                            </div>
                        </div>
                        <h1 className={"!text-xl !font-normal !my-2.5"}>{props.localizedPage.title}</h1>
                        <div className={"text-[#52B5DB] text-sm"}>{displayDate}</div>
                    </div>
                </div>
            </div>
            {/*<BlogHeaderMobile localizedPage={props.localizedPage} page={props.page} />*/}
        </>
    );
}
