import React from "react";
import clsx from "clsx";
import styles from "home/_css/homeTestimonialSection.module.css";
import "swiper/swiper-bundle.min.css";
import { testimonialsStore } from "reviews/_stores/testimonialsStore";
import { TestimonialHomeCard } from "reviews/TestimonialHomeCard";
import { ArrowPaginated } from "_common/ui/swiper/ArrowPaginated";
import { useTranslation } from "react-i18next";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Navigation]);
const NAME = "homeTestimonials";
export function HomeTestimonialSection() {
    const { t } = useTranslation();

    return (
        <div id={NAME} className={clsx("flex_row_center justifyContent_spaceBetween", styles.container)}>
            <div className={clsx("flex_column flex-2", styles.leftColumn)}>
                <div className={styles.title} dangerouslySetInnerHTML={{ __html: t("admin.review.title") }} />
                <div className={styles.subTitle} dangerouslySetInnerHTML={{ __html: t("admin.review.subTitle") }} />
            </div>
            <Swiper
                navigation={{
                    prevEl: `.swiper-button-prev.${NAME}`,
                    nextEl: `.swiper-button-next.${NAME}`,
                }}
                className={"flex-3"}
            >
                {testimonialsStore.reviews.map((review) => {
                    if (!review) return;
                    return (
                        <SwiperSlide key={review._id}>
                            <TestimonialHomeCard review={review} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <div className={styles.arrowContainer}>
                <ArrowPaginated numberOfSection={testimonialsStore.reviews.length} name={NAME} />
            </div>
        </div>
    );
}
