import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import styles from "./_css/propertiesView.module.css";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { ListStore } from "_common/list/ListStore";
import { PropertiesViewList } from "properties/PropertiesViewList";
import { usePropertyPageContext } from "properties/PropertyPageContext";
import { scrollTop } from "_common/_utils/ScrollTop";
import { PropertiesViewMap } from "properties/PropertiesViewMap";
import { PropertiesViewButtonsMobile } from "properties/PropertiesViewButtonsMobile";

export enum MAP_OR_LIST_SELECTED {
    "LIST" = "list",
    "MAP" = "map",
}

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};

export const PropertiesView = ({ listStore }: Props) => {
    const propertiesStore = usePropertiesStore();
    const { mapOrListSelected } = usePropertyPageContext();
    const resultsContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setTimeout(() => {
            if (resultsContainer && resultsContainer?.current?.scrollTop) {
                scrollTop(resultsContainer, resultsContainer.current.scrollTop);
            }
        }, 250);
    }, [listStore.selectedPage, mapOrListSelected]);
    return (
        <div
            className={clsx("flex_row flex-1 relative", styles.container)}
            onMouseLeave={() => propertiesStore.setMapPropertiesSelected(undefined)}
        >
            <div
                ref={resultsContainer}
                className={clsx("flex flex-col px-1", {
                    [styles.resultsMap]: mapOrListSelected === MAP_OR_LIST_SELECTED.MAP,
                    ["w-1/2"]: mapOrListSelected === MAP_OR_LIST_SELECTED.MAP,
                    ["w-full h-full overflow-hidden"]: mapOrListSelected === MAP_OR_LIST_SELECTED.LIST,
                })}
            >
                <PropertiesViewList listStore={listStore} />
            </div>
            {mapOrListSelected === MAP_OR_LIST_SELECTED.MAP && <PropertiesViewMap listStore={listStore} />}
            <PropertiesViewButtonsMobile listStore={listStore} />
        </div>
    );
};
