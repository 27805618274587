import { useEffect } from "react";
import { useLocation } from "react-router";
import { useScrollPosition } from "_common/_utils/hookWindow";

export const scrollTop = (pageRef: { current: HTMLDivElement | null }, scrollPosition?: number, force = false) => {
    if ((pageRef.current && scrollPosition && scrollPosition > 0) || (pageRef.current && force)) {
        pageRef.current.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
};

type Props = {
    pageRef: { current: HTMLDivElement | null };
};

export function ScrollTop(props: Props) {
    const location = useLocation();
    const scrollPosition: number | undefined = useScrollPosition(props.pageRef);
    useEffect(() => {
        setTimeout(() => {
            scrollTop(props.pageRef, scrollPosition);
        }, 200);
    }, [location.pathname]);
    return null;
}
