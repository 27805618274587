import sharedConfig from "_configs/sharedConfig";

export const appConfig = {
    token: {
        storageKey: "TOKEN",
    },

    stripe: {
        publicKey: process.env.STRIPE_PUBLIC_KEY as string,
    },

    square: {
        appId: process.env.SQUARE_APP_ID as string,
        locationId: process.env.SQUARE_LOCATION_ID as string,
    },
    hotjar: {
        id: "1553915",
    },
    googleGtm: {
        gtmId: "GTM-5TZCXV4",
        dataLayerName: "PageDataLayer",
    },
    google: {
        analytics: "267717222",
        apiKey: sharedConfig.isDev
            ? "AIzaSyCzusE0LFaOjCE_dEka-z1ZVzoIEu3Nf-k"
            : "AIzaSyAXEWQoiQvj9k8mtBZGtXb0kL9FTy4-Kkg",
    },
    googleAuth: {
        apiKey: sharedConfig.isProd
            ? process.env.GOOGLE_AUTH_KEY
            : "960924869491-18oa4nd1udasclmrbb0gb97mks9ts2oi.apps.googleusercontent.com",
    },
    facebook: {
        appId: (process.env.FACEBOOK_ID as string) ?? "1898241403682517",
    },

    intercom: {
        app_id: "pt9n24c1",
    },
    leaflet: {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    },
    leafletSatelite: {
        subdomains: ["mt1", "mt2", "mt3"],
        url: "https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
    },
    attributer: {
        url: "https://d1b3llzbo1rqxo.cloudfront.net/attributer.js",
    },
    dropBox: {
        // apiKey: "uokgqw058dhq23b",
        apiKey: "5jytesdfwnjhi8y",
    },
};
