import React from "react";
import { TLang } from "_configs/sharedConfig";
import { getFlagImg } from "_common/_utils/pageUtils";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {
    label: string;
    extralabel?: string;
    text?: string;
    required?: boolean;
    className?: string;
    inputClassName?: string;
    lang?: TLang;
};

export function InputBlock(props: React.PropsWithChildren<Props>) {
    return (
        <div className={props.className}>
            <div className={"flex flex-row items-center mb-1 text-base"}>
                {props.lang && (
                    <div className="mr-3 h-4">
                        <LazyLoadImage
                            height={16}
                            src={getFlagImg(props.lang)}
                            alt={props.lang}
                            className={"h-full w-full"}
                        />
                    </div>
                )}
                <div className={"mr-4"}>
                    {props.label}
                    {props.required && <span>*</span>}
                </div>
                <div className={"text-xs color-[#333]"}>{props.extralabel}</div>
            </div>
            {props.text && <div className={"opacity-90 text-sm leading-4 mb-1"}>{props.text}</div>}
            <div className={props.inputClassName ?? "mb_10"}>{props.children}</div>
        </div>
    );
}
