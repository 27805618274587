export function reformatUtf8Url(url: string, withAccent = true) {
    return url
        .replace(/%C3%A9/g, withAccent ? "é" : "e")
        .replace(/%C3%A8/g, withAccent ? "è" : "e")
        .replace(/%C3%AB/g, withAccent ? "ë" : "e")
        .replace(/%C3%AA/g, withAccent ? "ê" : "e")
        .replace(/%C3%B9/g, withAccent ? "ù" : "u")
        .replace(/%C3%A0/g, withAccent ? "à" : "a");
}

export function removeAccentFromString(text?: string) {
    if (!text) return "";
    return text
        .replace(new RegExp(/[àáâãäå]/g), "a")
        .replace(new RegExp(/æ/g), "ae")
        .replace(new RegExp(/ç/g), "c")
        .replace(new RegExp(/[èéêë]/g), "e")
        .replace(new RegExp(/[ìíîï]/g), "i")
        .replace(new RegExp(/ñ/g), "n")
        .replace(new RegExp(/[òóôõö]/g), "o")
        .replace(new RegExp(/œ/g), "oe")
        .replace(new RegExp(/[ùúûü]/g), "u")
        .replace(new RegExp(/[ýÿ]/g), "y");
}

export function diacriticSensitiveRegex(value: string) {
    return value
        .replace(/a/g, "[a,á,à,ä,â]")
        .replace(/e/g, "[e,é,ë,è]")
        .replace(/i/g, "[i,í,ï,ì]")
        .replace(/o/g, "[o,ó,ö,ò;ô]")
        .replace(/u/g, "[u,ü,ú,ù]");
}

export function reformatStringForUrls(text?: string) {
    if (!text) return "";
    return removeAccentFromString(text).replaceAll(" ", "_").toLowerCase().trim();
}

export function reformatUrlForOriginalName(text?: string) {
    return removeAccentFromString(text).replaceAll("_", " ").toLowerCase().trim();
}
export function reformatUrlForOriginalNameForRegex(text: string) {
    return diacriticSensitiveRegex(text)?.replaceAll("_", " ").toLowerCase().trim();
}

export function reformatUrlForSlugSearch(text?: string) {
    return removeAccentFromString(text).replaceAll("_", "-").toLowerCase().trim();
}

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export function isEmail(email?: string) {
    return email !== undefined && email !== null && emailRegex.test(email.trim());
}

export function Rounded(value: number, afterComa: number) {
    if (value === undefined || value === 0) return 0;
    return Math.round(value * Math.pow(10, afterComa)) / Math.pow(10, afterComa);
}

export function removeInlineCssForMceText(text?: string) {
    if (!text) return "";
    return text.replaceAll('<span style="font-weight: 400;">', "<span>");
}

export function capitalize(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}
