import React, { ReactNode } from "react";
import { useTrackPage } from "pages/useTrackPage";

type Props = {
    children: ReactNode;
};

export function PageTracker(props: Props) {
    useTrackPage();
    return <>{props.children}</>;
}
