import React from "react";
import { useTranslation } from "react-i18next";
import { HomeValueCard } from "home/HomeValueCard";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { Button } from "_common/ui/mui/buttons/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import world from "../assets/images/icons/world.svg";
import access from "../assets/images/icons/access.svg";
import home from "../assets/images/icons/home.svg";
import trust from "../assets/images/icons/trust.svg";
import SwiperCore, { Navigation } from "swiper";

const VALUES_BLOCKS = [
    {
        img: world,
        titleKey: "homePage.values.blocks.title1",
        descriptionKey: "homePage.values.blocks.description1",
    },
    {
        img: home,
        titleKey: "homePage.values.blocks.title2",
        descriptionKey: "homePage.values.blocks.description2",
    },
    {
        img: access,
        titleKey: "homePage.values.blocks.title3",
        descriptionKey: "homePage.values.blocks.description3",
    },
    {
        img: trust,
        titleKey: "homePage.values.blocks.title4",
        descriptionKey: "homePage.values.blocks.description4",
    },
];

SwiperCore.use([Navigation]);

export function HomeValues() {
    const { t } = useTranslation();

    return (
        <div id={"homeValues"}>
            <div
                className={"mb_30 headerHome colorPrimary text-left mb-3 md:text-center"}
                dangerouslySetInnerHTML={{ __html: t("homePage.values.title") }}
            />
            <div className={"mb_30 textHome colorPrimary text-left mb-5 md:max-w-[500px] md:m-auto md:text-center"}>
                {t("homePage.values.subTitle")}
            </div>
            <Swiper
                navigation
                slidesPerView={1.2}
                spaceBetween={20}
                centeredSlides={true}
                centeredSlidesBounds={true}
                initialSlide={0}
                breakpoints={{
                    375: {
                        slidesPerView: 1.5,
                    },
                    560: {
                        slidesPerView: 2,
                    },
                    680: {
                        slidesPerView: 2.5,
                    },
                    900: {
                        slidesPerView: 3,
                    },
                    1290: {
                        slidesPerView: 4,
                        centeredSlides: false,
                    },
                }}
            >
                {VALUES_BLOCKS.map((value) => {
                    return (
                        <SwiperSlide key={value.titleKey}>
                            <HomeValueCard
                                title={t(value.titleKey)}
                                descritpion={t(value.descriptionKey)}
                                img={value.img}
                            />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <Link to={URLS.contact()} className="text-white">
                <Button variant={"contained"} color={"primary"} className="!mt-10 mb-0 mx-0 md:!mx-auto" size={"large"}>
                    {t("homePage.values.contactUs")}
                </Button>
            </Link>
        </div>
    );
}
