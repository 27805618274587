import React from "react";
import { List, MapOutlined } from "@material-ui/icons";
import { usePropertyPageContext } from "properties/PropertyPageContext";
import { MAP_OR_LIST_SELECTED } from "properties/PropertiesView";
import { Button } from "_common/ui/mui/buttons/Button";

export const PropertiesViewButtonMapList = () => {
    const { mapOrListSelected, setMapOrListSelected } = usePropertyPageContext();

    return (
        <Button
            onClick={() =>
                setMapOrListSelected(
                    mapOrListSelected === MAP_OR_LIST_SELECTED.MAP
                        ? MAP_OR_LIST_SELECTED.LIST
                        : MAP_OR_LIST_SELECTED.MAP,
                )
            }
            color={"primary"}
            variant={"contained"}
        >
            <>
                {mapOrListSelected === MAP_OR_LIST_SELECTED.MAP ? (
                    <List className="mr_5" fontSize={"default"} />
                ) : (
                    <MapOutlined className="mr_5" fontSize={"default"} />
                )}
            </>
            {mapOrListSelected === MAP_OR_LIST_SELECTED.MAP ? "List" : "Map"}
        </Button>
    );
};
