import { TFilter } from "admin/_common/filters/TFilter";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import dayjs from "dayjs";
import { PHASE_STATUS } from "admin/phases/_models/PhasesMdl";

export const TAGS: { key: string; filters: TFilter[] }[] = [
    {
        key: "presale",
        filters: [
            {
                id: "inventory.endDate",
                type: TFilterType.DELIVERY_DATE,
                value: dayjs().startOf("month"),
                op: "gte",
            },
            {
                id: "inventory.status",
                type: TFilterType.ENUM,
                value: PHASE_STATUS.UNDER_CONSTRUCTION,
            },
        ],
    },
    {
        key: "pool",
        filters: [
            {
                id: "amenities.pool",
                value: true,
                type: TFilterType.BOOLEAN,
            },
        ],
    },
    {
        key: "newest",
        filters: [
            {
                id: "amenities.pool",
                value: true,
                type: TFilterType.BOOLEAN,
            },
        ],
    },
    {
        key: "beachfront",
        filters: [
            {
                id: "features.beachfront",
                value: true,
                type: TFilterType.BOOLEAN,
            },
        ],
    },
    {
        key: "oceanView",
        filters: [
            {
                id: "feature.oceanView",
                value: true,
                type: TFilterType.BOOLEAN,
            },
        ],
    },
    {
        key: "garage",
        filters: [
            {
                id: "feature.garage",
                value: true,
                type: TFilterType.BOOLEAN,
            },
        ],
    },
    {
        key: "closingInPesos",
        filters: [
            {
                id: "feature.closingInPesos",
                value: true,
                type: TFilterType.BOOLEAN,
            },
        ],
    },
    {
        key: "gatedCommunity",
        filters: [
            {
                id: "feature.gatedCommunity",
                value: true,
                type: TFilterType.BOOLEAN,
            },
        ],
    },
    {
        key: "furnishedEquipped",
        filters: [
            {
                id: "feature.furnishedEquipped",
                value: true,
                type: TFilterType.BOOLEAN,
            },
        ],
    },
];
