import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import facebook from "../../assets/images/networks/facebook.svg";
import whatsapp from "../../assets/images/networks/whatsapp.svg";
import instagram from "../../assets/images/networks/instagram.svg";
import linkedIn from "../../assets/images/networks/linkedIn.svg";
import { settingsStore } from "settings/_stores/settingsStore";
import { ISettingsMdl } from "settings/_models/SettingsMdl";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./_css/footerSocial.module.css";

export function FooterSocial() {
    const settings = settingsStore.getOne() as ISettingsMdl;

    const { t } = useTranslation();
    return (
        <>
            {settings?.links && (
                <>
                    <div className={clsx(styles.title, "mb_15")}>{t("footer.socialNetworks.title")}</div>
                    <div className={clsx(styles.socialContainer, "flex_row gap-3")}>
                        {settings.links.fb && (
                            <a href={settings.links.fb}>
                                <LazyLoadImage src={facebook} />
                            </a>
                        )}
                        {settings.links.wa && (
                            <a href={settings.links.wa}>
                                <LazyLoadImage src={whatsapp} />
                            </a>
                        )}
                        {settings.links.ig && (
                            <a href={settings.links.ig}>
                                <LazyLoadImage src={instagram} />
                            </a>
                        )}
                        {settings.links.li && (
                            <a href={settings.links.li}>
                                <LazyLoadImage src={linkedIn} />
                            </a>
                        )}
                    </div>
                </>
            )}
        </>
    );
}
