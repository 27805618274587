import React from "react";
import { useTranslation } from "react-i18next";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { ListStore } from "_common/list/ListStore";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};
export const PropertiesCount = ({ listStore }: Props) => {
    const { t } = useTranslation();

    return (
        <div className={"text-xs font-light"}>
            {`
            ${t("propertiesPage.listingNumber", { count: listStore.count })} / ${t(
                "propertiesPage.listingUnitsMatched",
                {
                    count: listStore.count,
                },
            )}
            `}
        </div>
    );
};
