import dayjs from "dayjs";
import { s4 } from "_common/_utils/coreUtils";

export enum PAGE_TYPE {
    NORMAL = "NORMAL",
    ARTICLE = "ARTICLE",
    FAQ = "FAQ",
}

export enum BLOG_TYPE {
    TIPS = "TIPS",
    NEWS = "NEWS",
    NEW_PROJECTS = "NEW PROJECTS",
    BUILDER_DESIGN = "BUILDER DESIGN",
    FINANCES = "FINANCES",
    LIFESTYLE = "LIFESTYLE",
    INVEST = "INVEST",
    TOURISM = "TOURISM",
}

export type TMeta = {
    title?: string;
    description?: string;
    image?: string;
};

export type TPropsValues = { [key: string]: any | undefined };

export type TComponent = {
    id: string;
    props?: TPropsValues;
};

export type TRootComponent = {
    id: string;
    props: TPropsValues & {
        children: TComponent[];
    };
};

export interface IPageShortMdl {
    _id: string;
    localized: {
        url: string;
        title?: string;
        image?: string;
        description?: string;
        publishedAt?: dayjs.Dayjs;
        featured?: number;
    };
}

export type TLocalized = {
    url: string;
    meta?: TMeta;
    title?: string;
    image?: string;
    description?: string;
    component: TRootComponent;
    published: boolean;
    publishedAt?: dayjs.Dayjs;
    createdAt?: dayjs.Dayjs;
    featured?: number;
};

export interface IBasePageMDl {
    _id: string;
    type: PAGE_TYPE;
    blogType?: BLOG_TYPE;
    geoZoneId?: string;
    rankHomePage?: number;
    createdAt: dayjs.Dayjs;
    updatedAt: dayjs.Dayjs;
}

export interface IPageMdl extends IBasePageMDl {
    localized: {
        fr?: TLocalized;
        en?: TLocalized;
        es?: TLocalized;
        zh?: TLocalized;
    };
}

export function createDefaultLocalizedPage() {
    return {
        title: "Page title",
        url: "/page-url-" + s4(),
        published: false,
        component: {
            id: "div",
            props: { style: { padding: 0 }, children: [{ id: "container", props: { style: {}, children: [] } }] },
        },
    };
}
