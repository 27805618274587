import _ from "lodash";

export function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

export function s6() {
    return Math.floor((1 + Math.random()) * 0x1000000)
        .toString(16)
        .substring(1);
}

export function guid() {
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
}

export function iterateObject(obj: any, callback: (value: any, path: string[]) => void, path: string[] = []) {
    callback(obj, path);
    if (_.isObject(obj) || _.isArray(obj)) {
        for (const key in obj) {
            iterateObject((obj as any)[key], callback, [...path, key]);
        }
    }
}

export function transformToNestedObject<T>(obj: Record<string, any>): T {
    const result: any = {};

    for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            const keys = key.split(".");
            let current = result;

            for (let i = 0; i < keys.length; i++) {
                const part = keys[i];
                if (i === keys.length - 1) {
                    current[part] = obj[key];
                } else {
                    if (!current[part]) {
                        current[part] = {};
                    }
                    current = current[part];
                }
            }
        }
    }

    return result;
}

export function extractCommonAttributes(objects: { [key: string]: any }[]): { [key: string]: any } {
    const commonAttributesObject: { [key: string]: any } = {};
    if (objects.length === 0) {
        return commonAttributesObject;
    }

    const firstObject = objects[0];

    for (const key in firstObject) {
        if (objects.every((obj) => JSON.stringify(obj[key]) === JSON.stringify(firstObject[key]))) {
            commonAttributesObject[key] = firstObject[key];
        }
    }

    return commonAttributesObject;
}
