import React, { useEffect, useState } from "react";
import { Input } from "_common/ui/forms/Input";
import styles from "./_css/articlesSearchBar.module.css";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { IconComponent } from "components/base/icon/IconComponent";
import { pagesStore } from "pages/_stores/pagesStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import _ from "lodash";
import { IPageMdl } from "pages/_models/PageMdl";
import { observer } from "mobx-react";
import { ArticleSearchCard } from "blog/ArticleSearchCard";

type Props = {
    isArticlePage?: boolean;
};

export const ArticlesSearchBar = observer((props: Props) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState("");

    const debounceSetSearch = _.debounce((search: string) => setSearch(search), 300);

    useEffect(() => {
        pagesStore.fetchBySearch(search);
    }, [search]);

    return (
        <div className={"relative"}>
            <Input
                className={clsx(
                    "border border-[var(--color-primary)] box-border leading-[29px] h-[54px] rounded-[30px] border-solid pl-5",
                    { [styles.isArticlePageInput]: props.isArticlePage },
                )}
                placeholder={t("blog.placeholder")}
                endIconClassName={"w-[54px] right-[-3px] top-1 bottom-0 hover:opacity-100 hover:scale-100"}
                endIcon={
                    <div
                        className={
                            "flex items-center justify-center z-[2] bg-[var(--color-primary)] h-[45px] w-[45px] text-[white] cursor-text rounded-[30px] hover:bg-[var(--color-primary-darker)] hover:shadow-md"
                        }
                    >
                        <IconComponent fontSize="large" icon={"search"} />
                    </div>
                }
                onChange={(event) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    debounceSetSearch(event.target.value);
                }}
            />
            {search.length > 2 && (
                <div
                    className={
                        "absolute z-[var(--zIndex-search-blog-overlay)] bg-[white] rounded-[var(--border-radius)] max-h-[400px] overflow-y-auto shadow-[0_8px_13px_2px_rgba(0,0,0,0.26)] inset-x-0"
                    }
                >
                    <LoadableFromLoading<IPageMdl[]>
                        loading={pagesStore.searchArticlesLoadingState}
                        renderer={() => {
                            if (!pagesStore.searchResults || pagesStore.searchResults.length === 0) {
                                return null;
                            }
                            return pagesStore.searchResults.map((blog) => {
                                return <ArticleSearchCard key={blog._id} blog={blog} isArticlePage />;
                            });
                        }}
                    />
                </div>
            )}
        </div>
    );
});
