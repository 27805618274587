import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

SwiperCore.use([Navigation]);
export function CommunitiesImageSlider() {
    return (
        <Swiper loop slidesPerView={1} centeredSlides>
            <SwiperSlide>
                <div className={"flex justify-center "}>
                    <img className={"rounded"} src={"https://placehold.co/1240x500"} />
                </div>
            </SwiperSlide>
        </Swiper>
    );
}
