import React from "react";
import { testimonialsStore } from "reviews/_stores/testimonialsStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { HomeTestimonialSection } from "home/HomeTestimonialSection";

const REVIEW_COUNT = 10;
export function HomeTestimonials() {
    testimonialsStore.fetchReviews(REVIEW_COUNT);

    return (
        <LoadableFromLoading
            loading={testimonialsStore.reviewsState}
            renderer={(_status, error) => {
                if (error || testimonialsStore.reviews.length === 0) return null;
                return <HomeTestimonialSection />;
            }}
        />
    );
}
