import React from "react";

type Props = {
    title: string;
    descritpion: string;
    img: string;
};

export function HomeValueCard(props: Props) {
    return (
        <div className="p-5 flex flex-col gap-4 border border-[var(--color-secondary)] rounded-lg h-64 md:min-w-[220px]">
            <div>
                <img height={30} src={props.img} alt={props.img} />
            </div>
            <div className="font-semibold text-base">{props.title}</div>
            <div className="text-sm">{props.descritpion}</div>
        </div>
    );
}
