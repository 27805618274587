import React from "react";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { CardGalleryLabelType } from "properties/propertyCard/components/PropertyCardComponents";
import { useTranslation } from "react-i18next";
import { PropertyCardFavoriteBtn } from "properties/propertyCard/components/PropertyCardFavoriteBtn";
import { CardGalleryNew } from "properties/propertyCard/components/CardGalleryNew";
import { NoSsr } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import clsx from "clsx";

type Props = {
    property: TPropertyListingMdl;
    isLuxury?: boolean;
};

export function PropertyCardGallery({ property, isLuxury }: Props) {
    const { t } = useTranslation();
    return (
        <>
            <LazyLoadImage
                src={property.photos?.[0]?.url}
                alt={"property image"}
                title={`${property.localized.title} photo 1`}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `https://placehold.co/300x200?text=Zisla.com`;
                }}
            />
            <CardGalleryLabelType
                type={t("property.types." + property.type)}
                className={clsx({ "-rotate-90 w-[80px] h-[30px] pr-5 pl-1 -left-2 top-6": isLuxury })}
            />
            <NoSsr>
                <CardGalleryNew date={property.lastUpdate} />
            </NoSsr>
            <NoSsr>
                <PropertyCardFavoriteBtn property={property} />
            </NoSsr>
        </>
    );
}
