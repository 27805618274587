import React from "react";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { HomeFeaturesProperties } from "home/HomeFeaturesProperties";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { Button } from "_common/ui/mui/buttons/Button";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

export function HomeFeaturesPropertiesSection() {
    // const listStore = propertiesStore.getListStore("featuredProperties", 6);
    propertiesStore.fetchPremiumProperties(6);

    const { t } = useTranslation();

    return (
        <>
            <h2
                className={"mb_20 headerHome colorPrimary"}
                dangerouslySetInnerHTML={{ __html: t("homePage.featuredProperties.title") }}
            />
            <LoadableFromLoading
                loading={propertiesStore.featuredPropertiesState}
                renderer={(_status, error) => {
                    if (error) return null;
                    return <HomeFeaturesProperties />;
                }}
            />
            <div className={clsx("flex_center_center mt_50 justify-start md:justify-center")}>
                <Link to={URLS.buy()}>
                    <Button variant={"outlined"} color={"primary"} size={"large"}>
                        {t("homePage.viewAllProperties")}
                    </Button>
                </Link>
            </div>
        </>
    );
}
