import React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { CardGalleryLabelNew } from "properties/propertyCard/components/PropertyCardComponents";

type Props = {
    date: dayjs.Dayjs;
};

export function CardGalleryNew({ date }: Props) {
    const { t } = useTranslation();
    const diffInHours = dayjs().diff(date, "hour");
    let text = "";
    if (diffInHours > 24) {
        text = t("property.card.labelFrom.day");
    } else if (diffInHours > 0) {
        text = t("property.card.labelFrom.hour", { from: diffInHours });
    }

    if (diffInHours > 48 || !text) return null;
    return <CardGalleryLabelNew text={text} />;
}
