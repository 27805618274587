import { TComponentsConfigs } from "components/ComponentRenderer";
import { SectionTitleComponent } from "components/sections/sectionTitle/SectionTitleComponent";
import { SectionComponent } from "components/sections/section/SectionComponent";
import { TwoColsComponent } from "components/sections/twoColumnsSection/TwoColsComponent";
import { CtaSection } from "components/sections/ctaSection/CtaSection";
import { DiscoverPropertiesComponent } from "components/sections/discoverProperties/DiscoverPropertiesComponent";

export const SECTIONS_COMPONENTS_CONFIGS: TComponentsConfigs = {
    section: { component: SectionComponent },
    sectionTitle: { component: SectionTitleComponent },
    twoCols: { component: TwoColsComponent },
    ctaSection: { component: CtaSection },
    discoverProperties: { component: DiscoverPropertiesComponent },
};
