import React from "react";
import square from "../../../assets/images/icons/square.svg";
import { convert, UNIT_CONVERSION } from "_common/_utils/unityUtils";
import { useTranslation } from "react-i18next";

type Props = {
    min: number;
};

export function PropertyCardSurfaces({ min }: Props) {
    const { t } = useTranslation();

    return (
        <>
            <img src={square} alt={"square icon"} />
            <div>
                {t("propertiesPage.card.surfaces", {
                    ft2: convert(min, UNIT_CONVERSION.M2_TO_FT2),
                    m2: min,
                })}
            </div>
        </>
    );
}
