import React from "react";

type Props = {
    title: string;
    delimiter: number;
};

export function CommunitiesTitle({ title, delimiter }: Props) {
    const titleSplit = title.split(" ");

    const beforeDelimiter = titleSplit.slice(0, delimiter).join(" ");
    const afterDelimiter = titleSplit.slice(delimiter, titleSplit.length).join(" ");

    return (
        <h2 className={"mb_20 headerHome colorPrimary"}>
            {beforeDelimiter} <span>{afterDelimiter}</span>
        </h2>
    );
}
