import React from "react";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./_css/footerLink.module.css";
import clsx from "clsx";

const LINKS: { url: string; labelKey: string }[] = [
    {
        labelKey: "footer.links.contactUs",
        url: URLS.contact(),
    },
    {
        labelKey: "footer.links.properties",
        url: URLS.buy(),
    },
    {
        labelKey: "footer.links.policy",
        url: URLS.policy(),
    },
    {
        labelKey: "footer.links.terms",
        url: URLS.terms(),
    },
    {
        labelKey: "footer.links.faq",
        url: URLS.faq(),
    },
];
export function FooterLink() {
    const { t } = useTranslation();

    return (
        <Swiper
            slidesPerView={2.3}
            breakpoints={{
                500: {
                    slidesPerView: 3,
                },
                600: {
                    slidesPerView: 3.3,
                },
                960: {
                    slidesPerView: 4.3,
                },
                1180: {
                    slidesPerView: 5,
                },
            }}
            className={styles.swiper}
        >
            {LINKS.map((link) => {
                return (
                    <SwiperSlide key={link.url} className={clsx("flex_center_center", styles.link)}>
                        <Link to={link.url}>{t(link.labelKey)}</Link>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}
