import React from "react";
import styles from "main/footer/_css/footerLinkList.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { FooterCollapsedTitle } from "main/footer/FooterCollapsedTitle";

export type TList = {
    url: string;
    labelKey: string;
    label: string;
};

type Props = {
    list: TList[];
    titleKeyLabel: string;
};

export function FooterLinkList(props: Props) {
    const { t } = useTranslation();

    return (
        <>
            <FooterCollapsedTitle title={props.titleKeyLabel}>
                <>
                    <ul className={styles.blockList}>
                        {props.list.map((link, key) => {
                            if (link.labelKey === "NO_LINK") return null;
                            return (
                                <li key={key} className={clsx(styles.list, "mb_10")}>
                                    <Link
                                        target={"_blank"}
                                        rel="noopener noreferrer"
                                        to={link.url}
                                        className={styles.link}
                                    >
                                        {t(link.labelKey, { city: link.label })}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                    <div hidden>
                        <a href={"https://lesentrecodeurs.com"} target={"_blank"} rel="noopener noreferrer">
                            Powered by Les Entrecodeurs (lesentrecodeurs.com)
                        </a>
                    </div>
                </>
            </FooterCollapsedTitle>
        </>
    );
}
